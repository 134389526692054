import Body2 from 'components/Body2';
import Icon from 'components/Icon';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@awesome.me/kit-cc709582e6/icons/kit/custom';

const handleClick = onClick => handleEvent(onClick);

const EditLink = ({
    onClick,
    text = '',
    displayIconOnLeft = false,
    className = '',
    iconClassName = '',
    icon,
    noIcon = false,
    id = 'editLink',
    iconType = 'icon'
}) => (
    <Body2
        onClick={handleClick(onClick)}
        className={`${styles.editLink} ${className}`}
        text={
            <>
                {displayIconOnLeft &&
                    !noIcon &&
                    (icon ? (
                        iconType === 'FontAwesomeIcon' ? (
                            <FontAwesomeIcon
                                className={`${styles.icon} ${iconClassName}`}
                                color={'#052A63'}
                                icon={icon}
                            />
                        ) : (
                            <Icon
                                className={`${styles.icon} ${iconClassName}`}
                                image={icon}
                            />
                        )
                    ) : (
                        <FontAwesomeIcon
                            className={`${styles.icon} ${iconClassName}`}
                            color={'#0b41aa'}
                            icon={faEdit}
                        />
                    ))}
                <span>{text}</span>
                {!displayIconOnLeft &&
                    !noIcon &&
                    (icon ? (
                        iconType === 'FontAwesomeIcon' ? (
                            <FontAwesomeIcon
                                className={`${styles.icon} ${iconClassName}`}
                                color={'#1157ee'}
                                icon={icon}
                            />
                        ) : (
                            <Icon
                                className={`${styles.icon} ${iconClassName}`}
                                image={icon}
                            />
                        )
                    ) : (
                        <FontAwesomeIcon
                            className={`${styles.icon} ${iconClassName}`}
                            color={'#0b41aa'}
                            icon={faEdit}
                        />
                    ))}
            </>
        }
        id={id}
    />
);

export default EditLink;
