import Hamburger from 'components/Hamburger';
import Logo from 'components/Logo';

import styles from './styles.module.scss';

const Header = ({ shouldHideNavigation = false, isHeaderFixed }) => {
    const isHomePage = window.location.pathname === '/' && isHeaderFixed;
    return (
        <header
            className={`${styles.header} ${
                isHomePage ? styles.headerFixed : ''
            }`}
            data-gtm="nav"
        >
            <div className={styles.headerContent} id="headerContent">
                <Logo
                    className={`${styles.logo} ${
                        shouldHideNavigation ? styles.logoCentered : ''
                    }`}
                    id="headerLogo"
                />

                {!shouldHideNavigation && <Hamburger />}
            </div>
        </header>
    );
};

export default Header;
