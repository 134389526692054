import React from 'react';

import InfoModal from 'components/InfoModal';

import './filterSection.scss';

const FilterSection = ({ children }) => {
    return <div className="section">{children}</div>;
};

export default FilterSection;

export const SubSection = ({
    children,
    className,
    infoModalTitle,
    infoModalDescription,
    title = '',
    modalBoxClassName = '',
    headerClassName = ''
}) => {
    return (
        <div className="subSection">
            {title && (
                <div className={`${className} title`}>
                    <div> {title}</div>
                    <InfoModal
                        className="filter-section-info-modal"
                        iconClassName={'filter-section-info-icon'}
                        description={infoModalDescription}
                        modalBoxClassName={modalBoxClassName}
                        headerClassName={headerClassName}
                        title={infoModalTitle}
                    />
                </div>
            )}
            {children}
        </div>
    );
};
