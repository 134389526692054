import { useContext, useEffect, useState } from 'react';

import ZipCodeInputWithLabel from 'components/ZipCodeInputWithLabel';

import ZipCodeContext from 'contexts/zipCode';

import { validateZipCode } from 'utilities/validate';

const ZipCode = ({
    className = '',
    footerButtonClicked,
    id = 'zipCodeInput',
    isOnChangeEnable,
    labelClass,
    only5Valid,
    type,
    zipCodeLabel
}) => {
    const {
        buttonDisabled,
        error,
        setButtonDisabled,
        setZipCode,
        zipCode,
        onZipCodeChange,
        setError,
        getCounties,
        onCountyChange,
        onSetCounties
    } = useContext(ZipCodeContext);

    const [zip, setZip] = useState(isOnChangeEnable ? zipCode : '');

    useEffect(() => {
        const handleEnter = e => {
            const viewPlansButtonDoc = document.getElementById('view-plans');
            const id = document.activeElement.id;
            if (e.keyCode === 13 && !buttonDisabled) {
                if (
                    id !== 'ContactAnAgent' &&
                    ['FooterInput', 'zipCodeInput', 'view-plans'].includes(
                        document.activeElement.getAttribute('id')
                    )
                ) {
                    viewPlansButtonDoc && viewPlansButtonDoc.click();
                    window.scrollTo(0, 0);
                }

                if (
                    type === 'modalWindow' &&
                    typeof footerButtonClicked === 'function'
                ) {
                    footerButtonClicked();
                }
            }
        };
        document.addEventListener('keypress', handleEnter);
        return () => document.removeEventListener('keypress', handleEnter);
         
    }, [buttonDisabled]);

    const zipCodeChanged = async e => {
        const zip = e.target.value.replace(/\D/g, '');
        setZip(zip);
        if (validateZipCode(zip)) {
            setZipCode(zip);
            if (isOnChangeEnable) {
                if (zip === zipCode) onZipCodeChange();
            } else {
                localStorage.setItem('ZipCodeWithoutError', zip);
                localStorage.setItem('updatedZipCode', zip);
                const countiesList = await getCounties(zip);
                await onSetCounties(countiesList);
                if (countiesList && countiesList.length) {
                    await onCountyChange();
                    setError(false);
                } else {
                    setError(true);
                }
            }
        } else {
            setButtonDisabled(true);
        }
    };

    const handleOnBlur = e => {
        const zip = e.target.value.replace(/\D/g, '');
        if (zipCode.length <= 5 || zipCode !== zip) {
            if (zipCode === zip) onZipCodeChange();
            else setZipCode(zip);
        }
    };

    const isError = only5Valid ? error && zip.length === 5 : error;

    return (
        <>
            <ZipCodeInputWithLabel
                className={className}
                error={isError}
                id={id}
                labelClass={labelClass}
                onBlur={isOnChangeEnable && handleOnBlur}
                onChange={zipCodeChanged}
                zipCode={zip}
                zipCodeLabel={zipCodeLabel}
            />
        </>
    );
};

export default ZipCode;
