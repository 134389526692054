import { useHistory } from 'react-router-dom';

import Button from 'components/Button';

import { CONTACT_AGENT_ROUTE } from 'utilities/routes';
import scrollToTop from 'utilities/scrollToTop';

const ContactAgentButton = ({ className, label, onClick }) => {
    const history = useHistory();

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        } else {
            history.push(CONTACT_AGENT_ROUTE);
            scrollToTop();
        }
    };

    return (
        <Button className={className} label={label} onClick={handleOnClick} />
    );
};

export default ContactAgentButton;
