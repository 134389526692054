import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import { setGuidedModal } from 'components/ConsumerGuidedExperienceModal/slice';
import IconWithLabel from 'components/IconWithLabel';
import InfoCard from 'components/InfoCard';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import { CustomizedSteppers } from 'components/ProgressStepper';
import Text from 'components/Text';

import { getPlans } from 'utilities/apiSession/plans';

import healthIcon from './image/health.svg';
import lifeIcon from './image/life.svg';
import icon from './image/personalInfo.svg';

import { finalExpensePlanType, medicarePlanType } from './constants';

import styles from './styles.module.scss';

const PersonalDetails = () => {
    const dispatch = useDispatch();

    const [, setHealthPlans] = useState([]);
    const [, setLifePlans] = useState([]);

    const getPlansList = useCallback(async () => {
        try {
            const plans = await getPlans();
            const filterPlans = plans?.filter(
                data => data?.policyStatus === 'Active'
            );
            const lifeFilterPlans = filterPlans?.filter(
                data => data?.productCategory === 'Final Expense'
            );
            const healthFilterPlans = filterPlans?.filter(
                data => data?.productCategory !== 'Final Expense'
            );
            setLifePlans(lifeFilterPlans);
            setHealthPlans(healthFilterPlans);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        getPlansList();
    }, [getPlansList]);

    const onClick = planType => {
        dispatch(setSelectedPlanType(planType));
        dispatch(setGuidedModal(true));
    };

    const HealthInsuranceProgress = () => {
        return (
            <>
                <div className={styles.healthInsuranceProgress}>
                    <Text
                        id="healthInsuranceProgressHelperText"
                        text={
                            'Help us get more accurate results by answering a few questions.'
                        }
                        className={styles.progressText}
                    />
                    <CustomizedSteppers
                        className={styles.progressbar}
                        id="healthInsuranceProgressStepper"
                        isProfilePage={true}
                        selectedPlanType={medicarePlanType}
                        selectedProfilePlanProgress={medicarePlanType}
                    />
                </div>
                <div className={styles.actionContainer}>
                    <Button
                        className={styles.actionBtn}
                        id="updateHealthInsuranceButton"
                        label="Update Health"
                        onClick={() => onClick(medicarePlanType)}
                    />
                </div>
            </>
        );
    };

    const LifeInsuranceProgress = () => {
        return (
            <>
                <div className={styles.lifeInsuranceProgress}>
                    <Text
                        id="lifeInsuranceProgressHelperText"
                        text={
                            'Help us get more accurate results by answering a few questions.'
                        }
                        className={styles.progressText}
                    />
                    <CustomizedSteppers
                        className={styles.progressbar}
                        id="updateLifeInsuranceButton"
                        isProfilePage={true}
                        selectedPlanType={finalExpensePlanType}
                        selectedProfilePlanProgress={finalExpensePlanType}
                    />
                </div>
                <div className={styles.actionContainer}>
                    <Button
                        className={styles.actionBtn}
                        id="updateLifeInsuranceButton"
                        label="Update Life"
                        onClick={() => onClick(finalExpensePlanType)}
                    />
                </div>
            </>
        );
    };

    return (
        <InfoCard
            bodyElement={
                <div className={styles.container}>
                    <div className={styles.containerBox}>
                        <IconWithLabel
                            alt="Health Icon"
                            iconClassName={styles.iconSize}
                            labelClassName={styles.healthTitle}
                            caption={'Health Profile Progress'}
                            className={styles.iconWithLabel}
                            image={healthIcon}
                        />

                        <HealthInsuranceProgress />
                    </div>
                    <div className={styles.containerBox}>
                        <IconWithLabel
                            alt="Life Icon"
                            iconClassName={styles.iconSize}
                            labelClassName={styles.lifeTitle}
                            caption={'Life Profile Progress'}
                            className={styles.iconWithLabel}
                            image={lifeIcon}
                        />

                        <LifeInsuranceProgress />
                    </div>
                </div>
            }
            heading={'Personal Details'}
            containerClassName={styles.personalDetails}
            icon={icon}
            iconClassName={styles.headerIcon}
            infoCardDetailsClassName={styles.cardBody}
            titleContainerClassName={styles.titleContainerClassName}
        />
    );
};
export default PersonalDetails;
