import * as Sentry from '@sentry/react';
import { CONSUMER_SERVICE_URL } from 'utilities/env';
import parseResponse from 'utilities/parseResponse';

export class ClientsService {
    constructor(getAccessToken, user) {
        this.getAccessToken = getAccessToken;
        this.user = user;
    }

    setAccessToken = getAccessToken => {
        this.getAccessToken = getAccessToken;
    };

    _clientAPIRequest = async (
        path,
        method = 'GET',
        body,
        accessTokenVal = ''
    ) => {
        const accessToken = accessTokenVal || (await this.getAccessToken());
        const opts = {
            method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        };
        if (body) {
            opts.body = JSON.stringify(body);
        }

        return fetch(path, opts);
    };

    getConsumerByConsumerId = async consumerid => {
        const url = `${CONSUMER_SERVICE_URL}/${consumerid}`;
        try {
            const response = await this._clientAPIRequest(url, 'GET');
            const userData = await parseResponse(response);
            return userData;
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    updateAccountData = async values => {
        const url = `${CONSUMER_SERVICE_URL}/update`;
        try {
            const response = await this._clientAPIRequest(url, 'POST', values);
            return response;
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    agentAssociation = async (values, accessToken = '') => {
        const url = `${CONSUMER_SERVICE_URL}/AgentAssociation`;
        try {
            const response = await this._clientAPIRequest(
                url,
                'POST',
                values,
                accessToken
            );
            const userData = await parseResponse(response);
            return userData;
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    getUser = async () => this.user;
    getConsumerid = () => this.user?.consumerid;
}

const ClientService = new ClientsService();

export default ClientService;
