import { useContext, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    setGuidedModal,
    setGuidedProcess
} from 'components/ConsumerGuidedExperienceModal/slice';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';

import hamburgerContext from 'contexts/hamburger';
import zipcodeContext from 'contexts/zipCode';

import {
    FINAL_EXPENSE_PLANS_ROUTE,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK,
    PROFILE_ROUTE
} from 'utilities/routes';
import { SELECTED_PLAN_TYPE } from 'utilities/storageKeys';

import finalExpenseIcon from './finalExpense.svg';
import medicareIcon from './medicare.svg';

import { finalExpensePlanType, medicarePlanType } from './constants';
import {
    hideSubProductSelection,
    setCheckPreferredAgentModal,
    setSelectedItem,
    setSelectedPlanType,
    setSubProductTypeSelected
} from './slice';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';
import {
    FinalExpenseSubProduct,
    IndexUniversalLifeSubProduct,
    SubProductType
} from './subProductType';
import { IUL_TOGGLE } from '../../utilities/env';
import {
    COVERAGE_AMOUNT,
    FROM_SUB_PRODUCT,
    PLAN_TYPE_KEY
} from '../../utilities/storageKeys';
import { PLAN_TYPE_FILTER_FE } from '../FinalExpenseContentContainer';
import { setSelectedAgentType } from '../AgentTypeModal/slice';

const PlanTypeModal = () => {
    const { setIsOpen } = useContext(hamburgerContext);
    const { zipCode } = useContext(zipcodeContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedItem = useSelector(
        state => state.planTypeDetail.selectedItem
    );
    const subProductSelection = useSelector(
        state => state.planTypeDetail.subProductSelection
    );
    const [, setLifeClicked] = useState(false);
    const contentRef = useRef(null);

    const onSubProductTypeClose = () => {
        setLifeClicked(false);
        dispatch(hideSubProductSelection());
    };

    const onClose = () => {
        dispatch(setSelectedItem(null));
        onSubProductTypeClose();
    };
    const onClick = url => {
        setIsOpen(false);
        onClose();
        if (
            PROFILE_ROUTE !== selectedItem.from ||
            (PROFILE_ROUTE === selectedItem.from && zipCode)
        )
            history.push(url);
    };

    const onSelectPlanType = (planType, subProductType) => {
        dispatch(setSelectedPlanType(planType));
        dispatch(setSelectedAgentType(planType));
        dispatch(setSubProductTypeSelected(subProductType));

        dispatch(setCheckPreferredAgentModal(true));
        if (PROFILE_ROUTE === selectedItem.from && !zipCode) {
            dispatch(setGuidedProcess(true));
            dispatch(setGuidedModal(true));
            set(SELECTED_PLAN_TYPE, planType);
        }
        if (subProductType) {
            if (selectedItem.title === 'Products')
                onClick(FINAL_EXPENSE_PLANS_ROUTE);
            if (
                subProductType === IndexUniversalLifeSubProduct &&
                selectedItem.title === 'Find an Agent'
            )
                onClick(IUL_REQUEST_CALL_BACK);
            if (
                subProductType === FinalExpenseSubProduct &&
                selectedItem.title === 'Find an Agent'
            )
                onClick(FINAL_EXPENSE_REQUEST_CALL_BACK);
        } else {
            onClick(
                planType === medicarePlanType
                    ? selectedItem.medicareLink
                    : selectedItem.finalExpenseLink
            );
        }
    };

    const onClickFeTab = () => {
        if (!selectedItem.disableFinalExpensePlanType) {
            if (IUL_TOGGLE === 'true') {
                document.getElementById('fePlanType')?.scrollIntoView({
                    behavior: 'smooth'
                });
            } else {
                dispatch(setSubProductTypeSelected(FinalExpenseSubProduct));
                set(FROM_SUB_PRODUCT, true);
                set(COVERAGE_AMOUNT, '15000');
                set(PLAN_TYPE_KEY, PLAN_TYPE_FILTER_FE);
                onSelectPlanType(finalExpensePlanType, FinalExpenseSubProduct);
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            contentRef.current?.addEventListener('wheel', function (e) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });
        }, 1000);
    }, [selectedItem]);

    return selectedItem ? (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={true}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    headerClassName={styles.headerClassName}
                    headerTitle={'Choose Product Type'}
                    onClose={onClose}
                    hideFooter={true}
                    modalWindow={styles.modalWindow}
                >
                    {subProductSelection && (
                        <div
                            className={styles.content}
                            data-testid="plan-type-modal"
                            id="planTypeContent"
                            ref={ref => (contentRef.current = ref)}
                        >
                            <SubProductType
                                onClose={onSubProductTypeClose}
                                onSelectPlanType={onSelectPlanType}
                                subProductSelection={subProductSelection}
                            />
                        </div>
                    )}
                    {!subProductSelection && (
                        <div
                            className={styles.content}
                            data-testid="plan-type-modal"
                        >
                            <div
                                className={styles.planTypeContent}
                                ref={ref => (contentRef.current = ref)}
                                id="planTypeContent"
                            >
                                <div className={styles.tile}>
                                    <Icon
                                        alt="Medicare"
                                        className={`${styles.medicareIcon} ${
                                            selectedItem?.disableMedicarePlanType
                                                ? styles.disableIcon
                                                : ''
                                        }`}
                                        image={medicareIcon}
                                        onClick={
                                            !selectedItem?.disableMedicarePlanType
                                                ? () =>
                                                      onSelectPlanType(
                                                          medicarePlanType
                                                      )
                                                : () => {}
                                        }
                                        id="medicareIcon"
                                    />
                                    <Text
                                        className={`${styles.text} ${
                                            selectedItem?.disableMedicarePlanType
                                                ? styles.opacity3
                                                : ''
                                        }`}
                                        text="Medicare"
                                        id="Medicare"
                                    />
                                </div>
                                <div className={styles.tile}>
                                    <Icon
                                        alt="Final Expense"
                                        className={`${styles.finalExpenseIcon} ${
                                            selectedItem?.disableFinalExpensePlanType
                                                ? styles.disableIcon
                                                : ''
                                        }`}
                                        image={finalExpenseIcon}
                                        onClick={onClickFeTab}
                                        id="finalExpenseIcon"
                                    />
                                    <Text
                                        className={`${styles.text} ${
                                            selectedItem?.disableFinalExpensePlanType
                                                ? styles.opacity3
                                                : ''
                                        }`}
                                        text="Life"
                                        id="life"
                                    />
                                </div>
                            </div>

                            <SubProductType
                                onClose={onSubProductTypeClose}
                                onSelectPlanType={onSelectPlanType}
                                subProductSelection={subProductSelection}
                            />
                        </div>
                    )}
                </ModalWindow>
            </Modal>
        </div>
    ) : (
        <></>
    );
};

export default PlanTypeModal;
