import Body2 from 'components/Body2';
import Heading2 from 'components/Heading2';
import Icon from 'components/Icon';
import IconWithLink from 'components/IconWithLink';
import LinkWithArrow from 'components/LinkWithArrow';
import ProfilePicture from 'components/ProfilePicture';
import Text from 'components/Text';

import arrow from './images/arrow.svg';
import online from './images/online.svg';

import styles from './styles.module.scss';

export const AgentAvailableComponent = ({
    labelClassName = '',
    hideText = false,
    text = 'Available Now',
    isNetworkAgent,
    toggleStatus
}) => {
    if (isNetworkAgent && toggleStatus) {
        return (
            <span className={styles.onlineStyle}>
                {' '}
                <Icon
                    className={`${styles.onlineIcon}`}
                    image={online}
                    id="onlineIcon"
                />
                {!hideText && (
                    <Body2
                        className={`${styles.availableText} ${labelClassName}`}
                        text={text}
                        id="availableText"
                    />
                )}
            </span>
        );
    } else {
        return null;
    }
};

export const AgentAvailableComponentPurl = ({
    labelClassName = '',
    hideText = false,
    text = 'Available Now',
    isPurlToggledOn,
    onlineStyle = styles.onlineStyle
}) => {
    if (isPurlToggledOn) {
        return (
            <span className={onlineStyle}>
                {' '}
                <Icon
                    className={`${styles.onlineIcon}`}
                    image={online}
                    id="onlineIcon"
                />
                {!hideText && (
                    <Body2
                        className={`${styles.availableText} ${labelClassName}`}
                        text={text}
                        id="availableText"
                    />
                )}
            </span>
        );
    } else {
        return null;
    }
};

export const PurlBannerAgentInfo = ({
    purlAgentData,
    handleClose,
    isMobile,
    onClickViewMore
}) => {
    const { isPurlToggledOn, agentProfessionalInfoResponse } =
        purlAgentData || {};
    const { agentOfficeLocation: office } = agentProfessionalInfoResponse || {};
    const { city = '', state = '' } = office || {};

    const agentName = purlAgentData?.presentationName
        ? purlAgentData?.presentationName
        : `${purlAgentData.agentFirstName} ${purlAgentData.agentLastName}`;

    const npnCAContent = (
        <div className={styles.npnCIAContainer}>
            {!purlAgentData.caLicense && (
                <Text id="agentNpn">NPN: {purlAgentData.agentNpn}</Text>
            )}
            {purlAgentData.caLicense && (
                <Text id="caLicense">CA#: {purlAgentData.caLicense}</Text>
            )}
        </div>
    );

    return (
        <div className={styles.agentInfoContainer}>
            {isMobile && (
                <ProfilePicture
                    firstName={purlAgentData.agentFirstName}
                    lastName={purlAgentData.agentLastName}
                    profileUrl={purlAgentData.profileImageUrl}
                    hideCameraOverlay={true}
                />
            )}
            <div className={styles.purlAgentNameContainer}>
                <div className={styles.nameWithStatus}>
                    <div className={styles.nameContainer}>
                        <Heading2
                            className={styles.purlAgentName}
                            text={agentName}
                            id={'agentName'}
                        />
                    </div>

                    {!isMobile && (
                        <AgentAvailableComponent
                            isPurlToggledOn={isPurlToggledOn}
                        />
                    )}
                    {npnCAContent}
                </div>

                {!isMobile && (
                    <Text className={styles.purlAgentSubText}>
                        {purlAgentData?.agencyName && (
                            <Text id="agencyName">
                                {purlAgentData?.agencyName}
                            </Text>
                        )}
                        {(city && city.length > 0) ||
                        (state && state.length > 0) ? (
                            <>
                                <Text
                                    className={`${styles.cityState} ${purlAgentData?.agencyName && styles.agentAddress}`}
                                    id="agentAddress"
                                >
                                    <Text
                                        className={styles.cityState}
                                        id="city_State"
                                    >
                                        {`${[city, state]
                                            .filter(Boolean)
                                            .join(', ')}  `}
                                    </Text>
                                </Text>
                            </>
                        ) : (
                            <></>
                        )}
                    </Text>
                )}
                {!isMobile && (
                    <LinkWithArrow
                        text={'View More'}
                        className={styles.viewMore}
                        onClick={onClickViewMore}
                        iconClassName={styles.rightArrowIcon}
                    />
                )}
                {localStorage.getItem('reqAgentData') && (
                    <IconWithLink
                        caption="Return to Map"
                        className={styles.iconWithLink}
                        iconClassName={styles.iconClassName}
                        image={arrow}
                        labelClassName={styles.labelClassName}
                        onClick={() => handleClose(true)}
                    />
                )}
            </div>
        </div>
    );
};
