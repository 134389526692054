import Button from '../Button';
import styles from './styles.module.scss';

export const AgentModalContinueButton = ({
    disabled = false,
    onClick = () => {},
    type = 'button',
    buttonContainerClassName = ''
}) => {
    return (
        <div
            className={`${styles.buttonContainer} ${buttonContainerClassName}`}
        >
            <Button
                className={styles.button}
                disabled={disabled}
                label="Continue"
                onClick={onClick}
                id={'Continue'}
                type={type}
            />
        </div>
    );
};
