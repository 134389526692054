import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import Icon from 'components/Icon';
import InfoCard from 'components/InfoCard';
import Text from 'components/Text';

import { PROFILE_ROUTE } from 'utilities/routes';

import toDoIcon from './toDo.svg';

import TodoItem from './TodoItem';
import { setCheckCondition } from './slice';
import { useToDo } from './useToDo';

import styles from './styles.module.scss';
import { faConditions } from '@awesome.me/kit-cc709582e6/icons/kit/custom';

const TodoItems = ({ setInsuranceIcon }) => {
    const dispatch = useDispatch();
    const { getData, toDoUpdate } = useToDo();
    const todoItems = useSelector(state => state.toDoDetail.todoItems);
    const completedItems = useSelector(
        state => state.toDoDetail.completedItems
    );
    const checkCondition = useSelector(
        state => state.toDoDetail.checkCondition
    );

    const userProfile = useSelector(state => state.userProfile.userProfile);

    const { isAuthenticated } = useAuth0();
    const medicareSteps = useSelector(
        state => state.guidedDetails.medicareSteps
    );
    const finalExpenseSteps = useSelector(
        state => state.guidedDetails.finalExpenseSteps
    );
    const { consumerId } = userProfile;
    const isProfilePage = window.location.pathname === PROFILE_ROUTE;

    useEffect(() => {
        if (isAuthenticated && consumerId > 0) getData();
         
    }, [consumerId, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && checkCondition && isProfilePage) {
            const updateToDoIfAllStepsComplete = async () => {
                if (medicareSteps.every(i => i.isCompleted)) {
                    await toDoUpdate(null, 1);
                    dispatch(setCheckCondition(false));
                }
                if (finalExpenseSteps.every(i => i.isCompleted)) {
                    await toDoUpdate(null, 2);
                    dispatch(setCheckCondition(false));
                }
            };
            updateToDoIfAllStepsComplete();
        }
         
    }, [medicareSteps, finalExpenseSteps, isAuthenticated, checkCondition]);

    const ToDoItemsBody = () => {
        return (
            <div className={styles.bodyContainer}>
                {todoItems.length === 0 && completedItems.length === 0 ? (
                    <div className={styles.emptyStateContainer}>
                        <Icon
                            className={`${styles.toDoIcon}`}
                            image={toDoIcon}
                            id="profileTodoEmptyIcon"
                        />
                        <Text
                            text="No To-Do Items"
                            className={styles.noToDoText}
                            id="profileTodoEmptyText"
                        />
                        <Text
                            text="Check here in the future for to-dos."
                            className={styles.noToDoSubText}
                            id="profileTodoEmptySubText"
                        />
                    </div>
                ) : (
                    <>
                        {todoItems.map(toDo => (
                            <TodoItem
                                setInsuranceIcon={setInsuranceIcon}
                                toDo={toDo}
                                toDoUpdate={toDoUpdate}
                            />
                        ))}

                        <Text className={styles.completedItemtext}>
                            Completed Items{' '}
                            <span
                                className={styles.completedItemCount}
                                id="profileTodoCompletedItems"
                            >
                                ({completedItems.length})
                            </span>
                        </Text>

                        {completedItems.map(toDo => (
                            <TodoItem
                                setInsuranceIcon={setInsuranceIcon}
                                toDo={toDo}
                                toDoUpdate={toDoUpdate}
                            />
                        ))}
                    </>
                )}
            </div>
        );
    };

    return (
        <InfoCard
            bodyElement={<ToDoItemsBody />}
            containerClassName={styles.todosContainer}
            heading="To-Do Items"
            icon={faConditions}
            iconSize="3x"
            iconType="FontAwesomeIcon"
            iconClassName={styles.todosIcon}
            infoCardDetailsClassName={styles.toDoCardBody}
            titleContainerClassName={styles.titleContainerClassName}
        />
    );
};

export default TodoItems;
