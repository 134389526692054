import Body2 from 'components/Body2';
import DropdownWithLabel from 'components/DropdownWithLabel';

import { STATES } from './data';

import styles from './styles.module.scss';

const StateDropdown = props => {
    const handleChange = event => {
        const value = event.target.value;
        props.setState(value);
    };

    return (
        <>
            <DropdownWithLabel
                displayValue={props.state}
                list={props.stateList || STATES}
                id={props.id || 'state'}
                label={`${props.required ? 'State *' : props.label || 'State'}`}
                labelClassName={styles.labelClassName}
                onChange={handleChange}
                {...props}
                className={`${props.className} ${props.dropDownWithLabelClassName}`}
            />
            {props?.error?.type === 'required' && (
                <Body2
                    text={props?.error?.message}
                    className={`${props?.body2ClassName} ${styles.errorText}`}
                />
            )}
        </>
    );
};

export default StateDropdown;
