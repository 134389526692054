import useQueryParams from "components/hooks/useQueryParams";
import { set } from 'temp/utilities/storage';
import { useEffect } from "react";
import { GCLID, UTM_CAMPAIGN, UTM_CONTENT, UTM_MEDIUM, UTM_SOURCE, UTM_TERM } from "utilities/storageKeys";


export const useHandleQueryParams = () => {
    const params = useQueryParams();
    useEffect(()=>{
        const utm_source = params.get(UTM_SOURCE);
        const utm_medium = params.get(UTM_MEDIUM);
        const utm_campaign = params.get(UTM_CAMPAIGN);
        const utm_content = params.get(UTM_CONTENT);
        const utm_term = params.get(UTM_TERM);
        const gclid = params.get(GCLID);
        utm_source && set(UTM_SOURCE, utm_source);
        utm_medium && set(UTM_MEDIUM, utm_medium);
        utm_campaign && set(UTM_CAMPAIGN, utm_campaign);
        utm_content && set(UTM_CONTENT, utm_content);
        utm_term && set(UTM_TERM, utm_term);
        gclid && set(GCLID, gclid);
         
    },[]);
};
