import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import Button from 'components/Button';

import isFunction from 'utilities/isFunction';
import scrollToTop from 'utilities/scrollToTop';

import styles from './styles.module.scss';

const SignInButton = ({ label = 'Sign In', onClick, className = '' }) => {
    const { loginWithRedirect } = useAuth0();

    const handleOnClick = async () => {
        try {
            await loginWithRedirect();
            scrollToTop();
        } catch (e) {
            console.error('sign in error: ', e);
        }
        isFunction(onClick) && onClick();
    };

    return (
        <Button
            className={`${styles.signInButton} ${className}`}
            label={label}
            onClick={handleOnClick}
            type="submit"
            id={'signInButton'}
        />
    );
};

export default SignInButton;
