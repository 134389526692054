import { createSlice } from '@reduxjs/toolkit';

import {
    FINALEXPENSE_AGENT_DATA,
    MEDICARE_AGENT_DATA,
    SELECTED_AGENT_TYPE,
    AGENT_MODAL_ADDRESS,
    SELECTED_AGENT_PREFERENCE,
    SELECTED_LANGUAGES,
    SELECTED_SUB_AGENT_TYPE
} from 'utilities/storageKeys';

import { get, set } from 'temp/utilities/storage';

export const AgentTypeDetail = createSlice({
    name: 'AgentTypeDetail',

    initialState: {
        selectedAgentType: get(SELECTED_AGENT_TYPE, null),
        selectedSubAgentType: get(SELECTED_SUB_AGENT_TYPE, null),
        selectedItem: null,
        checkPreferredAgentModal: false,
        medicareAgent: null,
        finalExpenseAgent: null,
        subAgentSelection: false,
        selectedLanguages: get(SELECTED_LANGUAGES, ['English']),
        selectedLanguageIds: [16],
        selectedAgentPreference: get(SELECTED_AGENT_PREFERENCE, null),
        addressDetails: get(AGENT_MODAL_ADDRESS, {
            streetAddress: '',
            aptSuiteNumber: '',
            city: '',
            stateCode: '',
            zip: ''
        })
    },

    reducers: {
        setSelectedAgentType: (state, action) => {
            set(SELECTED_AGENT_TYPE, action.payload);
            state.selectedAgentType = action.payload;
            state.checkPreferredAgentModal = true;
        },
        setSelectedSubAgentType: (state, action) => {
            set(SELECTED_SUB_AGENT_TYPE, action.payload);
            state.selectedSubAgentType = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setCheckPreferredAgentModal: (state, action) => {
            state.checkPreferredAgentModal = action.payload;
        },
        setMedicareAgent: (state, action) => {
            set(MEDICARE_AGENT_DATA, action.payload);
            state.medicareAgent = action.payload;
        },
        setFinalExpenseAgent: (state, action) => {
            set(FINALEXPENSE_AGENT_DATA, action.payload);
            state.finalExpenseAgent = action.payload;
        },
        showSubAgentSelection: state => {
            state.subProductSelection = true;
        },
        hideSubAgentSelection: state => {
            state.subProductSelection = false;
        },
        setSelectedLanguages: (state, action) => {
            set(SELECTED_LANGUAGES, action.payload);
            state.selectedLanguages = action.payload;
        },
        setSelectedLanguageIds: (state, action) => {
            state.selectedLanguageIds = action.payload;
        },
        setSelectedAgentPreference: (state, action) => {
            set(SELECTED_AGENT_PREFERENCE, action.payload);
            state.selectedAgentPreference = action.payload;
        },
        setAddressDetails: (state, action) => {
            set(AGENT_MODAL_ADDRESS, action.payload);
            state.addressDetails = action.payload;
        }
    }
});

export const {
    setSelectedItem,
    setCheckPreferredAgentModal,
    setMedicareAgent,
    setFinalExpenseAgent,
    setSelectedAgentType,
    setSelectedSubAgentType,
    showSubAgentSelection,
    hideSubAgentSelection,
    setSelectedLanguages,
    setSelectedLanguageIds,
    setSelectedAgentPreference,
    setAddressDetails
} = AgentTypeDetail.actions;

export default AgentTypeDetail.reducer;
