import ActivityStreamList from 'components/ActivityStreamList';
import InfoCard from 'components/InfoCard';

import { activityStreamHeading } from './constant';

import styles from './styles.module.scss';

import { faBell } from '@fortawesome/fontawesome-free-regular';

const ActivityStreamInfoCard = ({
    activityStreamCardContainer,
    onclick,
    openZipModal
}) => (
    <div className={`${activityStreamCardContainer}`}>
        <InfoCard
            bodyElement={
                <ActivityStreamList
                    onclick={onclick}
                    openZipModal={openZipModal}
                />
            }
            containerClassName={styles.activityStream}
            heading={activityStreamHeading}
            icon={faBell}
            titleContainerClassName={styles.agentInfoTitle}
            infoCardDetailsClassName={styles.activityStreamCardDetails}
            iconType="FontAwesomeIcon"
            iconSize="2x"
        />
    </div>
);

export default ActivityStreamInfoCard;
