import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';
import { Delete } from '@mui/icons-material';

import Body2 from 'components/Body2';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import PageSpinner from 'components/PageSpinner';
import ProfilePicture from 'components/ProfilePicture';
import { setProfileImage } from 'components/UserProfile/slice';
import useFetch from 'components/hooks/useFetch';

import { PROFILE_IMAGE_SERVICE_URL } from 'utilities/env';

import {
    title,
    description,
    footerButtonLabel,
    fileSize,
    deleteImageText,
    uploadFailedText,
    uploadSuccessText,
    removeFailedText
} from './constants';

import styles from './styles.module.scss';

const MaxSizeInBytes = fileSize * 1024 * 1000;

const ProfileImageModal = ({
    className = '',
    modalBoxClassName = '',
    modalOpen = false,
    isMobile,
    openParentModal
}) => {
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const profileImage = useSelector(state => state.profileImage.profileImage);
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const { consumerId } = userProfile;
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState();
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const user = userProfile || {};
    const [isDisable, setIsDisable] = useState(true);

    const { Delete: deleteProfileImage } = useFetch(
        `${PROFILE_IMAGE_SERVICE_URL}/${consumerId}`
    );

    const openFileViewer = () => {
        inputRef.current?.click();
    };

    const handleReset = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.type = 'text';
            inputRef.current.type = 'file';
        }
    };

    const onFileChange = event => {
        let [file] = event.target.files;
        if (!fileValidation(file)) return false;
        setSelectedFile(file);
    };

    const fileValidation = file => {
        if (file && file.size > MaxSizeInBytes) {
            alert(
                'The file size must be no more than ' +
                    parseInt(MaxSizeInBytes / 1024) / 1000 +
                    'MB'
            );
            return false;
        }
        return true;
    };

    const onFileUpload = async () => {
        handleUpload(true);
    };

    const handleUpload = async (isUpdate = false) => {
        if (!selectedFile) {
            return;
        }
        setLoading(true);
        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('ConsumerId', consumerId);

        const options = {
            method: isUpdate ? 'PUT' : 'POST',
            body: formData
        };

        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            options.headers = {
                Authorization: `Bearer ${accessToken}`
            };
        }

        try {
            const response = await (
                await fetch(PROFILE_IMAGE_SERVICE_URL, options)
            ).json();
            setPreview(response);
            dispatch(setProfileImage(response));
            setMessage(uploadSuccessText);
            setError(false);
            setIsDisable(true);
        } catch (error) {
            setError(true);
            setMessage(uploadFailedText);
        } finally {
            setLoading(false);
        }
    };

    const deleteImage = async () => {
        const response = await deleteProfileImage(null, true);
        if (response && response.status >= 200 && response.status < 300) {
            setError(false);
            setMessage(deleteImageText);
            dispatch(setProfileImage(undefined));
            setIsDisable(true);
        } else {
            setError(true);
            setMessage(removeFailedText);
        }
    };

    useEffect(() => {
        setMessage('');
        if (!selectedFile) {
            setIsDisable(true);
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        setError(false);
        setIsDisable(false);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    useEffect(() => {
        setPreview(profileImage);
    }, [profileImage]);

    return (
        <Modal isOpen={modalOpen}>
            <ModalWindow
                contentClassName={styles.contentClassName}
                footerClassName={styles.footerClassName}
                headerClassName={styles.headerClassName}
                headerTitle={title}
                hideFooter={false}
                modalBoxClassName={modalBoxClassName}
                modalHeaderTitle={styles.modalHeaderTitle}
                onClose={() => {
                    openParentModal();
                }}
                footerButtonClicked={() => {
                    onFileUpload();
                }}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonDisabled={isDisable}
                footerId={'footerButton'}
                footerLabel={footerButtonLabel}
                isCloseButton
            >
                {loading && <PageSpinner />}
                <div
                    className={styles.profileContainer}
                    id="profilePictureContainer"
                >
                    <ProfilePicture
                        firstName={user?.firstName}
                        lastName={user?.lastName}
                        profileUrl={preview}
                        onClick={openFileViewer}
                        enableProgress={error}
                    />
                    {preview && (
                        <div
                            className={styles.removeText}
                            id="profilePictureUploadDeleteButton"
                            onClick={() => {
                                handleReset();
                                setSelectedFile(undefined);
                                profileImage && deleteImage();
                            }}
                        >
                            Remove Picture <Delete />
                        </div>
                    )}
                    <input
                        accept=".png,.jpeg,.jpg,.heic"
                        id="profilePictureUploadInput"
                        onChange={onFileChange}
                        ref={inputRef}
                        size={MaxSizeInBytes}
                        style={{ display: 'none' }}
                        type="file"
                    />
                    <Body2
                        className={styles.descriptionBlock}
                        text={description}
                        id="profilePictureUploadHelperText"
                    />
                    <Body2
                        className={`${
                            error ? styles.errorMessage : styles.successMessage
                        }`}
                        text={message}
                        id="profilePictureUploadErrorMessage"
                    />
                </div>
            </ModalWindow>
        </Modal>
    );
};

export default ProfileImageModal;
