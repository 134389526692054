import { uuid4 } from '@sentry/core';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const Button = ({
    className = '',
    dataGtm = '',
    dataTestId,
    disabled,
    disabledClassName = '',
    id,
    label,
    onClick
}) => {
    const handleClick = onClick => handleEvent(onClick);
    const classes = [
        className,
        disabled ? [disabledClassName, styles.disabled].join(' ') : '',
        styles.button
    ].join(' ');

    return (
        <button
            className={classes}
            data-click-type="button"
            data-gtm={dataGtm}
            data-testid={dataTestId || uuid4()}
            disabled={disabled}
            id={id}
            onClick={handleClick(onClick)}
        >
            {label}
        </button>
    );
};

export default Button;
