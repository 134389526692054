import { createSlice } from '@reduxjs/toolkit';

import { SELECTED_PLAN_TYPE } from 'utilities/storageKeys';

import { get, set } from 'temp/utilities/storage';
import { SUB_PRODUCT_TYPE_SELECTED } from '../../utilities/storageKeys';

export const PlanTypeDetail = createSlice({
    name: 'PlanTypeDetail',

    initialState: {
        selectedPlanType: get(SELECTED_PLAN_TYPE),

        selectedItem: null,
        checkPreferredAgentModal: false,
        subProductSelection: false,
        subProductTypeSelected: get(SUB_PRODUCT_TYPE_SELECTED)
    },

    reducers: {
        setSelectedPlanType: (state, action) => {
            set(SELECTED_PLAN_TYPE, action.payload);
            state.selectedPlanType = action.payload;
            state.checkPreferredAgentModal = true;
        },
        setSubProductTypeSelected: (state, action) => {
            set(SUB_PRODUCT_TYPE_SELECTED, action.payload);
            state.selectedPlanType = action.payload;
        },

        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setCheckPreferredAgentModal: (state, action) => {
            state.checkPreferredAgentModal = action.payload;
        },

        showSubProductSelection: state => {
            state.subProductSelection = true;
        },
        hideSubProductSelection: state => {
            state.subProductSelection = false;
        }
    }
});

export const {
    setSelectedPlanType,
    setSelectedItem,
    setCheckPreferredAgentModal,
    showSubProductSelection,
    hideSubProductSelection,
    setSubProductTypeSelected
} = PlanTypeDetail.actions;

export default PlanTypeDetail.reducer;
