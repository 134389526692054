import userProfile from 'services/clientServices/slice';

import { configureStore } from '@reduxjs/toolkit';

import guidedDetails from 'components/ConsumerGuidedExperienceModal/slice';
import createAccount from 'components/CreateAccountModal/slice';
import distanceDropdownDetails from 'components/DistanceDropdown/slice';
import learningCenterTags from 'components/LearningCenterSearchFilter/slice';
import agentTypeDetail from 'components/AgentTypeModal/slice';
import planTypeDetail from 'components/PlanTypeModal/slice';
import preferredAgentDetails from 'components/PreferredAgent/slice';
import quickProfileModalDetail from 'components/QuickProfileHealthModal/slice';
import quickProfileStepper from 'components/QuickProfileStepper/slice';
import reduxExample from 'components/ReduxExample/slice';
import stateDropdownDetails from 'components/StateDropdown/slice';
import profileImage from 'components/UserProfile/slice';
import supplementCardDetails from 'components/WalletSupplementCard/slice';
import doctorDetails from 'components/YourDoctorModal/slice';
import healthConditionModalDetails from 'components/YourHealthConditionContentModal/slice';
import healthConditionDetails from 'components/YourHealthConditionModal/slice';
import healthInfoDetails from 'components/YourHealthInfoModal/slice';
import healthIntakeDetails from 'components/YourHealthIntakeModal/slice';
import healthPlanModalDetails from 'components/YourHealthPlanModal/slice';
import legacyModalDetails from 'components/YourLegacyModal/slice';
import lifePlanModalDetails from 'components/YourLifePlanModal/slice';
import locationDetails from 'components/YourLocationModal/slice';
import medicaidDetails from 'components/YourMedicaidModal/slice';
import healthInsuranceModalDetails from 'components/YourMedicareHealthInsuranceModal/slice';
import pharmacyDetails from 'components/YourPharmacyModal/slice';
import profilePharmacyModalDetails from 'components/YourProfilePharmacyModal/slice';
import profilePrescriptionsModalDetails from 'components/YourProfilePrescriptionsModal/slice';
import supplementModalDetails from 'components/YourSupplementModal/slice';

import mainDetails from 'layouts/MainLayout/slice';

import userDetails from 'pages/QuickProfilePersonalInfo/slice';
import quickProfileRSA from 'pages/QuickProfileRSA/slice';
import toDoDetail from 'pages/TodoItems/slice';

import enrollDetails from 'temp/components/EnrollModal/slice';
import pharmacyFilter from 'temp/components/PharmacyFilters/slice';
import tempReduxExample from 'temp/components/ReduxExample/slice';
import prescriptionDetails from 'temp/components/YourPrescriptionModal/slice';
import planDetails from 'temp/pages/PlanDetails/slice';
import plansPage from 'temp/pages/Plans/slice';
import contactAgentDetails from 'temp/components/ContactAgentModal/slice';

export default configureStore({
    reducer: {
        agentTypeDetail,
        createAccount,
        distanceDropdownDetails,
        doctorDetails,
        enrollDetails,
        guidedDetails,
        healthInfoDetails,
        healthConditionDetails,
        healthInsuranceModalDetails,
        healthPlanModalDetails,
        profilePrescriptionsModalDetails,
        profilePharmacyModalDetails,
        lifePlanModalDetails,
        healthConditionModalDetails,
        healthIntakeDetails,
        learningCenterTags,
        locationDetails,
        medicaidDetails,
        pharmacyDetails,
        planDetails,
        plansPage,
        planTypeDetail,
        quickProfileModalDetail,
        preferredAgentDetails,
        prescriptionDetails,
        pharmacyFilter,
        reduxExample,
        stateDropdownDetails,
        supplementModalDetails,
        tempReduxExample,
        userProfile,
        mainDetails,
        supplementCardDetails,
        profileImage,
        legacyModalDetails,
        toDoDetail,
        quickProfileStepper,
        quickProfileRSA,
        userDetails,
        contactAgentDetails
    }
});
