import React, { useState } from 'react';
import Media from 'react-media';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import bannerImage from 'components/FindCoverage/images/banner.svg';
import mobileBannerImage from 'components/FindCoverage/images/mobile_banner.svg';
import Icon from 'components/Icon';
import { setSelectedItem } from 'components/PlanTypeModal/slice';

import { buttonLabel, linkData } from './constants';

import styles, { mobileWidth } from './styles.module.scss';

const FindCoverage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const dispatch = useDispatch();

    const handleNavigation = () => {
        let item = linkData;
        let disableMedicarePlanType = false;
        let disableFinalExpensePlanType = false;
        dispatch(
            setSelectedItem({
                ...item,
                disableMedicarePlanType,
                disableFinalExpensePlanType
            })
        );
    };

    return (
        <div className={styles.container}>
            <Media
                queries={{
                    mobile: { maxWidth: mobileWidth }
                }}
                onChange={breakpoint => {
                    setIsMobile(breakpoint.mobile);
                }}
            />
            <Icon image={isMobile ? mobileBannerImage : bannerImage} />
            <Button
                className={styles.getStartedBtn}
                id="findCoverageBtn"
                label={buttonLabel}
                onClick={handleNavigation}
            />
        </div>
    );
};

export default FindCoverage;
