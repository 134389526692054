import Button from 'components/Button';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const InverseButton = ({ className, label, onClick, id = 'inverseButton' }) => {
    const handleClick = onClick => handleEvent(onClick);

    return (
        <Button
            id={id}
            className={`${styles.inverseButton} ${className} `}
            label={label}
            onClick={handleClick(onClick)}
        />
    );
};

export default InverseButton;
