import { Box, IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styles from './styles.module.scss';

export const AgentModalCollapseRibbon = ({
    onClick = () => {},
    collapseButtonClassName = ''
}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
            width="100%"
            onClick={onClick}
        >
            <IconButton
                className={`${styles.collapseButton} ${collapseButtonClassName}`}
            >
                <ExpandLessIcon
                    className={styles.collapseIcon}
                    fontSize="large"
                />
            </IconButton>
        </Box>
    );
};
