import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Heading3 from 'components/Heading3';
import Heading5 from 'components/Heading5';
import Phone from 'components/Phone';
import Text from 'components/Text';

import phoneContext from 'contexts/phone';
import purlAgentContext from 'contexts/purlAgentContext';

import formatPhoneNumber from 'utilities/formatPhoneNumber';
import { PLANS_ROUTE } from 'utilities/routes';

import styles from './styles.module.scss';

const PLAN_TYPE_FILTER_IUL = 6;
const PlansCtaTitle = ({ finalExpensePlanType }) => {
    const { purlAgentData } = useContext(purlAgentContext);
    const medicareAgent = useSelector(
        state => state.agentTypeDetail.medicareAgent
    );
    const finalExpenseAgent = useSelector(
        state => state.agentTypeDetail.finalExpenseAgent
    );
    const { pathname } = useLocation();
    const agentPhone = pathname.includes(PLANS_ROUTE)
        ? (medicareAgent?.virtualNumber?.substring(2) ?? '')
        : (finalExpenseAgent?.virtualNumber?.substring(2) ?? '');
    const { phone: phoneCxt } = useContext(phoneContext);
    const phone =
        finalExpensePlanType && finalExpensePlanType === PLAN_TYPE_FILTER_IUL
            ? phoneCxt.IULPhoneNumber || ''
            : agentPhone
              ? formatPhoneNumber(agentPhone)
              : phoneCxt.text;

    return (
        <div className={styles.plansCtaTitle}>
            <Heading5
                className={styles.heading5}
                text="Get personalized guidance."
            />

            <Heading3
                className={styles.heading3}
                text="Connect with a licensed insurance agent."
            />

            <Phone className={styles.phone} number={phone} text={phone} />

            {!purlAgentData && (
                <Text
                    className={styles.text}
                    text="Or have an agent contact you"
                />
            )}
        </div>
    );
};

export default PlansCtaTitle;
