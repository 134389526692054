import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import ContactAgentButton from 'components/ContactAgentButton';
import PlansCtaTitle from 'components/PlansCtaTitle';
import purlAgentContext from 'contexts/purlAgentContext';
import { useFindAgentModal } from 'components/hooks/useFindAgentModal';

import {
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from 'utilities/routes';

import styles from './styles.module.scss';

const PlansCta = ({ dataGtm = '', finalExpensePlanType }) => {
    const { purlAgentData } = useContext(purlAgentContext);
    const [agentInfo, setAgentInfo] = useState(null);
    const { isAuthenticated } = useAuth0();
    const { openAgentModalWithProductType } = useFindAgentModal();
    const { push } = useHistory();

    const medicareAgent = useSelector(
        state => state.agentTypeDetail.medicareAgent
    );
    const finalExpenseAgent = useSelector(
        state => state.agentTypeDetail.finalExpenseAgent
    );
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );

    const PLAN_TYPE_FILTER_IUL = 6;

    useEffect(() => {
        if (medicareAgent || finalExpenseAgent) {
            if (selectedPlanType === 'Medicare') setAgentInfo(medicareAgent);
            else setAgentInfo(finalExpenseAgent);
        }
    }, [
        isAuthenticated,
        purlAgentData,
        medicareAgent,
        finalExpenseAgent,
        selectedPlanType
    ]);

    const onClickContactUs = () => {
        if (agentInfo) {
            if (selectedPlanType === 'Medicare') push(REQUEST_CALL_BACK);
            else
                push(
                    finalExpensePlanType === PLAN_TYPE_FILTER_IUL
                        ? IUL_REQUEST_CALL_BACK
                        : FINAL_EXPENSE_REQUEST_CALL_BACK
                );
        } else {
            openAgentModalWithProductType({
                title: 'Find an Agent',
                navigationLink: REQUEST_CALL_BACK,
                medicareLink: REQUEST_CALL_BACK,
                finalExpenseLink:
                    finalExpensePlanType === PLAN_TYPE_FILTER_IUL
                        ? IUL_REQUEST_CALL_BACK
                        : FINAL_EXPENSE_REQUEST_CALL_BACK
            });
        }
    };

    return (
        <div className={styles.plansCta} data-gtm={dataGtm}>
            <div className={styles.content}>
                <PlansCtaTitle finalExpensePlanType={finalExpensePlanType} />
                <ContactAgentButton
                    onClick={onClickContactUs}
                    className={styles.plansCtaBtn}
                    label={`Contact ${agentInfo?.agentFirstName ?? 'an Agent'}`}
                />
            </div>
        </div>
    );
};

export default PlansCta;
