import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';
import { Pagination } from 'swiper';
import ConsumerGuidedExperienceModal from 'components/ConsumerGuidedExperienceModal';
import { setGuidedModal } from 'components/ConsumerGuidedExperienceModal/slice';
import InfoCard from 'components/InfoCard';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import WalletFinalExpenseCard from 'components/WalletFinalExpenseCard';
import WalletHealthCard from 'components/WalletHealthCard';
import WalletInsuranceCard from 'components/WalletInsuranceCard';
import WalletLegacyCard from 'components/WalletLegacyCard';
import WalletPharmacyCard from 'components/WalletPharmacyCard';
import WalletPrescriptionCard from 'components/WalletPrescriptionCard';
import WalletSupplementCard from 'components/WalletSupplementCard';
import YourDoctorModal from 'components/YourDoctorModal';
import YourHealthConditionContentModal from 'components/YourHealthConditionContentModal';
import YourHealthInfoModal from 'components/YourHealthInfoModal';
import YourHealthPlanModal from 'components/YourHealthPlanModal';
import { setHealthPlanContentModal } from 'components/YourHealthPlanModal/slice';
import YourLegacyModal from 'components/YourLegacyModal';
import { setLegacyModal } from 'components/YourLegacyModal/slice';
import YourLifePlanModal from 'components/YourLifePlanModal';
import { setLifePlanContentModal } from 'components/YourLifePlanModal/slice';
import YourLocationModal from 'components/YourLocationModal';
import YourMedicaidModal from 'components/YourMedicaidModal';
import YourMedicareHealthInsuranceModal from 'components/YourMedicareHealthInsuranceModal';
import { setHealthInsuranceContentModal } from 'components/YourMedicareHealthInsuranceModal/slice';
import YourPharmacyModal from 'components/YourPharmacyModal';
import {
    setPharmacyModal,
    setPharmacyNames,
    setSelectedPharmacyId
} from 'components/YourPharmacyModal/slice';
import YourProfilePharmacyModal from 'components/YourProfilePharmacyModal';
import { setProfilePharmacyContentModal } from 'components/YourProfilePharmacyModal/slice';
import YourProfilePrescriptionsModal from 'components/YourProfilePrescriptionsModal';
import { setProfilePrescriptionsContentModal } from 'components/YourProfilePrescriptionsModal/slice';
import YourSupplementModal from 'components/YourSupplementModal';
import { setsupplementContentModal } from 'components/YourSupplementModal/slice';
import CloseIcon from 'components/icons/CloseIcon';

import prescriptionModalContext from 'contexts/prescriptionModal';

import { updatePrimary } from 'utilities/apiSession/pharmacies';
import { getPlans } from 'utilities/apiSession/plans';
import { sortPharmacies } from 'utilities/sortPharmacies';
import {
    PHARMACY_LIST_PAGE,
    PHARMACY_NAMES_KEY,
    SELECTED_PLAN_TYPE
} from 'utilities/storageKeys';

import './slider.scss';

import styles from './styles.module.scss';

import { setPharmacies } from 'temp/components/PharmacyFilters/slice';
import YourPrescriptionModal from 'temp/components/YourPrescriptionModal';
import {
    deletePharmacy,
    getPharmacies,
    getPrescriptions
} from 'temp/utilities/apiSession';
import { set } from 'temp/utilities/storage';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { faWallet } from '@awesome.me/kit-cc709582e6/icons/kit/custom';

const WalletContainer = ({ insuranceIcon, setInsuranceIcon }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const isGuidedModal = useSelector(
        state => state.guidedDetails.isGuidedModal
    );
    const { prescriptionModal, setPrescriptionModal } = useContext(
        prescriptionModalContext
    );
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [plansDetails, setPlansDetails] = useState([]);
    const [prescriptionsList, setPrescriptionsList] = useState([]);
    const [pharmacyList, setPharmacyList] = useState([]);

    const [editDrug, setEditDrug] = useState();
    const [editFromProfile, setEditFromProfile] = useState(false);
    const [isAddPrescription, setIsAddPrescription] = useState(false);
    const [isAddPharmacy, setIsAddPharmacy] = useState(false);

    const [healthPlanInfo, setHealthPlanInfo] = useState();
    const [finalPlansDetails, setFinalPlansDetails] = useState([]);
    const [finalPlanInfo, setFinalPlanInfo] = useState();
    const [walletID, setWalletID] = useState('');
    const [isWalletClosed, setIsWalletClosed] = useState(true);
    const walletIdsList = [
        'medicareCard',
        'walletPrescription',
        'healthPlansEmpty-0',
        'lifeCoverageEmpty-0',
        'walletLegacyCard',
        'walletSupplementCard',
        'walletPharmacy'
    ];
    const mouseOver = (id, walletIndex) => {
        const walletCount = '8px';

        if (isWalletClosed) {
            setWalletID(id);
            const footerDom = document.getElementById(
                `${id}Icon`
            )?.offsetHeight;

            setTimeout(function () {
                for (const walletId of walletIdsList) {
                    id !== walletId
                        ? document
                              .getElementById(walletId)
                              ?.setAttribute('style', `height:${walletCount}`)
                        : document
                              .getElementById(walletId)
                              ?.setAttribute(
                                  'style',
                                  `height:${footerDom || 150}px`
                              );
                }
                for (let index = 0; index < finalPlansDetails.length; index++) {
                    id !== `lifeCoverageEmpty-${index}`
                        ? document
                              .getElementById(`lifeCoverageEmpty-${index}`)
                              ?.setAttribute('style', `height:${walletCount}`)
                        : document
                              .getElementById(`lifeCoverageEmpty-${index}`)
                              ?.setAttribute(
                                  'style',
                                  `height:${footerDom || 150}px`
                              );
                }
                for (let index = 0; index < plansDetails?.length; index++) {
                    id !== `healthPlansEmpty-${index}`
                        ? document
                              .getElementById(`healthPlansEmpty-${index}`)
                              ?.setAttribute('style', `height:${walletCount}`)
                        : document
                              .getElementById(`healthPlansEmpty-${index}`)
                              ?.setAttribute(
                                  'style',
                                  `height:${footerDom || 150}px`
                              );
                }
            }, 100);
            setIsWalletClosed(false);
        }
    };
    const mouseOverOut = () => {
        setWalletID('');
        setIsWalletClosed(true);
        const height = '33px';
        for (const walletId of walletIdsList) {
            document
                .getElementById(walletId)
                ?.setAttribute('style', `height:${height}`);
        }
        for (let index = 0; index < finalPlansDetails?.length; index++) {
            document
                .getElementById(`lifeCoverageEmpty-${index}`)
                ?.setAttribute('style', `height:${height}`);
        }
        for (let index = 0; index < plansDetails?.length; index++) {
            document
                .getElementById(`healthPlansEmpty-${index}`)
                ?.setAttribute('style', `height:${height}`);
        }
    };
    const syncId = useSelector(state => state.userProfile.userProfile.syncId);
    useEffect(() => {
        if (ref.current == null) {
            return;
        }

        mouseOverOut();
    }, [ref, finalPlansDetails, plansDetails]);
    const openHealthInsuranceModal = icon => {
        setInsuranceIcon(icon);
        dispatch(setHealthInsuranceContentModal(true));
    };

    const openLegacyModal = icon => {
        setInsuranceIcon(icon);
        dispatch(setLegacyModal(true));
    };

    const openSupplementModal = () => {
        dispatch(setsupplementContentModal(true));
    };

    const openPrescriptionModal = () => {
        dispatch(setProfilePrescriptionsContentModal(true));
    };

    const openPharmacyModal = () => {
        dispatch(setProfilePharmacyContentModal(true));
    };

    const openHealthPlanModal = (icon, index) => {
        if (plansDetails?.length > [0] && plansDetails[0]?.policyStatus) {
            setInsuranceIcon(icon);
            dispatch(setHealthPlanContentModal(true));
            setHealthPlanInfo(plansDetails[index]);
        } else {
            set(SELECTED_PLAN_TYPE, medicarePlanType);
            dispatch(setSelectedPlanType(medicarePlanType));
            dispatch(setGuidedModal(true));
        }
    };
    const openLifePlanModal = (icon, index) => {
        if (
            finalPlansDetails?.length > [0] &&
            finalPlansDetails[0]?.policyStatus
        ) {
            setInsuranceIcon(icon);
            dispatch(setLifePlanContentModal(true));
            setFinalPlanInfo(finalPlansDetails[index]);
        } else {
            set(SELECTED_PLAN_TYPE, finalExpensePlanType);
            dispatch(setSelectedPlanType(finalExpensePlanType));
            dispatch(setGuidedModal(true));
        }
    };
    const getPlansList = useCallback(async () => {
        try {
            const plans = await getPlans();
            const filterPlans = plans?.filter(
                data =>
                    data?.policyStatus === 'active' ||
                    data?.policyStatus === 'Active'
            );
            const healthPlans = filterPlans?.filter(
                data => data?.productCategory !== 'Final Expense'
            );

            const lifeFilterPlans = filterPlans?.filter(
                data => data?.productCategory === 'Final Expense'
            );

            setPlansDetails(healthPlans?.slice(0, 9));
            setFinalPlansDetails(lifeFilterPlans?.slice(0, 9));
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (userProfile && Object.keys(userProfile).length) {
            getPlansList();
        }
    }, [userProfile]);

    const { user, getAccessTokenSilently } = useAuth0();
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };
    const profilePrescriptionsModal = useSelector(
        state =>
            state.profilePrescriptionsModalDetails
                ?.profilePrescriptionsContentModal
    );
    const profilePharmacyModal = useSelector(
        state =>
            state.profilePharmacyModalDetails?.setProfilePharmacyContentModal
    );
    const pharmacyModal = useSelector(
        state => state.pharmacyDetails.pharmacyModal
    );
    useEffect(() => {
        const FirstLoad = async () => {
            const authToken = await getAuthToken();
            const prescriptions = await getPrescriptions(authToken);
            setPrescriptionsList(prescriptions);
        };
        FirstLoad();
    }, [prescriptionModal, profilePrescriptionsModal]);

    useEffect(() => {
        const FirstLoad = async () => {
            const authToken = await getAuthToken();
            const pharmacyList = await getPharmacies(authToken);
            const pharmacies = await sortPharmacies(pharmacyList);
            setPharmacyList(pharmacies);
        };
        FirstLoad();
    }, [profilePharmacyModal, pharmacyModal, userProfile]);

    const defaultSwiperProps1 = {
        swiper: 'true',
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        direction: 'horizontal',
        pagination: {
            enabled: true,
            type: 'bullets',
            el: '.custom-pagination-div1',
            clickable: true,
            renderBullet: (index, className) => {
                return '<span class="' + className + '"></span>';
            }
        },
        modules: [Pagination]
    };

    const WalletCardBodyDetails = (isVerifyId = false) => {
        return (
            <div>
                {((isVerifyId && walletID === walletIdsList[0]) ||
                    (!isVerifyId && walletID !== walletIdsList[0])) && (
                    <WalletInsuranceCard
                        mouseOver={mouseOver}
                        walletID={walletID}
                        mouseOverOut={mouseOverOut}
                        cardClassName={styles.walletCard}
                        openHealthInsuranceModal={openHealthInsuranceModal}
                    />
                )}

                {((isVerifyId && walletID === walletIdsList[6]) ||
                    (!isVerifyId && walletID !== walletIdsList[6])) && (
                    <WalletPharmacyCard
                        mouseOver={mouseOver}
                        walletID={walletID}
                        mouseOverOut={mouseOverOut}
                        cardClassName={styles.walletCard}
                        openPharmacyModal={openPharmacyModal}
                        pharmaciesList={pharmacyList}
                    />
                )}

                {plansDetails && plansDetails.length > 0 ? (
                    isVerifyId && walletID === `healthPlansEmpty-0` ? (
                        <div className={styles.sliderBox}>
                            <Swiper
                                className={styles.mySwiper}
                                {...defaultSwiperProps1}
                            >
                                {plansDetails.map((item, index) => (
                                    <SwiperSlide key={item.policyNumber}>
                                        <WalletHealthCard
                                            key={item.policyNumber}
                                            showAllByDefault={true}
                                            openHealthPlanModal={
                                                openHealthPlanModal
                                            }
                                            plansDetailsIndex={index}
                                            plansDetails={item}
                                            mouseOver={() => {}}
                                            mouseOverOut={() => {}}
                                            cardClassName={
                                                styles.healthCardClassName
                                            }
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div
                                className="custom-pagination-div1"
                                id="customPagination1"
                            />
                        </div>
                    ) : (
                        !isVerifyId &&
                        walletID !== `healthPlansEmpty-0` && (
                            <WalletHealthCard
                                mouseOver={mouseOver}
                                walletID={walletID}
                                mouseOverOut={mouseOverOut}
                                cardClassName={styles.walletCard}
                                openHealthPlanModal={openHealthPlanModal}
                                plansDetailsIndex={0}
                                isVerifyId={true}
                                plansDetailsList={plansDetails}
                            />
                        )
                    )
                ) : (
                    ((isVerifyId && walletID === walletIdsList[2]) ||
                        (!isVerifyId && walletID !== walletIdsList[2])) && (
                        <WalletHealthCard
                            mouseOver={mouseOver}
                            walletID={walletID}
                            mouseOverOut={mouseOverOut}
                            cardClassName={styles.walletCard}
                            openHealthPlanModal={openHealthPlanModal}
                            plansDetails={healthPlanInfo}
                        />
                    )
                )}

                {((isVerifyId && walletID === walletIdsList[5]) ||
                    (!isVerifyId && walletID !== walletIdsList[5])) && (
                    <WalletSupplementCard
                        mouseOver={mouseOver}
                        walletID={walletID}
                        mouseOverOut={mouseOverOut}
                        cardClassName={styles.walletCard}
                        openSupplementModal={openSupplementModal}
                    />
                )}

                {finalPlansDetails && finalPlansDetails.length > 0 ? (
                    isVerifyId && walletID === `lifeCoverageEmpty-0` ? (
                        <div className={styles.sliderBox}>
                            <Swiper
                                className={styles.mySwiper}
                                {...defaultSwiperProps1}
                            >
                                {finalPlansDetails.map((item, index) => (
                                    <SwiperSlide key={item.policyNumber}>
                                        <WalletHealthCard
                                            key={item.policyNumber}
                                            showAllByDefault={true}
                                            openHealthPlanModal={
                                                openLifePlanModal
                                            }
                                            plansDetailsIndex={index}
                                            plansDetails={item}
                                            mouseOver={() => {}}
                                            mouseOverOut={() => {}}
                                            cardClassName={
                                                styles.healthCardClassName
                                            }
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div
                                className="custom-pagination-div1"
                                id="customPagination1"
                            />
                        </div>
                    ) : (
                        !isVerifyId &&
                        walletID !== `lifeCoverageEmpty-0` && (
                            <WalletFinalExpenseCard
                                walletID={walletID}
                                mouseOver={mouseOver}
                                mouseOverOut={mouseOverOut}
                                cardClassName={styles.walletCard}
                                plansDetails={0}
                                plansDetailsIndex={0}
                                isVerifyId={true}
                                plansDetailsList={finalPlansDetails}
                                openLifePlanModal={openLifePlanModal}
                            />
                        )
                    )
                ) : (
                    ((isVerifyId && walletID === walletIdsList[3]) ||
                        (!isVerifyId && walletID !== walletIdsList[3])) && (
                        <WalletFinalExpenseCard
                            mouseOver={mouseOver}
                            walletID={walletID}
                            mouseOverOut={mouseOverOut}
                            cardClassName={styles.walletCard}
                            plansDetails={finalPlanInfo}
                            plansDetailsIndex={0}
                            openLifePlanModal={openLifePlanModal}
                        />
                    )
                )}

                {((isVerifyId && walletID === walletIdsList[1]) ||
                    (!isVerifyId && walletID !== walletIdsList[1])) && (
                    <WalletPrescriptionCard
                        mouseOver={mouseOver}
                        walletID={walletID}
                        mouseOverOut={mouseOverOut}
                        cardClassName={styles.walletCard}
                        openPrescriptionModal={openPrescriptionModal}
                        prescriptionsList={prescriptionsList}
                    />
                )}

                {((isVerifyId && walletID === walletIdsList[4]) ||
                    (!isVerifyId && walletID !== walletIdsList[4])) && (
                    <WalletLegacyCard
                        mouseOver={mouseOver}
                        walletID={walletID}
                        mouseOverOut={mouseOverOut}
                        cardClassName={styles.walletCard}
                        openLegacyModal={openLegacyModal}
                    />
                )}
            </div>
        );
    };

    const WalletCardBody = useMemo(() => {
        return (
            <div className={styles.walletCardDetails} ref={ref}>
                {walletID && (
                    <div id={'showWallet'} className={styles.showWallet}>
                        <>
                            {' '}
                            <div className={styles.closeIcon}>
                                <CloseIcon
                                    onClick={mouseOverOut}
                                    iconClassName={styles.closeIconClassName}
                                />
                            </div>
                            <div>{WalletCardBodyDetails(true)}</div>
                        </>
                    </div>
                )}
                <div>{WalletCardBodyDetails()}</div>
            </div>
        );
         
    }, [
        plansDetails,
        finalPlansDetails,
        healthPlanInfo,
        finalPlanInfo,
        prescriptionsList,
        walletID,
        pharmacyList
    ]);

    const handleDeletePharmacy = async pharmacy => {
        const filterSelected = pharmacyList.filter(
            addressId => addressId?.pharmacyId !== pharmacy?.pharmacyId
        );
        const filterSelectedIds = pharmacyList
            .filter(addressId => addressId?.pharmacyId !== pharmacy?.pharmacyId)
            .map(pharmacy => pharmacy.pharmacyId);
        const authToken = await getAuthToken();
        await deletePharmacy({
            id: pharmacy?.pharmacyRecordID,
            syncId,
            authToken
        });

        if (filterSelected?.length === 0) {
            set(PHARMACY_LIST_PAGE, false);
            setPharmacyList([]);
            set(PHARMACY_NAMES_KEY, []);
        } else {
            dispatch(setSelectedPharmacyId(filterSelectedIds));
            const existingPrimary = filterSelected.some(
                pharmacy => pharmacy.isPrimary
            );
            if (!existingPrimary) {
                await setAsPrimary(filterSelected[0], filterSelected);
            }

            const pharmacyList = await getPharmacies(authToken);
            const pharmacies = await sortPharmacies(pharmacyList);
            setPharmacyList(pharmacies);
            const pharmacyNames = pharmacyList?.map(item => item.name) || [];
            dispatch(setPharmacyNames(pharmacyNames || []));
        }
    };

    const setAsPrimary = async (data, pharmacies) => {
        const oldPrimaryPharmacy = pharmacies.filter(
            pharmacy =>
                pharmacy?.pharmacyId !== data?.pharmacyId &&
                pharmacy.isPrimary === true
        );

        if (oldPrimaryPharmacy?.length) {
            const oldPrimary = [...oldPrimaryPharmacy].map(pharmacy => {
                let tpharmacy = { ...pharmacy };

                tpharmacy.isPrimary = false;

                return tpharmacy;
            });

            await updatePrimary({
                data: oldPrimary[0],
                syncId,
                authToken: await getAuthToken()
            });
        }

        let upDatePharmacy = { ...data };

        upDatePharmacy.isPrimary = true;

        await updatePrimary({
            data: upDatePharmacy,
            syncId,
            authToken: await getAuthToken()
        });

        const upDateList = pharmacies.map(pharmacy => {
            let tpharmacy = { ...pharmacy };
            if (tpharmacy?.pharmacyId !== data?.pharmacyId) {
                tpharmacy.isPrimary = false;
            } else {
                tpharmacy.isPrimary = true;
            }

            return tpharmacy;
        });
        upDateList?.sort(function (a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        upDateList?.sort(function (a, b) {
            var textA = a.isPrimary;
            var textB = b.isPrimary;
            return textA > textB ? -1 : textA < textB ? 1 : 0;
        });

        dispatch(setPharmacies([...upDateList]));
    };

    return (
        <>
            <InfoCard
                bodyElement={WalletCardBody}
                heading={'Wallet'}
                containerClassName={styles.walletInformationCardChildren}
                icon={faWallet}
                iconType="FontAwesomeIcon"
                iconSize="xl"
                infoCardDetailsClassName={styles.walletCardBody}
                titleContainerClassName={styles.titleContainerClassName}
            />

            <YourMedicareHealthInsuranceModal insuranceImage={insuranceIcon} />

            <YourLegacyModal insuranceImage={insuranceIcon} />

            <YourSupplementModal
                modalBoxClassName={styles.supplementModalClass}
            />

            {healthPlanInfo && (
                <YourHealthPlanModal
                    insuranceImage={insuranceIcon}
                    plansDetails={healthPlanInfo}
                    onClose={() => {
                        setHealthPlanInfo(undefined);
                    }}
                />
            )}

            {finalPlanInfo && (
                <YourLifePlanModal
                    insuranceImage={insuranceIcon}
                    plansDetails={finalPlanInfo}
                    onClose={() => {
                        setFinalPlanInfo(undefined);
                    }}
                />
            )}
            <ConsumerGuidedExperienceModal
                isOpen={isGuidedModal}
                isFromProfile={true}
            />

            <YourLocationModal />

            <YourDoctorModal />

            <YourHealthInfoModal />

            <YourHealthConditionContentModal isFromProfile={true} />

            <YourProfilePrescriptionsModal
                openPrescriptionModal={() => setPrescriptionModal(true)}
                editDrug={setEditDrug}
                prescriptionsList={prescriptionsList}
                editFromProfile={setEditFromProfile}
                isAdd={setIsAddPrescription}
            />
            <YourProfilePharmacyModal
                openPharmacyModal={() => dispatch(setPharmacyModal(true))}
                PharmacyList={pharmacyList}
                deletePharmacy={handleDeletePharmacy}
                isAdd={setIsAddPharmacy}
            />
            <YourPrescriptionModal
                isOpen={prescriptionModal}
                isFromProfile={true}
                editDrug={editDrug}
                editFromProfile={editFromProfile}
                addDrug={isAddPrescription}
                setAddDrug={setIsAddPrescription}
            />

            <YourMedicaidModal isFromProfile={true} />

            <YourPharmacyModal
                isAddPharmacy={isAddPharmacy}
                isFromProfile={true}
                openPharmacyModal={() => openPharmacyModal()}
                isAdd={setIsAddPharmacy}
            />
        </>
    );
};

export default WalletContainer;
