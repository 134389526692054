import TextFieldWithLabel from 'components/TextFieldWithLabel';

import { placeholder, nameRegex, nameError, invalidPatternError } from './data';

import styles from './styles.module.scss';

const CityTextField = ({
    autoFocus,
    defaultValue,
    disablePattern = false,
    error,
    id,
    labelText,
    register,
    className,
    required = true,
    noPlaceholder = false
}) => (
    <div className={`${className} ${styles.container}`}>
        <TextFieldWithLabel
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            error={error}
            errorMessage={nameError}
            errorMessagePattern={invalidPatternError}
            fieldClassName={styles.textField}
            id={id}
            labelClassName={styles.label}
            labelText={labelText}
            pattern={disablePattern && nameRegex}
            placeholder={!noPlaceholder ? placeholder : ''}
            register={register}
            required={required}
            type="text"
        />
    </div>
);

export default CityTextField;
