export const APPLICATION_SUBMITTED = '/application-submitted';
export const AVAILABLE_AGENTS_ROUTE = '/available-agents';
export const CCPA_ROUTE = '/ccpa-policy';
export const COMPARE_PLANS = '/compare-plans';
export const COMPARE_PLANS_TOKEN =
    '/customer/plans/:contactId/compare/:planIds/:effectiveDate/:request/:token';
export const CONFIRM_EMAIL = '/confirm-email';
export const CONTACT_AGENT_ROUTE = '/contact-agent';
export const CONTACT_AN_AGENT_NOINDEX_ROUTE = '/contact-an-agent';
export const CONTACT_US_ROUTE = '/contact-agent-1';
export const COVERAGE_OPTIONS_ROUTE = '/guide/coverage-options';
export const CUSTOMER_PLAN_SNAPSHOT =
    '/customer/enrollmenthistory/:contactId/:confirmationNumber/:effectiveDate/:request/:token';
export const CREATE_ACCOUNT_ROUTE = '/create-account';
export const DO_NOT_SELL_INFO =
    'https://privacyportal.onetrust.com/webform/ee1cd67a-fc19-4a8a-a048-465b10a9fefd/8a391573-2b45-49cc-a794-9ba6557de300';
export const ENROLLMENT_CHECKOUT_ROUTE = '/continue-checkout';
export const ENROLLMENT_ROUTE = '/guide/enrollment';

export const FINAL_EXPENSE_AGENT = '/final-expense/agent-finder';

export const FINAL_EXPENSE_REQUEST_CALL_BACK =
    '/final-expense/request-call-back';
export const FINAL_EXPENSE_APPLICATION_ROUTE = '/final-expense-application';
export const FINAL_EXPENSE_BASICS_ROUTE = '/final-expense-basics';
export const FINAL_EXPENSE_COVERAGE_OPTIONS_ROUTE =
    '/final-expense-coverage-options';
export const FINAL_EXPENSE_CONTACT_DETAILS_ROUTE =
    '/final-expense/contact-details';
export const FINAL_EXPENSE_HEALTH_CONDITION_ROUTE =
    '/final-expense/health-condition';

export const FINAL_EXPENSE_HOME_ROUTE = '/final-expense/home';
export const FINAL_EXPENSE_PLANS_ROUTE = '/life/products';
export const FINAL_EXPENSE_THANK_YOU_ROUTE = '/life/thank-you';
export const QUICK_PROFILE_ROUTE = '/quick-profile';
export const LEARNING_CENTER_ROUTE = '/learning-center';
export const MAINTENANCE_ROUTE = '/maintenance';
export const MANAGE_ACCOUNT_ROUTE = '/manage-account';
export const MEDICARE_BASICS_ROUTE = '/guide/medicare-basics';
export const MOBILE_TERMS = '/mobile-terms-and-conditions';
export const CONTACT_AN_AGENT_ROUTE = '/medicare/agent-finder';
export const OUR_COMMITMENT_ROUTE = '/our-commitment';
export const PARTS_OF_MEDICARE_ROUTE = '/guide/parts-of-medicare';
export const PASSWORD_UPDATED_ROUTE = '/password-updated';
export const PLANS_ROUTE = '/medicare/products';
export const PLAN_ROUTE = '/plan/:planId/:zip/:fips';
export const PRIVACY_NOTICE_ROUTE = '/privacy-notice';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const REQUEST_CALL_BACK = '/medicare/request-call-back';
export const REQUEST_CALL_BACK_FORM = '/request-call-back-form';
export const SOA_CONFIRM = '/soa-confirmation-form/:linkCode';
export const SOA_CONFIRM_PAGE = '/soa-confirmation-page/:firstName/:lastName';
export const TERMS_ROUTE = '/terms-of-service';
export const THANK_YOU_ROUTE = '/thank-you';
export const VERIFY_EMAIL_ROUTE = '/verify-email';
export const WELCOME_ROUTE = '/welcome';
export const PROFILE_ROUTE = '/profile';
export const AGENT_BIO_ROUTE = '/AgentBio';
export const AGENT_DETAIL_ROUTE = '/AgentDetail/:purl';
export const SETTINGS_ROUTE = '/settings';
export const CLIENT_SSO = '/clientsSSO';
export const AUTHENTICATED_ROUTES = [MANAGE_ACCOUNT_ROUTE, PROFILE_ROUTE];
export const IUL_REQUEST_CALL_BACK =
    '/indexed-universal-life/request-call-back';
export const FIND_AGENT_BIO_ROUTE = '/FindAgentBio';
