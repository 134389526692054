import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import styles from './styles.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, ListItemText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function MultiSelectDropdown({
    className,
    values,
    itemClassName = '',
    displayValue = [],
    onChange = () => {},
    renderValue = null
}) {
    const [open] = React.useState(false);

    const defaultRenderValue = selected => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    flexDirection: 'row'
                }}
            >
                {selected.map(value => (
                    <Chip key={value} label={value} className={itemClassName} />
                ))}
            </Box>
        );
    };

    return (
        <Select
            IconComponent={() => (
                <KeyboardArrowDownIcon
                    className={`${styles.selectIconStyle} ${
                        open && styles.selectIconStyleActive
                    }`}
                />
            )}
            className={`${styles.dropdown} ${className} `}
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={displayValue}
            onChange={onChange}
            renderValue={renderValue || defaultRenderValue}
            MenuProps={MenuProps}
        >
            {values?.map(item => (
                <MenuItem key={item} value={item}>
                    <Checkbox checked={displayValue.includes(item)} />
                    <ListItemText primary={item} />
                </MenuItem>
            ))}
        </Select>
    );
}
