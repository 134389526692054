import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import YourProfilePrescriptionsModalContent from 'components/YourProfilePrescriptionsModalContent';

import dataLayer from 'utilities/dataLayer';

import { headerTitle, saveButtonLabel } from './constants';
import { setProfilePrescriptionsContentModal } from './slice';

import styles from './styles.module.scss';

const YourProfilePrescriptionsModal = ({
    openPrescriptionModal,
    editDrug,
    prescriptionsList,
    editFromProfile,
    isAdd
}) => {
    const profilePrescriptionsModal = useSelector(
        state =>
            state.profilePrescriptionsModalDetails
                ?.profilePrescriptionsContentModal
    );

    useEffect(() => {
        if (profilePrescriptionsModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [profilePrescriptionsModal]);

    const dispatch = useDispatch();

    const onClose = async () => {
        dispatch(setProfilePrescriptionsContentModal(false));
    };

    const footerButtonClicked = async e => {
        dispatch(setProfilePrescriptionsContentModal(false));
    };

    return (
        <Modal className={styles.modal} isOpen={profilePrescriptionsModal}>
            <ModalWindow
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonClicked={footerButtonClicked}
                footerClassName={styles.footerClassName}
                footerLabel={saveButtonLabel}
                headerClassName={styles.headerClassName}
                headerTitle={headerTitle}
                modalWindow={styles.modalWindow}
                onClose={() => onClose()}
                headerHeadingClassName={styles.headerHeadingClassName}
                modalHeaderTitle={styles.modalHeaderTitle}
            >
                <YourProfilePrescriptionsModalContent
                    openPrescriptionModal={openPrescriptionModal}
                    editDrug={editDrug}
                    editFromProfile={editFromProfile}
                    prescriptionsList={prescriptionsList}
                    isAdd={isAdd}
                />
            </ModalWindow>
        </Modal>
    );
};

export default YourProfilePrescriptionsModal;
