/* eslint-disable no-console */
import styles from './styles.module.scss';
import { AgentModalContinueButton } from '../AgentModalContinueButton';
import { AgentModalCollapseRibbon } from '../AgentModalCollapseRibbon';
import { AgentModalContentContainer } from '../AgentModalContentContainer';
import { Box } from '@mui/material';
import AddressTextField from '../AddressTextField';
import CityTextField from '../CityTextField';
import StateDropdownContainer from '../StateDropdownContainer';
import { useForm } from 'react-hook-form';
import ZipCodeTextField from '../ZipCodeTextField';
import { STATES } from '../StateDropdown/data';
import Text from '../Text';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddressDetails } from '../AgentTypeModal/slice';
import { useAuth0 } from '@auth0/auth0-react';
import { get } from '../../temp/utilities/storage';
import { STATE_KEY } from '../../utilities/storageKeys';
import { countySearch } from '../../temp/utilities/apiSearch';
import Heading3 from 'components/Heading3';
import { validateZipCode } from '../../utilities/validate';
import zipcodeContext from '../../contexts/zipCode';

export const AgentModalAddress = ({ navigateToFeFindAgent = () => {} }) => {
    const dispatch = useDispatch();
    const { zipCode, setZipCode } = useContext(zipcodeContext);
    const addressDetails = useSelector(
        state => state.agentTypeDetail.addressDetails
    );
    const [isValid, setIsValid] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [state, setState] = useState(
        addressDetails.stateCode || get(STATE_KEY, '')
    );
    const [stateList, setStateList] = useState(STATES);
    // const updatedZipCode = localStorage.getItem('updatedZipCode');
    const { streetAddress, aptSuiteNumber, city } = addressDetails;
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useForm({
        defaultValues: {
            streetAddress,
            aptSuiteNumber,
            city,
            zip: zipCode
        }
    });
    const { user, getAccessTokenSilently } = useAuth0();

    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };
    const getCounties = async zipCode => {
        const authToken = await getAuthToken();
        let allCounties = await countySearch({ zipCode, authToken }).catch(
            error => {}
        );
        return allCounties;
    };

    const setStateAndStateList = async zip => {
        if (zip && zip.length === 5) {
            const counties = await getCounties(zip);
            if (counties && counties.length > 0) {
                setState(counties[0].state);
            }
            const countyStates = counties.map(c => c.state);
            setStateList(STATES.filter(s => countyStates.includes(s.value)));
        }
    };
    useEffect(() => {
        setValue('zip', zipCode);
        setStateAndStateList(zipCode);
    }, [zipCode]);

    const watchAll = watch();
    const zipValue = watch('zip');

    useEffect(() => {
        setStateAndStateList(zipValue);
    }, [zipValue]);

    const onContinueClick = data => {
        setButtonDisabled(true);
        setZipCode(data.zip);
        dispatch(setAddressDetails({ ...data, stateCode: state }));
        navigateToFeFindAgent();
    };
    useEffect(() => {
        const areFieldsValid = () => {
            if (
                !watchAll?.streetAddress ||
                watchAll?.streetAddress?.trim() === ''
            )
                return false;
            if (!watchAll?.city || watchAll?.city?.trim() === '') return false;
            if (!watchAll?.zip || watchAll?.zip?.trim() === '') return false;
            if (!state) return false;
            return true;
        };
        const isInputValid = areFieldsValid();
        setIsValid(isInputValid);
    }, [watchAll, state]);

    return (
        <form onSubmit={handleSubmit(data => onContinueClick(data))}>
            <AgentModalContentContainer
                id="agentModalAddress"
                className={styles.agentModalAddressContainer}
            >
                <AgentModalCollapseRibbon
                    onClick={() =>
                        document
                            .getElementById('agentPreferenceModal')
                            ?.scrollIntoView({ behavior: 'smooth' })
                    }
                    collapseButtonClassName={styles.collapseButton}
                />
                <Heading3
                    className={styles.addressHeading1}
                    text="What is your address?"
                />
                <Box className={styles.twoFieldRow1}>
                    <AddressTextField
                        id="streetAddress"
                        register={register}
                        defaultValue={streetAddress}
                        error={errors.streetAddress}
                        labelText="Street Address"
                        required={true}
                    />
                    <AddressTextField
                        className={styles.inputField}
                        id="aptSuiteNumber"
                        register={register}
                        defaultValue={aptSuiteNumber}
                        error={errors.aptSuiteNumber}
                        labelText={
                            <div className={styles.aptSuiteNumberLabel}>
                                Apt, Suite Number{' '}
                                <Text
                                    text="(Optional)"
                                    className={styles.optional}
                                />
                            </div>
                        }
                        required={false}
                    />
                </Box>
                <CityTextField
                    id="city"
                    register={register}
                    defaultValue={city}
                    error={errors.city}
                    labelText="City"
                    required={true}
                    className={styles.cityContainer}
                />
                <Box className={styles.twoFieldRow2}>
                    <StateDropdownContainer
                        error={{
                            ...errors.stateCode,
                            message: 'Please select state'
                        }}
                        state={state}
                        setState={setState}
                        className={styles.stateDropdown}
                        dropdownClassName={styles.dropdownWidth}
                        dropDownWithLabelClassName={
                            styles.dropDownWithLabelClassName
                        }
                        stateList={stateList}
                        renderValue={value =>
                            value !== ''
                                ? STATES.find(s => s.value === value)?.label
                                : 'Select one'
                        }
                        required={true}
                    />

                    <ZipCodeTextField
                        disablePattern={true}
                        defaultValue={zipCode}
                        id="zip"
                        labelText="Zip Code"
                        register={register}
                        error={errors?.zip}
                        required={true}
                        className={styles.zipCodeContainer}
                    />
                </Box>
                <AgentModalContinueButton
                    disabled={
                        !isValid || !validateZipCode(zipValue) || buttonDisabled
                    }
                    type="submit"
                    buttonContainerClassName={styles.buttonContainer}
                />
            </AgentModalContentContainer>
        </form>
    );
};
