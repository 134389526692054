import useFetch from 'components/hooks/useFetch';

export const useUpdateLegacyDetails = () => {
    const { Post: updateLegacyDetails } = useFetch(
        `${import.meta.env.VITE_APP_PLANNING_TOOL_SERVICE_URL}/legacySafeguard`
    );

    return { updateLegacyDetails };
};

export const useUpdateMedicareAccountData = () => {
    const { Put: updateMedicareAccountData } = useFetch(
        `${import.meta.env.VITE_APP_ACCOUNT_SERVICE_URL}/Update`
    );

    return { updateMedicareAccountData };
};
