import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect, useRef } from 'react';

import ArrowDownIcon from 'components/ArrowDownIcon';
import Heading6 from 'components/Heading6';
import { useOnClickOutside } from 'components/hooks/useOnClickOutside';
import { useWindowSize } from 'components/hooks/useWindowSize';

import './select.scss';

export const DefaultOption = ({
    label,
    onClick,
    optionsClass,
    prefix = '',
    selected = false,
    showValueAsLabel,
    value,
    ...rest
}) => {
    const handleOptionClick = ev => {
        onClick && onClick(ev, value);
    };

    return (
        <div
            {...rest}
            className={`${
                optionsClass ? 'tobaccoOption' : 'selectOption'
            } option ${selected ? 'selected' : ''}`}
            onClick={handleOptionClick}
        >
            {prefix && <Heading6 className="heading6" text={prefix} />}
            {showValueAsLabel ? value : label}
        </div>
    );
};

DefaultOption.propTypes = {
    label: PropTypes.string,
    selected: PropTypes.bool,
    value: PropTypes.any,
    onClick: PropTypes.func,
    style: PropTypes.object,
    prefix: PropTypes.string
};

export const Select = ({
    Option,
    contactsPage,
    disabled,
    error,
    initialValue,
    isDefaultOpen = false,
    labelPrefix = '',
    onBlur,
    onChange,
    options,
    optionsClass,
    placeholder,
    placeholderClass,
    prefix = '',
    providerModal,
    selectInputBoxClass,
    showValueAlways = false,
    showValueAsLabel = false,
    style
}) => {
    const [isOpen, setIsOpen] = useState(isDefaultOpen);
    const [value, setValue] = useState(initialValue);
    const ref = useRef();

    const { height: windowHeight } = useWindowSize();

    useOnClickOutside(ref, () => {
        setIsOpen(false);
        if (isOpen) {
            onBlur && onBlur();
        }
    });

    useEffect(() => {
        setValue(initialValue);
        setIsOpen(isDefaultOpen);
    }, [initialValue, isDefaultOpen]);

    const handleOptionChange = (ev, value) => {
        ev.preventDefault();
        setValue(value);
        onChange && onChange(value);
        toggleOptionsMenu();
    };

    const toggleOptionsMenu = ev => {
        ev && ev.preventDefault();
        setIsOpen(isOpen => !isOpen && !disabled);
        if (isOpen) {
            onBlur && onBlur();
        }
    };

    const [selectedOption, selectableOptions] = useMemo(() => {
        setIsOpen(isDefaultOpen);
        const selectedOptions = options.filter(
            option => option?.value === value
        );
        const selectableOptions = options.map(option => ({
            ...option,
            selected: option?.value === value,
            optionsClass: optionsClass
        }));
        return [selectedOptions[0], selectableOptions];
    }, [options, value, isDefaultOpen]);

    const heightStyle = useMemo(() => {
        const top = isOpen
            ? ref?.current?.getBoundingClientRect().top + 40
            : 40;
        return windowHeight
            ? {
                  maxHeight:
                      Math.max(
                          Math.min(
                              windowHeight - top,
                              (selectableOptions.length + 1) * 40
                          ),
                          Math.min(selectableOptions.length + 1, 3) * 40
                      ) + 2
              }
            : {
                  maxHeight: 0
              };
    }, [selectableOptions.length, isOpen, windowHeight]);

    const inputBox = (
        <div
            className={`${error ? 'has-error' : ''} ${
                showValueAlways ? 'show-always' : ''
            } ${selectInputBoxClass ? selectInputBoxClass : 'input'} inputbox`}
            onClick={toggleOptionsMenu}
        >
            {value ? (
                <Option
                    prefix={prefix}
                    {...selectedOption}
                    showValueAsLabel={showValueAsLabel}
                />
            ) : (
                <span className={placeholderClass || 'placeholder'}>
                    {placeholder}
                </span>
            )}
            <ArrowDownIcon />
        </div>
    );
    const selectHeader = (
        <div className="select-header">
            <div className="prefix">{placeholder}</div>
            <button onClick={toggleOptionsMenu}>&times;</button>
        </div>
    );
    const optionsContainer = (
        <div
            className="options"
            style={{ maxHeight: heightStyle.maxHeight - 40 }}
        >
            {selectHeader}
            {selectableOptions.map((option, idx) => (
                <Option
                    prefix={labelPrefix}
                    key={idx}
                    {...option}
                    onClick={handleOptionChange}
                />
            ))}
        </div>
    );

    return (
        <div
            ref={ref}
            style={style}
            className={`select ${contactsPage && 'contacts-dd'} ${
                providerModal && 'pr-select'
            } ${!isOpen && showValueAsLabel ? 'short-label' : ''}`}
        >
            <div
                className={`select-container ${isOpen ? 'opened' : 'closed'} ${
                    disabled ? 'disabled' : ''
                }`}
                style={heightStyle}
            >
                {inputBox}
                {isOpen && !disabled && optionsContainer}
            </div>
        </div>
    );
};

Select.propTypes = {
    initialValue: PropTypes.string,
    prefix: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    Option: PropTypes.elementType,
    style: PropTypes.object,
    isDefaultOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool
};

Select.defaultProps = {
    placeholder: '- Select -',
    prefix: '',
    initialValue: null,
    options: [],
    Option: DefaultOption,
    style: {},
    isDefaultOpen: false,
    disabled: false,
    onBlur: () => {},
    error: false
};
