import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import LinkWithArrow from 'components/LinkWithArrow';
import {
    finalExpensePlanProductType,
    finalExpensePlanType,
    medicarePlanProductType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';
import {
    setFinalExpenseAgent,
    setMedicareAgent,
    setSelectedAgentType
} from 'components/AgentTypeModal/slice';
import ProfilePicture from 'components/ProfilePicture';
import PurlBannerAgentExperience from 'components/PurlBannerAgentExperience';
import { PurlBannerAgentInfo } from 'components/PurlBannerAgentInfo';
import { PurlBannerContactInfo } from 'components/PurlBannerContactInfo';
import PurlBannerFindAgent from 'components/PurlBannerFindAgent';
import { PurlBannerGenericInfo } from 'components/PurlBannerGenericInfo';
import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';

import hamburgerContext from 'contexts/hamburger';
import purlAgentContext from 'contexts/purlAgentContext';

import {
    AGENT_BIO_ROUTE,
    CONTACT_AN_AGENT_ROUTE,
    FINAL_EXPENSE_AGENT,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from 'utilities/routes';
import {
    PURL_AGENT_CODE,
    PURL_AGENT_CODE_NEW,
    PURL_AGENT_DATA
} from 'utilities/sessionStorageKeys';
import {
    FINALEXPENSE_AGENT_DATA,
    FINALEXPENSE_AGENT_NPN,
    MEDICARE_AGENT_DATA,
    MEDICARE_AGENT_NPN
} from 'utilities/storageKeys';

import medicareHealthIcon from './images/Health.svg';
import lifeHealthIcon from './images/Life.svg';

import styles from './styles.module.scss';

import { get, remove } from 'temp/utilities/storage';

export const PurlBanner = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth0();
    const purlAgents = useSelector(state => state.mainDetails.purlAgents);
    const selectedAgentType = useSelector(
        state => state.agentTypeDetail.selectedAgentType
    );

    const medicareAgent = useSelector(
        state => state.agentTypeDetail.medicareAgent
    );
    const finalExpenseAgent = useSelector(
        state => state.agentTypeDetail.finalExpenseAgent
    );
    const history = useHistory();
    const purlAgentCtxt = useContext(purlAgentContext);
    const { isMobile } = useContext(hamburgerContext);

    const { purlAgentData } = purlAgentCtxt;

    useEffect(() => {
        if (isAuthenticated) {
            const agentMedicare = purlAgents.find(
                pa => pa.productType === medicarePlanProductType
            );
            const agentFinalExpense = purlAgents.find(
                pa => pa.productType === finalExpensePlanProductType
            );
            dispatch(setMedicareAgent(agentMedicare));
            dispatch(setFinalExpenseAgent(agentFinalExpense));
        }
    }, [purlAgents, isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated && purlAgentData) {
            dispatch(
                setMedicareAgent(
                    purlAgentData.isMedicareAgent ? purlAgentData : null
                )
            );
            dispatch(
                setFinalExpenseAgent(
                    purlAgentData.isFinalExpenseAgent ? purlAgentData : null
                )
            );
        }
    }, [purlAgentData]);

    const navigateToFindAgent = () => {
        if (selectedAgentType === medicarePlanType)
            history.push(REQUEST_CALL_BACK);
        else history.push(FINAL_EXPENSE_REQUEST_CALL_BACK);
    };

    const handleClose = (isReturnToAgent = false) => {
        remove(PURL_AGENT_CODE_NEW);
        remove(PURL_AGENT_CODE);
        remove(PURL_AGENT_DATA);
        remove(MEDICARE_AGENT_DATA);
        remove(FINALEXPENSE_AGENT_DATA);
        remove(FINALEXPENSE_AGENT_NPN);
        remove(MEDICARE_AGENT_NPN);
        localStorage.removeItem('reqAgentData');
        window.location.href = isReturnToAgent
            ? `${import.meta.env.VITE_APP_APP_BASE_URL}${
                  window.location.pathname !== FINAL_EXPENSE_REQUEST_CALL_BACK
                      ? CONTACT_AN_AGENT_ROUTE
                      : FINAL_EXPENSE_AGENT
              }`
            : `${import.meta.env.VITE_APP_APP_BASE_URL}`;
    };

    const agentData = useMemo(() => {
        if (medicareAgent && selectedAgentType === medicarePlanType) {
            return medicareAgent;
        } else if (
            finalExpenseAgent &&
            selectedAgentType === finalExpensePlanType
        ) {
            return finalExpenseAgent;
        }
        return get(PURL_AGENT_DATA);
    }, [selectedAgentType, medicareAgent, finalExpenseAgent]);

    const currentPage = window.location.href.substring(
        window.location.href.indexOf('#') + 1
    );

    const onClickViewMore = () => {
        if (
            window.location.href.includes('quick-profile') &&
            currentPage !== 'personalInformation' &&
            currentPage !== 'complete' &&
            window.location.hash &&
            !isAuthenticated
        ) {
            dispatch(setTargetPage('ViewMore'));
            dispatch(
                setSelectedQuickProfileItem(
                    `${AGENT_BIO_ROUTE}?purl=${agentData.agentPurlCode}`
                )
            );
        } else {
            history.push(`${AGENT_BIO_ROUTE}?purl=${agentData.agentPurlCode}`);
        }
    };

    return medicareAgent || finalExpenseAgent ? (
        <>
            <header
                className={`${styles.purlBannerFull} ${styles.desktopBanner}`}
            >
                <div
                    className={styles.purlBannerFullContent}
                    id="purlBannerContent"
                >
                    <div className={styles.avatarWithTabs}>
                        {(!isMobile || !agentData) && (
                            <ProfilePicture
                                avatarClassName={
                                    !agentData ? styles.avatarClass : ''
                                }
                                className={styles.agentPicture}
                                firstName={agentData?.agentFirstName}
                                lastName={agentData?.agentLastName}
                                profileUrl={
                                    agentData
                                        ? agentData?.profileImageUrl
                                        : agentData?.isMedicareAgent
                                          ? medicareHealthIcon
                                          : lifeHealthIcon
                                }
                                hideCameraOverlay={true}
                            />
                        )}
                        <div
                            className={styles.purlAgentDetailsContainer}
                            id="purlAgentDetailsContainer"
                        >
                            {agentData ? (
                                <PurlBannerAgentInfo
                                    purlAgentData={agentData}
                                    handleClose={handleClose}
                                    isMobile={isMobile}
                                    onClickViewMore={onClickViewMore}
                                />
                            ) : (
                                <PurlBannerFindAgent
                                    selectedAgentType={selectedAgentType}
                                    navigateToFindAgent={navigateToFindAgent}
                                />
                            )}
                        </div>
                    </div>

                    {agentData ? (
                        <>
                            <PurlBannerContactInfo
                                purlAgentData={agentData}
                                isMobile={isMobile}
                            />

                            {isMobile && (
                                <LinkWithArrow
                                    text={'View More'}
                                    className={styles.viewMore}
                                    iconClassName={styles.rightArrowIcon}
                                    onClick={onClickViewMore}
                                />
                            )}
                        </>
                    ) : (
                        <PurlBannerGenericInfo
                            navigateToFindAgent={navigateToFindAgent}
                        />
                    )}
                </div>

                <PurlBannerAgentExperience />
            </header>
            <header
                className={`${styles.purlBannerFull} ${styles.mobileBanner}`}
            >
                <PurlBannerAgentExperience />
            </header>
        </>
    ) : (
        <></>
    );
};
