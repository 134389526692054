import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import Button from 'components/Button';
import {
    completeFinalExpenseCurrentStep,
    setFinalExpenseCurrentStep,
    setFinalExpenseStepper,
    setGuidedModal,
    setGuidedProcess,
    showStepper
} from 'components/ConsumerGuidedExperienceModal/slice';
import {
    MAX_AMOUNT_COVERAGE,
    MAX_AMOUNT_IUL_COVERAGE,
    MAX_AMOUNT_IUL_PREMIUM,
    MAX_AMOUNT_PREMIUM,
    MIN_AMOUNT_COVERAGE,
    MIN_AMOUNT_IUL_COVERAGE,
    MIN_AMOUNT_IUL_PREMIUM,
    MIN_AMOUNT_PREMIUM
} from 'components/CoverageCostFilterInput';
import EditLink from 'components/EditLink';
import FinalExpensePlanCard from 'components/FinalExpensePlanCard';
import Heading3 from 'components/Heading3';
import Metadata from 'components/Metadata';
import PersonalizeQuote from 'components/PersonalizeQuote';
import PlansCta from 'components/PlansCta';
import Text from 'components/Text';
import TextError from 'components/TextError';
import {
    setHealthInfoData,
    setHealthInfoModal
} from 'components/YourHealthInfoModal/slice';
import { setHealthConditionsList } from 'components/YourHealthIntakeModal/slice';
import { setLocationModal } from 'components/YourLocationModal/slice';
import NotFoundIcon from 'components/icons/NotFoundIcon';

import ZipCodeContext from 'contexts/zipCode';

import { getAgeFromDOB } from 'utilities/dateUtils';
import scrollToTop from 'utilities/scrollToTop';
import {
    COVERAGE_AMOUNT,
    FINAL_EXPENSE_KEY,
    FROM_HOME_PAGE,
    IUL_COVERAGE_AMOUNT
} from 'utilities/storageKeys';

import meta from './meta';

import styles from './styles.module.scss';

import Pagination from 'temp/components/Pagination/pagination';
import PlanCardLoader from 'temp/components/PlanCard/loader';
import { Select } from 'temp/components/Select';
import { getFinalExpensePlans } from 'temp/utilities/apiSession/finalExpense';
import { getUserHealthConditions } from 'temp/utilities/apiSession/healthConditions';
import { get, set } from 'temp/utilities/storage';
import {
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK
} from 'utilities/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import useDebounce from 'temp/utilities/useDebounce';
import { SUB_PRODUCT_TYPE_SELECTED } from '../../utilities/storageKeys';
import { useFindAgentModal } from '../hooks/useFindAgentModal';
import { REQUEST_CALL_BACK } from '../../utilities/routes';

const errorMessage = 'Please enter a valid ZIP Code.';

const PAGE_SIZE = 10;

export const PLAN_TYPE_FILTER_FE = 1;

function getPlansAvailableSection(
    planCount,
    plansLoading,
    openZip,
    error,
    state,
    zipCode
) {
    if (plansLoading) {
        return <div />;
    } else if (zipCode.length === 0) {
        return (
            <div className={styles['plansAvailable']}>
                {` 0 options available. `}
            </div>
        );
    } else {
        return (
            <div className={styles['plansAvailable']}>
                {planCount === 0 ? (
                    <>
                        <div>{` 0 options available `}</div>
                        {error && (
                            <TextError
                                className={styles.error}
                                text={errorMessage}
                            />
                        )}
                    </>
                ) : (
                    <div>
                        <span>{planCount} options</span>
                        {` available  `}
                    </div>
                )}
            </div>
        );
    }
}
const PLAN_TYPE_FILTER_IUL = 6;
const FinalExpenseContentContainer = ({
    openZipModal,
    conditionsData,
    coverageSelectedValue,
    coverageAmount,
    monthlyPremiumAmount,
    carrierSelectedValue,
    costTab,
    editZipModalOpen,
    sortSelected,
    setSortSelected,
    setCarrierList,
    setCarrierSelectedValue,
    COVERAGE_TYPE_OPTIONS,
    FINAL_EXPENSES_SORT_OPTIONS,
    finalExpensePlanType = PLAN_TYPE_FILTER_IUL,
    setFinalExpensePlanType,
    tokenData
}) => {
    localStorage.removeItem('healthConditionFromPage');
    const { push } = useHistory();
    const { openAgentModalWithProductType } = useFindAgentModal();
    const [plansLoading, setPlansLoading] = useState(false);
    const [plansAvailable, setPlansAvailable] = useState([]);
    const { county, state, zipCode } = useContext(ZipCodeContext);
    const [currentPlansPage, setCurrentPlansPage] = useState(1);
    const finalExpenseAgent = useSelector(
        state => state.agentTypeDetail.finalExpenseAgent
    );
    const [finalExpenseHealthDetails, setFinalExpenseHealthDetails] = useState(
        get(FINAL_EXPENSE_KEY)
    );
    const healthInfoData = useSelector(
        state => state.healthInfoDetails?.healthInfoData
    );

    const healthConditions = useSelector(
        state => state.healthIntakeDetails.healthConditionsList
    );
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const isFirstLoad = useRef(true);
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        if (localStorage.getItem('countyChange') && state) {
            setFinalExpenseHealthDetails(get(FINAL_EXPENSE_KEY));
            localStorage.removeItem('countyChange');
        }
    }, [localStorage.getItem('countyChange'), state]);

    useEffect(() => {
        (!isAuthenticated || !isFirstLoad.current) &&
            setFinalExpenseHealthDetails(get(FINAL_EXPENSE_KEY));
    }, [healthInfoData, healthConditions]);

    const [finalExpenseDetails] = useState(get(FINAL_EXPENSE_KEY));
    const data = finalExpenseDetails || userProfile;
    const isGender = useMemo(() => Boolean(data?.gender), [data]);

    const debouncedSearchTerm = useDebounce(coverageAmount, 1000);
    const apiCallTest = async () => {
        if (
            !finalExpenseHealthDetails ||
            !finalExpenseHealthDetails.dateOfBirth
        )
            return;
        if (
            coverageAmount &&
            finalExpensePlanType === PLAN_TYPE_FILTER_IUL &&
            (parseInt(coverageAmount) < MIN_AMOUNT_IUL_COVERAGE ||
                parseInt(coverageAmount) > MAX_AMOUNT_IUL_COVERAGE)
        ) {
            if (
                parseInt(coverageAmount) >= MIN_AMOUNT_COVERAGE &&
                parseInt(coverageAmount) <= MAX_AMOUNT_COVERAGE
            ) {
                set(COVERAGE_AMOUNT, coverageAmount);
                finalList();
            }
            set(FROM_HOME_PAGE, false);
            isFirstLoad.current = true;
            return;
        }

        if (
            coverageAmount &&
            finalExpensePlanType !== PLAN_TYPE_FILTER_IUL &&
            (parseInt(coverageAmount) < MIN_AMOUNT_COVERAGE ||
                parseInt(coverageAmount) > MAX_AMOUNT_COVERAGE)
        )
            return;

        if (
            monthlyPremiumAmount &&
            finalExpensePlanType === PLAN_TYPE_FILTER_IUL &&
            (parseInt(monthlyPremiumAmount) < MIN_AMOUNT_IUL_PREMIUM ||
                parseInt(monthlyPremiumAmount) > MAX_AMOUNT_IUL_PREMIUM)
        )
            return;
        if (
            monthlyPremiumAmount &&
            finalExpensePlanType !== PLAN_TYPE_FILTER_IUL &&
            (parseInt(monthlyPremiumAmount) < MIN_AMOUNT_PREMIUM ||
                parseInt(monthlyPremiumAmount) > MAX_AMOUNT_PREMIUM)
        )
            return;

        let coverageSelectedValues = coverageSelectedValue;
        if (coverageSelectedValue === '') {
            coverageSelectedValues = COVERAGE_TYPE_OPTIONS.map(
                item => item.value
            ).join(',');
        } else {
            coverageSelectedValues = [coverageSelectedValue];
        }
        setPlansLoading(true);

        const dataFinal = await getFinalExpensePlans({
            age: getAgeFromDOB(finalExpenseHealthDetails?.dateOfBirth),
            conditionsData: healthConditions,
            usState: state,
            coverageTypes:
                finalExpensePlanType === PLAN_TYPE_FILTER_IUL
                    ? ['SIMPLIFIED_IUL']
                    : coverageSelectedValues,
            tobacco: finalExpenseHealthDetails?.tobaccoUse === 'Yes',
            gender: finalExpenseHealthDetails?.gender === 'Male' ? 'M' : 'F',
            desiredFaceValue:
                costTab === 0
                    ? parseInt(
                          get(
                              finalExpensePlanType === PLAN_TYPE_FILTER_IUL
                                  ? IUL_COVERAGE_AMOUNT
                                  : COVERAGE_AMOUNT
                          )
                      )
                    : null,
            desiredMonthlyRate:
                costTab === 1 ? parseInt(monthlyPremiumAmount) : null,
            userHeight: finalExpenseHealthDetails?.height
                ? parseInt(finalExpenseHealthDetails?.height)
                : finalExpenseHealthDetails?.heightInFT
                  ? parseInt(finalExpenseHealthDetails?.heightInFT || 0) * 12 +
                    parseInt(finalExpenseHealthDetails?.heightIn || 0)
                  : 0,
            userWeight: finalExpenseHealthDetails?.weight
                ? parseInt(finalExpenseHealthDetails?.weight)
                : 0
        });
        setPlansLoading(false);
        setPlansAvailable(dataFinal.rtsPlans);
        const isFromHomePage = get(FROM_HOME_PAGE);
        if (
            (dataFinal?.rtsPlans?.length === 0 &&
                isFirstLoad.current &&
                finalExpensePlanType === PLAN_TYPE_FILTER_IUL &&
                isFromHomePage) ||
            (finalExpensePlanType === PLAN_TYPE_FILTER_IUL &&
                dataFinal?.hasNoIULPlansAvailable)
        ) {
            setPlansAvailable([]);
            finalList();
            set(FROM_HOME_PAGE, false);
        }
        isFirstLoad.current = false;
    };

    const finalList = async () => {
        const coverageAmount = tokenData?.coverage_amount
            ? tokenData?.coverage_amount
            : !isNaN(get(COVERAGE_AMOUNT))
              ? String(get(COVERAGE_AMOUNT))
              : '15000';

        const dataFinal = await getFinalExpensePlans({
            age: getAgeFromDOB(finalExpenseHealthDetails?.dateOfBirth),
            conditionsData: healthConditions,
            usState: state,
            coverageTypes: [COVERAGE_TYPE_OPTIONS[0].value],
            tobacco: finalExpenseHealthDetails?.tobaccoUse === 'Yes',
            gender: finalExpenseHealthDetails?.gender === 'Male' ? 'M' : 'F',
            desiredFaceValue: costTab === 0 ? parseInt(coverageAmount) : null,
            desiredMonthlyRate:
                costTab === 1 ? parseInt(monthlyPremiumAmount) : null,
            userHeight: finalExpenseHealthDetails?.height
                ? parseInt(finalExpenseHealthDetails?.height)
                : finalExpenseHealthDetails?.heightInFT
                  ? parseInt(finalExpenseHealthDetails?.heightInFT || 0) * 12 +
                    parseInt(finalExpenseHealthDetails?.heightIn || 0)
                  : 0,
            userWeight: finalExpenseHealthDetails?.weight
                ? parseInt(finalExpenseHealthDetails?.weight)
                : 0
        });
        if (dataFinal.rtsPlans?.length) {
            setFinalExpensePlanType(PLAN_TYPE_FILTER_FE);
            setPlansAvailable(dataFinal.rtsPlans);
            set(IUL_COVERAGE_AMOUNT, '50000');
        }
    };

    useEffect(() => {
        const getHealthConditions = async () => {
            if (isAuthenticated) {
                const authToken = await getAccessTokenSilently();
                const conditionsDataList = await getUserHealthConditions({
                    consumerId: user?.consumerid,
                    authToken
                });

                dispatch(setHealthConditionsList(conditionsDataList));
                const tobaccoUse = userProfile.isTobaccoUser
                    ? 'Yes'
                    : userProfile.isTobaccoUser === false
                      ? 'No'
                      : '';
                const dateVal = userProfile.dateOfBirth
                    ? moment(userProfile.dateOfBirth).format('MM/DD/YYYY')
                    : '';

                const feetVal = Math.floor(userProfile.height / 12);
                const inches = userProfile.height % 12;

                let payload = {};
                payload.gender = userProfile.gender;
                payload.heightInFT = feetVal;
                payload.heightIn = inches;
                payload.weight = userProfile.weight;
                payload.tobaccoUse = tobaccoUse;
                payload.zipCode = zipCode;
                payload.county = county;
                payload.dateOfBirth = dateVal;
                set(FINAL_EXPENSE_KEY, payload);
                dispatch(setHealthInfoData(payload));
                setFinalExpenseHealthDetails(payload);

                await apiCallTest();
            }
        };

        getHealthConditions();
    }, [isFirstLoad]);

    useEffect(() => {
        state && apiCallTest();
    }, [
        coverageSelectedValue,
        debouncedSearchTerm,
        setCarrierList,
        monthlyPremiumAmount,
        COVERAGE_TYPE_OPTIONS,
        finalExpenseHealthDetails,
        setCarrierSelectedValue,
        costTab,
        zipCode,
        state,
        finalExpensePlanType
    ]);

    const pagedStart = (currentPlansPage - 1) * PAGE_SIZE;
    const pageLimit = PAGE_SIZE * currentPlansPage;

    const gotoHealthCondition = () => {
        localStorage.setItem('healthConditionFromPage', 'plansPage');
        dispatch(setGuidedProcess(true));

        zipCode
            ? dispatch(setHealthInfoModal(true))
            : dispatch(setLocationModal(true));

        dispatch(setFinalExpenseStepper());
        dispatch(showStepper());
        dispatch(completeFinalExpenseCurrentStep());
        !zipCode && dispatch(setFinalExpenseCurrentStep(0));
    };

    const sortedPlans = useMemo(
        () =>
            plansAvailable?.sort((a, b) => {
                if (sortSelected === FINAL_EXPENSES_SORT_OPTIONS[0].value) {
                    return (
                        a.modalRates[0].totalPremium -
                        b.modalRates[0].totalPremium
                    );
                } else if (
                    sortSelected === FINAL_EXPENSES_SORT_OPTIONS[1].value
                ) {
                    return (
                        b.modalRates[0].totalPremium -
                        a.modalRates[0].totalPremium
                    );
                } else if (
                    sortSelected === FINAL_EXPENSES_SORT_OPTIONS[2].value
                ) {
                    return a.carrier.name.localeCompare(b.carrier.name);
                } else if (
                    sortSelected === FINAL_EXPENSES_SORT_OPTIONS[3].value
                ) {
                    return b.carrier.name.localeCompare(a.carrier.name);
                }
                return 0;
            }),
        [FINAL_EXPENSES_SORT_OPTIONS, plansAvailable, sortSelected]
    );
    const currentPlans = sortedPlans?.slice(pagedStart, pageLimit) || [];
    useEffect(() => {
        setCarrierSelectedValue({});
        const currentPlansSliced = sortedPlans?.slice(pagedStart, pageLimit);
        const carriers = [];
        let i = 0;
        while (carriers?.length < 7 && i < currentPlansSliced?.length) {
            const carrierId = currentPlansSliced[i].carrier.id;
            if (carriers.find(item => item.id === carrierId)) {
                i++;
                continue;
            }
            carriers.push({
                label: currentPlansSliced[i].carrier.name,
                id: carrierId,
                onChange: value => {
                    setCarrierSelectedValue(prev => ({
                        ...prev,
                        [carrierId]: value.target.checked
                    }));
                }
            });
            i++;
        }
        carriers.sort((a, b) => a.label.localeCompare(b.label));
        setCarrierList([...carriers]);
    }, [
        sortedPlans,
        pageLimit,
        pagedStart,
        setCarrierList,
        setCarrierSelectedValue
    ]);

    const carrierSelectedCount = Object.keys(carrierSelectedValue).filter(
        key => carrierSelectedValue[key]
    ).length;

    const filteredPlans = currentPlans?.filter(plan => {
        if (
            carrierSelectedCount === 0 ||
            carrierSelectedValue[plan.carrier.id]
        ) {
            return true;
        }
        return false;
    });

    const getStarted = e => {
        dispatch(setGuidedModal(true));
    };
    const handleContactAgent = () => {
        if (finalExpenseAgent) {
            const subProductTypeSelected = get(SUB_PRODUCT_TYPE_SELECTED);
            if (
                selectedPlanType === 'Final Expense' &&
                subProductTypeSelected === 'Final Expense'
            ) {
                push(FINAL_EXPENSE_REQUEST_CALL_BACK);
            } else {
                push(IUL_REQUEST_CALL_BACK);
            }
        } else {
            openAgentModalWithProductType({
                title: 'Find an Agent',
                navigationLink: REQUEST_CALL_BACK,
                medicareLink: REQUEST_CALL_BACK,
                finalExpenseLink:
                    finalExpensePlanType === PLAN_TYPE_FILTER_IUL
                        ? IUL_REQUEST_CALL_BACK
                        : FINAL_EXPENSE_REQUEST_CALL_BACK
            });
        }
    };

    return (
        <div className={styles.content}>
            <PersonalizeQuote
                onClick={gotoHealthCondition}
                planType={selectedPlanType}
            />
            <Metadata
                alt={meta?.alt}
                description={meta?.description}
                image={meta?.image}
                largeImage={meta?.largeImage}
                title={meta?.title}
            />

            <div className={styles.header}>
                {getPlansAvailableSection(
                    filteredPlans?.length,
                    plansLoading,
                    openZipModal,
                    false,
                    state,
                    zipCode
                )}
                <div className={styles['sortSelect']}>
                    <Select
                        initialValue={sortSelected}
                        onChange={value => setSortSelected(value)}
                        options={FINAL_EXPENSES_SORT_OPTIONS}
                        prefix="Sort by: "
                    />
                </div>
            </div>
            <div className={styles.planCardsContainer}>
                {plansLoading && (
                    <div className={styles.loaderContainer}>
                        {new Array(10).fill(0).map((_, index) => (
                            <PlanCardLoader
                                key={`Plan${index}}`}
                            ></PlanCardLoader>
                        ))}
                    </div>
                )}
                {!plansLoading &&
                    filteredPlans?.length > 0 &&
                    filteredPlans.map((plan, index, items) => {
                        let conditionList = [];
                        if (plan.reason?.categoryReasons?.length > 0) {
                            conditionList = plan.reason?.categoryReasons?.map(
                                // eslint-disable-next-line array-callback-return
                                ({ categoryId, lookBackPeriod }) => {
                                    const condition = healthConditions.find(
                                        item => item.conditionId === categoryId
                                    );
                                    if (condition) {
                                        return {
                                            name: condition?.conditionName,
                                            lookBackPeriod
                                        };
                                    }
                                }
                            );
                        }
                        const planCard = {
                            planId: plan.id,
                            planName: plan.product?.name,
                            planImage: plan.carrier?.logoUrl,
                            coverageAmount: plan?.faceValue,
                            premiumMonthly: plan.modalRates[0]?.totalPremium,
                            policyFee: plan?.policyFee,
                            coverageType: COVERAGE_TYPE_OPTIONS.find(
                                item => item.value === coverageSelectedValue
                            )?.label,
                            planInfo: plan.product?.benefits,
                            eligibility: plan?.eligibility,
                            conditionList: conditionList
                        };
                        return (
                            <>
                                {' '}
                                <div
                                    className={styles.planCard}
                                    key={planCard.id}
                                >
                                    <FinalExpensePlanCard
                                        planData={planCard}
                                        finalExpensePlanType={
                                            finalExpensePlanType
                                        }
                                    />
                                </div>
                                {(index === 2 ||
                                    index === items.length - 1) && (
                                    <PlansCta
                                        finalExpensePlanType={
                                            finalExpensePlanType
                                        }
                                    />
                                )}
                            </>
                        );
                    })}
                {!plansLoading &&
                    (!plansAvailable || filteredPlans?.length === 0) && (
                        <>
                            <div
                                key={'NoResultsCard'}
                                className={styles.noResultCard}
                            >
                                <div className={styles.notFoundIcon}>
                                    <NotFoundIcon />
                                </div>
                                <Heading3
                                    className={styles.notFoundHeading}
                                    text={'No Policies Found.'}
                                />
                                <div className={'no-results-reason'}>
                                    <Text
                                        className={styles.notFoundReason}
                                        text={
                                            isGender
                                                ? finalExpensePlanType ===
                                                  PLAN_TYPE_FILTER_IUL
                                                    ? 'There are no policies of this type for the specifications you provided, You can try the other product or contact an agent for more options.'
                                                    : `We couldn't find any matching plans`
                                                : 'Please add your ZIP Code and health information to view available policies in your area.'
                                        }
                                    />
                                </div>
                                {!isGender && (
                                    <Button
                                        className={styles.button}
                                        label="Get Started"
                                        onClick={getStarted}
                                    />
                                )}
                                {finalExpensePlanType ===
                                    PLAN_TYPE_FILTER_IUL &&
                                    isGender && (
                                        <div className={styles.planFooter}>
                                            <Button
                                                label={`Contact ${finalExpenseAgent?.agentFirstName ?? 'an Agent'}`}
                                                className={
                                                    styles.footerAgentButton
                                                }
                                                onClick={handleContactAgent}
                                            />
                                        </div>
                                    )}
                            </div>
                            <PlansCta
                                finalExpensePlanType={finalExpensePlanType}
                            />
                        </>
                    )}
            </div>
            <Pagination
                currentPlansPage={currentPlansPage}
                resultName="options"
                resultTrailer={
                    <>
                        <span className={styles['plansZip']}>
                            {' '}
                            <EditLink
                                onClick={() => openZipModal(true)}
                                text={state}
                            />
                        </span>
                    </>
                }
                totalPages={Math.ceil(sortedPlans?.length / PAGE_SIZE)}
                totalResults={sortedPlans?.length || 0}
                pageSize={PAGE_SIZE}
                onPageChange={page => {
                    scrollToTop();
                    setCurrentPlansPage(page);
                }}
            />
        </div>
    );
};

export default FinalExpenseContentContainer;
