import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from 'utilities/routes';
import { CONTACT_CALLBACK_KEY } from 'utilities/storageKeys';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';
import { useFindAgentModal } from '../hooks/useFindAgentModal';

const FooterListItem = ({
    className = '',
    externalURL = false,
    lines1Text = '',
    lines2Text = '',
    link,
    onClickCallbackFn,
    text,
    twoLines = false,
    id = ''
}) => {
    const { openAgentModal } = useFindAgentModal();
    const renderTwoLines = useMemo(() => {
        return (
            <>
                <a
                    className={`${styles.link} ${styles.linkBlock}`}
                    href={link}
                    rel="noreferrer"
                    target="_blank"
                >
                    {lines1Text}
                </a>
                <a
                    className={`${styles.link} ${styles.linkBlock}`}
                    href={link}
                    rel="noreferrer"
                    target="_blank"
                >
                    {lines2Text}
                </a>
            </>
        );
    }, []);

    const renderExternalURLComponent = () => {
        if (twoLines) {
            return renderTwoLines;
        }

        return (
            <a className={styles.link} href={link} rel="noreferrer" id={id}>
                {text}
            </a>
        );
    };

    const data = {
        title: 'Find an Agent',
        navigationLink: REQUEST_CALL_BACK,
        medicareLink: REQUEST_CALL_BACK,
        finalExpenseLink: FINAL_EXPENSE_REQUEST_CALL_BACK
    };

    const ContactAnAgent = () => {
        set(CONTACT_CALLBACK_KEY, 'true');
        openAgentModal({
            ...data
        });
    };

    const extraProps = {};

    if (onClickCallbackFn === 'ContactAnAgent')
        extraProps.onClick = ContactAnAgent;

    return (
        <li
            className={`${styles.footerListItem} ${className}`}
            id={typeof text === 'string' ? text?.replaceAll(' ', '_') : ''}
            {...extraProps}
        >
            {link ? (
                externalURL ? (
                    renderExternalURLComponent()
                ) : (
                    <Link
                        className={styles.link}
                        to={link}
                        id={
                            typeof text === 'string'
                                ? `${text?.replaceAll(' ', '_')}Link`
                                : ''
                        }
                    >
                        {text}
                    </Link>
                )
            ) : (
                text
            )}
        </li>
    );
};

export default FooterListItem;
