import { Link } from 'react-router-dom';

import { PRIVACY_NOTICE_ROUTE, TERMS_ROUTE } from 'utilities/routes';

export const PrivacyLink = ({ className = '' }) => {
    return (
        <Link className={className} to={PRIVACY_NOTICE_ROUTE}>
            Privacy Policy
        </Link>
    );
};
export const TermsAndConditionsLink = ({ className = '' }) => {
    return (
        <Link className={className} to={TERMS_ROUTE}>
            Terms & Conditions
        </Link>
    );
};
