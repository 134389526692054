import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import ActionText from 'components/ActionText';
import Button from 'components/Button';
import FeedbackCard from 'components/FeedbackCard';
import PageSpinner from 'components/PageSpinner';
import TextFieldWithLabel from 'components/TextFieldWithLabel';
import {
    setView,
    setIsFooterButtonDisabled
} from 'components/YourSupplementModal/slice';
import useFetch from 'components/hooks/useFetch';

import './index.css';
import supplementFrequencyOptions, {
    frequencyKeyMap
} from './supplementFrequencyOptions';

import styles from './styles.module.scss';

import Select from 'temp/components/MuiSelect';

const YourSupplementForm = forwardRef(({ supplement }, ref) => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const { consumerId } = userProfile;
    const { supplementId } = supplement;
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [message, setMessage] = useState('');
    const [frequency, setFrequency] = useState('');
    const { loginWithRedirect } = useAuth0();

    const { Post: addSupplement } = useFetch(
        `${import.meta.env.VITE_APP_SUPPLEMENTS_SERVICE_URL}/Add`
    );

    const { Put: updateSupplement } = useFetch(
        `${
            import.meta.env.VITE_APP_SUPPLEMENTS_SERVICE_URL
        }/UpdateSupplements/${supplementId}`
    );

    const { Delete: deleteSupplement } = useFetch(
        `${
            import.meta.env.VITE_APP_SUPPLEMENTS_SERVICE_URL
        }/DeleteSupplements/${supplementId}`
    );

    const {
        register,
        handleSubmit,
        formState: { isDirty },
        watch
    } = useForm({
        defaultValues: {
            supplementName: supplement?.supplementName,
            dosage: supplement?.dosage
        }
    });

    useEffect(() => {
        const { frequency } = supplement;
        let frequencyValue = frequency ? frequencyKeyMap[frequency] : undefined;
        setFrequency(frequencyValue);
    }, []);

    const watchAll = watch();

    const handleResponse = async (
        response,
        successMessage,
        errorMessage,
        callback = () => {}
    ) => {
        if (response.status >= 200 && response.status < 300) {
            setLoading(false);
            setMessage(successMessage);
            callback();
        } else {
            setMessage(errorMessage);
            setLoading(false);
            if (response.status === 401) {
                await loginWithRedirect();
            }
        }
    };

    const AddOrUpdateSuppliement = async data => {
        setLoading(true);
        if (isDisable) {
            setLoading(false);
            return false;
        }
        let payload = {
            consumerId: consumerId,
            supplementName: data.supplementName,
            dosage: data.dosage,
            frequency: frequency
        };
        if (supplementId)
            payload = {
                ...payload,
                supplementId
            };

        const response = supplementId
            ? await updateSupplement(payload, true)
            : await addSupplement(payload, true);
        await handleResponse(
            response,
            `Your supplement has been ${supplementId ? 'updated' : 'added'}`,
            `There is a problem ${
                supplementId ? 'updating' : 'adding'
            } your supplement`,
            () => {
                dispatch(setView('list'));
            }
        );
        setLoading(false);
    };

    const onFrequencyChange = e => {
        const value = e?.target?.value;
        setFrequency(value);
    };

    const removeSupplement = async () => {
        const response = await deleteSupplement();
        setMessage(response);
        setLoading(false);
        dispatch(setView('list'));
    };

    useEffect(() => {
        const isValid = () => {
            if (watchAll?.supplementName?.trim() === '') return false;
            if (watchAll?.dosage?.trim() === '') return false;
            if (typeof frequency === 'undefined') return false;
            return true;
        };
        const state = isValid();
        setIsDisable(!state);
        dispatch(setIsFooterButtonDisabled(!state));
         
    }, [watchAll, frequency]);

    function formSubmit() {
        document.getElementById('modalSubmitButton').click();
    }

    useImperativeHandle(ref, () => ({
        formSubmit
    }));

    return (
        <form
            onSubmit={handleSubmit(data => AddOrUpdateSuppliement(data))}
            className={styles.supplementForm}
        >
            {loading && <PageSpinner />}
            {message.length > 0 && !isDirty ? (
                <FeedbackCard
                    severity="success"
                    text={message}
                    onClose={() => setMessage('')}
                />
            ) : null}
            <TextFieldWithLabel
                fieldClassName={styles.fieldClassName}
                id={'supplementName'}
                labelClassName={styles.labelClassName}
                labelText={'Supplement Name'}
                placeholder="Type supplement name"
                register={register}
                required
                type="text"
            />
            <TextFieldWithLabel
                fieldClassName={styles.fieldClassName}
                id={'dosage'}
                labelClassName={styles.labelClassName}
                labelText={'Dosage'}
                placeholder="Type Dosage"
                register={register}
                required
                type="text"
            />
            <Select
                className={styles.frequency}
                data={supplementFrequencyOptions}
                onChange={onFrequencyChange}
                selectClassName={styles.selectClassName}
                title="Frequency"
                value={frequency}
                valueKey="value"
            />

            <Button
                className={styles.modalSubmitButton}
                id="modalSubmitButton"
                label="Submit"
                type="submit"
            />

            {supplementId && (
                <ActionText
                    className={styles.removeSupplement}
                    onClick={removeSupplement}
                >
                    Remove Supplement
                </ActionText>
            )}
        </form>
    );
});

export default YourSupplementForm;
