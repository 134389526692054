import Icon from 'components/Icon';
import TextAsLink from 'components/TextAsLink';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconWithLink = ({
    alt = '',
    caption = '',
    className = '',
    iconClassName,
    image,
    isImageClickable = false,
    labelClassName = '',
    onClick,
    display = '',
    isTextDisplayRight = true,
    iconType = 'icon',
    iconSize
}) => {
    if (!image) {
        throw new Error('No image was specified');
    }

    return (
        <div className={`${className} ${styles.iconWithLink} ${display}`}>
            {!isTextDisplayRight && (
                <TextAsLink
                    className={`${labelClassName} ${styles.textAsLink}`}
                    text={caption}
                    onClick={onClick}
                />
            )}

            {isImageClickable ? (
                <Icon
                    alt={alt}
                    className={`${iconClassName} ${styles.icon}`}
                    image={image}
                    onClick={onClick}
                />
            ) : iconType === 'FontAwesomeIcon' ? (
                <FontAwesomeIcon
                    className={`${iconClassName} ${styles.icon}`}
                    color={'#052a63'}
                    icon={image}
                    size={iconSize}
                    style={{ width: '40px', height: '40px' }}
                />
            ) : (
                <Icon
                    alt={alt}
                    className={`${iconClassName} ${styles.icon}`}
                    image={image}
                />
            )}

            {isTextDisplayRight && (
                <TextAsLink
                    className={`${labelClassName} ${styles.textAsLink}`}
                    text={caption}
                    onClick={onClick}
                />
            )}
        </div>
    );
};

export default IconWithLink;
