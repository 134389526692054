import React from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/Button';
import { medicarePlanType } from 'components/PlanTypeModal/constants';
import Text from 'components/Text';

import {
    finalExpenseDescription,
    finalExpenseDescriptionWhenNoData,
    medicareDescription,
    medicareDescriptionWhenNoData
} from './constants';

import styles from './styles.module.scss';

const PersonalizeQuote = ({ onClick, planType }) => {
    const medicareSteps = useSelector(
        state => state.guidedDetails.medicareSteps
    );
    const finalExpenseSteps = useSelector(
        state => state.guidedDetails.finalExpenseSteps
    );
    const allStepsCompleted =
        planType === medicarePlanType
            ? medicareSteps.slice(1).some(i => i.isCompleted)
            : finalExpenseSteps.slice(1).some(i => i.isCompleted);
    return (
        <div className={styles.healthCondition}>
            <div>
                <Text
                    className={styles.quote}
                    text={
                        allStepsCompleted
                            ? 'Keep your information up to date'
                            : 'Get a personalized quote'
                    }
                />
                <Text
                    className={styles.text}
                    text={
                        planType === medicarePlanType
                            ? allStepsCompleted
                                ? medicareDescription
                                : medicareDescriptionWhenNoData
                            : allStepsCompleted
                            ? finalExpenseDescription
                            : finalExpenseDescriptionWhenNoData
                    }
                />
            </div>
            <Button
                label="View"
                className={styles.viewButton}
                onClick={onClick}
            />
        </div>
    );
};

export default PersonalizeQuote;
