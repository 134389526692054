import PropTypes from 'prop-types';

import Checkbox, { checkboxProps } from 'components/Checkbox';

import './checkboxGroup.scss';

function CheckboxGroup({
    checkboxes,
    className,
    labelClass,
    borderLabelClass,
    activeClass,
    checkboxInput
}) {
    return (
        <div className={`checkbox-group ${className}`}>
            {checkboxes.map((box, index) => {
                return (
                    <Checkbox
                        checkboxInput={checkboxInput}
                        key={box.label}
                        disabled={box.disabled}
                        onChange={event => box.onChange(event.target.value)}
                        {...box}
                        labelClass={`${
                            index !== checkboxes.length - 1 && borderLabelClass
                        }  ${labelClass}`}
                        activeClass={activeClass}
                        id={box.label}
                    />
                );
            })}
        </div>
    );
}

CheckboxGroup.propTypes = {
    checkboxes: PropTypes.arrayOf(PropTypes.shape(checkboxProps))
};

export default CheckboxGroup;
