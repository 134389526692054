import { useContext, useState, useRef, useEffect } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select';

import Body2DMSans from 'components/Body2Bold';
import TextError from 'components/TextError';
import useQueryParams from 'components/hooks/useQueryParams';

import ZipCodeContext from 'contexts/zipCode';

import styles from './styles.module.scss';

const errorMessage = 'Please Select County.';

const CountyDropdown = ({
    className = '',
    id = 'countySelect',
    onChange,
    labelClass = styles.text,
    labelName = 'County'
}) => {
    const { counties, county, buttonDisabled, error } =
        useContext(ZipCodeContext);
    const queryParams = useQueryParams();
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [value, setValue] = useState(county);
    const numberOfCounties = counties?.length;
    const openRef = useRef(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setValue(county || queryParams?.get('countyFIPS'));
    }, [county, queryParams.get('countyFIPS')]);

    useEffect(() => {
        if (counties?.length === 0) setValue('');
        else if(county) setValue(county)
    }, [counties]);


    useEffect(() => {
        const viewPlansButtonDoc = document.getElementById('footerButton');
        if (!buttonDisabled && openRef.current) {
            viewPlansButtonDoc && viewPlansButtonDoc.focus();
        }
        const handleEnter = e => {
            if (e.keyCode === 13 && buttonDisabled) {
                setShowErrorMsg(true);
            } else if (!buttonDisabled && e.keyCode === 13) {
                setShowErrorMsg(false);
            }
        };
        document.addEventListener('keypress', handleEnter);
        return () => document.removeEventListener('keypress', handleEnter);
    }, [buttonDisabled]);

    const countyNameByFips = fips => {
        let label = '';

        if (Array.isArray(counties) && numberOfCounties) {
            label = counties.find(c => c.countyFIPS === fips);
            label = label?.countyName || '';
        }

        return label;
    };

    const handleOpen = () => {
        setOpen(true);

        openRef.current = true;
    };

    const handleClose = e => {
        openRef.current = false;
        setOpen(false);
    };

    function handleKeydown(e) {
        let viewPlansButtonDoc = document.getElementById('footerButton');
        viewPlansButtonDoc == null &&
            (viewPlansButtonDoc = document.getElementById('radius'));
        viewPlansButtonDoc == null &&
            (viewPlansButtonDoc = document.getElementById('city'));

        const currentInputDoc = document.getElementById(id);

        if (e.code === 'Tab') {
            if (!openRef.current) {
                currentInputDoc && currentInputDoc.blur();
                viewPlansButtonDoc && viewPlansButtonDoc.focus();
                handleOpen();
                setValue(value => {
                    let index = 0;
                    Array.isArray(counties) &&
                        counties.length > 1 &&
                        counties.forEach((item, idx) => {
                            if (item.countyFIPS === value) {
                                index = idx;
                            }
                        });
                    let currentValue =
                        counties[index]?.countyFIPS || counties[0]?.countyFIPS;
                    onChange({ target: { value: currentValue } });
                    setShowErrorMsg(false);
                    return currentValue;
                });
                return;
            }
            if (openRef.current) {
                handleClose();
                return;
            }
        }
    }

    useEffect(() => {
        const currentInputDoc = document.getElementById(id);
        currentInputDoc.onkeydown = handleKeydown;

        return () => {
            currentInputDoc.onkeydown = null;
        };
         
    }, [value]);

    const renderValue = value =>
        value ? countyNameByFips(value) : 'Select One';

    return (
        <div className={`${styles.container} ${className}`}>
            <Body2DMSans
                className={labelClass}
                text={labelName}
                id="countyLabel"
            />
            <Select
                value={value}
                open={open}
                className={styles.countyTextField}
                displayEmpty={true}
                renderValue={renderValue}
                variant="outlined"
                error={showErrorMsg}
                onOpen={handleOpen}
                onClose={handleClose}
                onChange={e => {
                    typeof onChange == 'function' && onChange(e);
                    setShowErrorMsg(false);
                    setValue(e.target.value);
                }}
                disabled={error}
                IconComponent={() => (
                    <KeyboardArrowDownIcon
                        className={`${styles.selectIconStyle} ${
                            open && styles.selectIconStyleActive
                        }`}
                    />
                )}
                id={id}
            >
                {Array.isArray(counties) &&
                    counties.map(({ countyFIPS, countyName }) => (
                        <MenuItem
                            key={countyFIPS}
                            value={countyFIPS}
                            id={countyName ?? 'menuItem'}
                        >
                            {countyName}
                        </MenuItem>
                    ))}
            </Select>

            {showErrorMsg && (
                <TextError className={styles.textError} text={errorMessage} />
            )}
        </div>
    );
};

export default CountyDropdown;
