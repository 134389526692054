import { Box, Chip } from '@mui/material';
import styles from './styles.module.scss';
import Heading3 from 'components/Heading3';
import Heading5 from '../Heading5';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { useContext, useEffect, useState } from 'react';
import { AgentModalContinueButton } from '../AgentModalContinueButton';
import { AgentModalCollapseRibbon } from '../AgentModalCollapseRibbon';
import { AgentModalContentContainer } from '../AgentModalContentContainer';
import { AGENT_API_BASE_URL } from '../../utilities/env';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSelectedLanguageIds,
    setSelectedLanguages
} from '../AgentTypeModal/slice';
import hamburgerContext from '../../contexts/hamburger';
import Body2 from '../Body2';

export const LanguageSelection = ({ onClose }) => {
    const { isMobile } = useContext(hamburgerContext);
    const dispatch = useDispatch();
    const [languages, setLanguages] = useState([]);
    const selectedLanguages = useSelector(
        state => state.agentTypeDetail.selectedLanguages
    );
    useEffect(() => {
        const fetchLanguages = async () => {
            const response = await fetch(
                `${AGENT_API_BASE_URL}/AgentProfessionalInfo/Languages`
            );
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }

            const languagesList = await response.json();
            setLanguages(languagesList);
        };
        fetchLanguages();
    }, []);
    const onChange = event => {
        const {
            target: { value }
        } = event;
        const values = typeof value === 'string' ? value.split(',') : value;
        const languageValues = languages
            .filter(language => values.includes(language.name))
            .map(language => language.name);
        const languageIds = languages
            .filter(language => values.includes(language.name))
            .map(language => language.languageId);
        dispatch(setSelectedLanguages(languageValues));
        dispatch(setSelectedLanguageIds(languageIds));
    };

    const renderValue = (selected = [], displayCount = 3) => {
        return selected.length > displayCount ? (
            <Box className={styles.languageSelectedContainer}>
                <Box className={styles.languageSelectedValuesContainer}>
                    {selected.slice(0, displayCount).map(value => (
                        <Chip
                            key={value}
                            label={value}
                            className={styles.languageSelected}
                        />
                    ))}
                </Box>
                <Body2
                    text={`+${selected.length - displayCount}`}
                    className={styles.remCount}
                />
            </Box>
        ) : (
            <Box className={styles.languageSelectedContainer}>
                <Box className={styles.languageSelectedValuesContainer}>
                    {selected.map(value => (
                        <Chip
                            key={value}
                            label={value}
                            className={styles.languageSelected}
                        />
                    ))}
                </Box>
            </Box>
        );
    };

    return (
        <AgentModalContentContainer
            id="languageSelect"
            className={styles.languageSelectionContainer}
        >
            <AgentModalCollapseRibbon
                onClick={() =>
                    document
                        .getElementById('agentZipBlock')
                        ?.scrollIntoView({ behavior: 'smooth' })
                }
            />
            <Heading3
                className={styles.languageSelectionHeading1}
                text="In addition to English, what other languages would be helpful for your agent to know?"
            />
            <Box
                display="flex"
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent="flex-start"
                width="100%"
                gap="5px"
                flexDirection={isMobile ? 'column' : 'row'}
            >
                <Heading5
                    className={styles.languageSelectionHeading5}
                    text="Choose a language "
                />
                <Heading5
                    className={styles.languageSelectionHeading5}
                    text="(Select all that apply.)"
                />
            </Box>
            <MultiSelectDropdown
                id="languageSelectDropdown"
                displayValue={selectedLanguages}
                onChange={onChange}
                values={languages.map(language => language.name)}
                className={styles.languageSelectDropdown}
                itemClassName={styles.languageSelected}
                renderValue={(selected = []) =>
                    renderValue(
                        selected,
                        isMobile
                            ? (selectedLanguages || [])
                                  .slice(0, 2)
                                  .some(s => s.length > 12)
                                ? 1
                                : 2
                            : 3
                    )
                }
            />
            <AgentModalContinueButton
                disabled={selectedLanguages.length === 0}
                onClick={() =>
                    document
                        .getElementById('agentPreferenceModal')
                        ?.scrollIntoView({ behavior: 'smooth' })
                }
            />
        </AgentModalContentContainer>
    );
};
