import StateDropdown from 'components/StateDropdown';

import styles from './styles.module.scss';

const StateDropdownContainer = props => {
    return (
        <div className={`${styles.container} ${props.className}`}>
            <StateDropdown {...props} />
        </div>
    );
};

export default StateDropdownContainer;
