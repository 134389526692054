import { Box, Paper, Typography } from '@mui/material';
import styles from './styles.module.scss';
import Heading3 from 'components/Heading3';
import { AgentModalContinueButton } from '../AgentModalContinueButton';
import { AgentModalCollapseRibbon } from '../AgentModalCollapseRibbon';
import { AgentModalContentContainer } from '../AgentModalContentContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAgentPreference } from '../AgentTypeModal/slice';
import { useState } from 'react';

export const IN_PERSON = 'In person';
export const OVER_THE_PHONE = 'Over the phone';
export const EITHER_IS_FINE = 'Either is fine';

export const AgentPreference = ({ navigateToFeFindAgent }) => {
    const selectedAgentPreference = useSelector(
        state => state.agentTypeDetail.selectedAgentPreference
    );
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const dispatch = useDispatch();

    const onNext = () => {
        if (selectedAgentPreference === OVER_THE_PHONE) {
            setButtonDisabled(true);
            navigateToFeFindAgent();
        } else
            document
                .getElementById('agentModalAddress')
                ?.scrollIntoView({ behavior: 'smooth' });
    };
    const PreferenceButton = ({ text, className }) => {
        return (
            <Paper
                elevation={1}
                className={`${styles.preferenceButton} ${className} ${
                    selectedAgentPreference === text
                        ? styles.selectedAgentPreference
                        : ''
                }`}
                onClick={() => dispatch(setSelectedAgentPreference(text))}
            >
                <Typography
                    variant="body1"
                    className={styles.preferenceButtonText}
                >
                    {text}
                </Typography>
            </Paper>
        );
    };
    return (
        <AgentModalContentContainer
            // className={styles.languageSelectionContainer}
            id="agentPreferenceModal"
        >
            <AgentModalCollapseRibbon
                onClick={() =>
                    document
                        .getElementById('languageSelect')
                        ?.scrollIntoView({ behavior: 'smooth' })
                }
            />
            <Heading3
                className={styles.languageSelectionHeading1}
                text="How would you prefer to work with your agent?"
            />
            <Box className={styles.buttonsRow1}>
                <PreferenceButton
                    text={IN_PERSON}
                    className={styles.inPersonButton}
                />
                <PreferenceButton
                    text={OVER_THE_PHONE}
                    className={styles.inPersonButton}
                />
            </Box>
            <Box className={styles.buttonsRow2}>
                <PreferenceButton text={EITHER_IS_FINE} />
            </Box>
            <AgentModalContinueButton
                disabled={!selectedAgentPreference || buttonDisabled}
                onClick={onNext}
            />
        </AgentModalContentContainer>
    );
};
