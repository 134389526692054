import React, { useContext, createContext, useMemo, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { ClientsService } from './clientServices';

const ClientServiceContext = createContext({
    clientService: null
});

export function useClientServiceContext() {
    return useContext(ClientServiceContext);
}

export function ClientServiceContextProvider({ children }) {
    const { getAccessTokenSilently, user } = useAuth0();

    const clientService = useMemo(
        () => new ClientsService(getAccessTokenSilently, user),
        [getAccessTokenSilently, user]
    );

    useEffect(() => {
        clientService.setAccessToken(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    return (
        <ClientServiceContext.Provider
            value={{
                clientService
            }}
        >
            {children}
        </ClientServiceContext.Provider>
    );
}
