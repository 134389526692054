import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
    setAgentPurlCode,
    setIsAgentAssociationCompleted
} from 'components/PreferredAgent/slice';
import useQueryParams from 'components/hooks/useQueryParams';

import {
    PURL_AGENT_CODE,
    PURL_AGENT_DATA,
    PURL_AGENT_DATA_OLD,
    PURL_AGENT_FINALEXPENSE_CODE,
    PURL_AGENT_FINALEXPENSE_DATA,
    PURL_AGENT_MEDICARE_CODE,
    PURL_AGENT_MEDICARE_DATA
} from 'utilities/sessionStorageKeys';

import { getPurlAgentData } from 'temp/utilities/apiSession';
import { get, remove, set } from 'temp/utilities/storage';

export const PURL_URL_PARAM = 'purl';

export const usePurl = () => {
    const [purlAgentCode, setPurlAgentCode] = useState(null);
    const [purlAgentData, setPurlAgentData] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const queryParams = useQueryParams();
    const purlParam = queryParams.get(PURL_URL_PARAM);
    const agentCode = get(PURL_AGENT_CODE);
    const dispatch = useDispatch();

    const setPurlAgentContext = (code, data) => {
        setPurlAgentCode(code);
        setPurlAgentData(data);
    };

    const setAgentPurlInUrl = pURLCode => {
        var uri = new URL(window.location.href);
        if (!uri.searchParams.get('purl')) {
            uri.searchParams.append('purl', pURLCode);
            if (uri.hash) {
                window.history.replaceState(
                    null,
                    null,
                    `?${uri.searchParams}${uri.hash}`
                );
            } else {
                window.history.replaceState(null, null, `?${uri.searchParams}`);
            }
        }
    };

    useEffect(() => {
        const pURLCode = get(PURL_AGENT_CODE);
        if (pURLCode?.length > 0) {
            setAgentPurlInUrl(pURLCode);
        }
    }, [history, location, agentCode]);

    const fetchPurlAgentDetails = useCallback(async newAgentCode => {
        const response = await getPurlAgentData();
        if (response) {
            set(PURL_AGENT_DATA, response);
            setPurlAgentData(response);
            setPurlAgentCode(newAgentCode);
        } else {
            remove(PURL_AGENT_DATA);
        }
        return response;
    }, []);

    const populateAgentData = async newPurlAgentCode => {
        if (newPurlAgentCode) {
            const purlAgentDataOld = get(PURL_AGENT_DATA_OLD);
            if (
                purlAgentDataOld &&
                purlAgentDataOld.agentPurlCode !== get(PURL_AGENT_CODE) &&
                newPurlAgentCode !== get(PURL_AGENT_CODE)
            ) {
                remove(PURL_AGENT_DATA_OLD);
            }
            set(PURL_AGENT_CODE, newPurlAgentCode);
            const response = await fetchPurlAgentDetails(newPurlAgentCode);
            if (response?.isMedicareAgent) {
                set(PURL_AGENT_MEDICARE_CODE, newPurlAgentCode);
                set(PURL_AGENT_MEDICARE_DATA, response);
            }
            if (response?.isFinalExpenseAgent) {
                set(PURL_AGENT_FINALEXPENSE_CODE, newPurlAgentCode);
                set(PURL_AGENT_FINALEXPENSE_DATA, response);
            }
            // if (response?.isFinalExpenseAgent && !response?.isMedicareAgent) {
            //     dispatch(setSelectedAgentType(finalExpensePlanType));
            // }
            // else{
            //     dispatch(setSelectedAgentType(medicarePlanType));
            // }
            dispatch(setAgentPurlCode(newPurlAgentCode));
            dispatch(setIsAgentAssociationCompleted(false));
        }
    };

    useEffect(() => {
        const newPurlAgentCode = purlParam;
        populateAgentData(newPurlAgentCode);
         
    }, [purlParam]);

    return {
        purlAgentCode,
        setPurlAgentCode,
        purlAgentData,
        setPurlAgentData,
        fetchPurlAgentDetails,
        setPurlAgentContext,
        setAgentPurlInUrl
    };
};
