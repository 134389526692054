import React, { useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';

import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoCard from 'components/InfoCard';
import bannerImage from 'components/SafeGuardBanner/images/banner.svg';
import mobileBannerImage from 'components/SafeGuardBanner/images/mobile_banner.svg';
import Text from 'components/Text';

import image from './images/image.svg';

import {
    LegacySafeguardWebsite,
    buttonLabel,
    buttonLabel1,
    heading,
    label
} from './constants';

import styles, { mobileWidth } from './styles.module.scss';

const SafeGuardBanner = () => {
    const [isMobile, setIsMobile] = useState(false);

    const { name } = useSelector(state => state.legacyModalDetails?.legacyData);
    const handleLinkClick = event => {
        event.preventDefault();
        window.open(LegacySafeguardWebsite);
    };

    return (
        <InfoCard
            bodyElement={
                <>
                    <Text className={styles.label} text={label} />
                    <div className={styles.container}>
                        <Media
                            queries={{
                                mobile: { maxWidth: mobileWidth }
                            }}
                            onChange={breakpoint => {
                                setIsMobile(breakpoint.mobile);
                            }}
                        />
                        <Icon
                            image={isMobile ? mobileBannerImage : bannerImage}
                        />
                        <Button
                            className={styles.getStartedBtn}
                            id="LegacySafeGuardBtn"
                            label={name ? buttonLabel1 : buttonLabel}
                            onClick={handleLinkClick}
                        />
                    </div>
                </>
            }
            containerClassName={styles.safeGuardBanner}
            heading={heading}
            icon={image}
            titleContainerClassName={styles.safeGuardTitle}
            infoCardDetailsClassName={styles.activityStreamCardDetails}
        />
    );
};

export default SafeGuardBanner;
