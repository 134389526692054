import Media from 'react-media';

import NextIcon from 'components/icons/NextIcon';
import PreviousIcon from 'components/icons/PreviousIcon';

import './pagination.scss';

import { mobileWidth } from 'temp/pages/Plans/breakpoints.module.scss';

const PaginationButton = ({
    state = 'active',
    navElement,
    children,
    ...props
}) => (
    <li
        className={`pagination__button pagination__button--${state} ${
            navElement ? 'nav' : ''
        }`}
        {...props}
    >
        <button
            type="button"
            aria-disabled={state === 'inactive' ? true : undefined}
            aria-current={state === 'current' ? 'page' : undefined}
        >
            {children}
        </button>
    </li>
);

const noop = () => null;
const defaultVisiblePages = 5;

const getVisibleRange = (total, current, maxVisible = defaultVisiblePages) => {
    const numVisible = Math.min(total, maxVisible);
    return new Array(numVisible).fill(null).map((_, idx) => {
        if (numVisible < maxVisible || current <= Math.ceil(maxVisible / 2)) {
            return idx + 1;
        }
        if (current > total - Math.floor(maxVisible / 2)) {
            return idx + (total - maxVisible + 1);
        }
        return idx + current - Math.floor(maxVisible / 2);
    });
};

const Pagination = ({
    totalPages = 5,
    totalResults,
    currentPlansPage = '1',
    currentPage = currentPlansPage,
    pageSize = 12,
    resultName = 'contacts',
    resultTrailer = null,
    onPageChange = noop,
    providerPagination = false,
    visibleRange = defaultVisiblePages,
    ...props
}) => {
    const handlePageChange = page => {
        if (page < 1 || page > totalPages || page === currentPage) return noop;
        return () => onPageChange(page);
    };

    const showingFrom = (currentPage - 1) * pageSize + 1;
    const showingTo =
        currentPage * pageSize > totalResults
            ? totalResults
            : currentPage * pageSize;

    return (
        <Media
            queries={{
                mobile: { maxWidth: mobileWidth }
            }}
        >
            {matches => (
                <div className="pagination-container">
                    {!providerPagination && (
                        <div className="pagination-display-results">
                            {`Showing ${showingFrom} - ${showingTo} of ${totalResults} ${resultName}${
                                resultTrailer ? ' for ' : ''
                            }`}
                            {resultTrailer}
                        </div>
                    )}
                    {totalPages > 1 ? (
                        <nav
                            aria-label="pagination"
                            className={`pagination ${
                                matches.mobile ? 'pagination--mobile' : ''
                            }`}
                            {...props}
                        >
                            <ul
                                className={`pagination__pages ${
                                    providerPagination
                                        ? 'provider-pagination-ul'
                                        : ''
                                }`}
                            >
                                <div className="pagination__middle">
                                    <PaginationButton
                                        state={
                                            currentPage === 1
                                                ? 'inactive'
                                                : 'active'
                                        }
                                        onClick={handlePageChange(
                                            currentPage - 1
                                        )}
                                        id="paginationPrevBtn"
                                        navElement={true}
                                    >
                                        <span className="mr-1">
                                            <PreviousIcon />
                                        </span>
                                        <span className="visuallyhidden">
                                            {!providerPagination && <>Prev</>}
                                            page
                                        </span>
                                    </PaginationButton>
                                    {getVisibleRange(
                                        totalPages,
                                        currentPage,
                                        visibleRange
                                    ).map(page => (
                                        <PaginationButton
                                            key={page}
                                            state={
                                                page === currentPage
                                                    ? 'current'
                                                    : 'active'
                                            }
                                            onClick={handlePageChange(page)}
                                        >
                                            <span className="visuallyhidden">
                                                page
                                            </span>
                                            {page}
                                        </PaginationButton>
                                    ))}

                                    <PaginationButton
                                        state={
                                            currentPage === totalPages
                                                ? 'inactive'
                                                : 'active'
                                        }
                                        id="paginationNexuBtn"
                                        onClick={handlePageChange(
                                            currentPage + 1
                                        )}
                                        navElement={true}
                                    >
                                        <span className="mr-1">
                                            <NextIcon />
                                        </span>

                                        <span className="visuallyhidden">
                                            {!providerPagination && (
                                                <span className="mr-1">
                                                    Next
                                                </span>
                                            )}
                                            page
                                        </span>
                                    </PaginationButton>
                                </div>
                            </ul>
                        </nav>
                    ) : null}
                </div>
            )}
        </Media>
    );
};

export default Pagination;
