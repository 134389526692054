import AddIcon from '@mui/icons-material/Add';
import SubtractIcon from '@mui/icons-material/Remove';
import { InputAdornment } from '@mui/material';

import Button from 'components/Button';
import Text from 'components/Text';
import TextField from 'components/TextField';

import styles from './styles.module.scss';
import './styles.scss';

export const MIN_AMOUNT_COVERAGE = 5000;
export const MAX_AMOUNT_COVERAGE = 250000;
export const MIN_AMOUNT_IUL_COVERAGE = 25000;
export const MAX_AMOUNT_IUL_COVERAGE = 300000;
export const MIN_AMOUNT_PREMIUM = 10;
export const MAX_AMOUNT_PREMIUM = 250;
export const MIN_AMOUNT_IUL_PREMIUM = 10;
export const MAX_AMOUNT_IUL_PREMIUM = 300;

const PLAN_TYPE_FILTER_IUL = 6;
const CoverageCostFilterInput = ({
    register,
    setValue,
    error,
    value,
    type = 'coverageAmount',
    planType = PLAN_TYPE_FILTER_IUL
}) => {
    const setNewValue = value => {
        setValue(type, value.toString());
    };

    const AMOUNT_INCREMENT = type === 'coverageAmount' ? 5000 : 10;
    const MIN_AMOUNT =
        type === 'coverageAmount'
            ? planType === PLAN_TYPE_FILTER_IUL
                ? MIN_AMOUNT_IUL_COVERAGE
                : MIN_AMOUNT_COVERAGE
            : planType === PLAN_TYPE_FILTER_IUL
              ? MIN_AMOUNT_IUL_PREMIUM
              : MIN_AMOUNT_PREMIUM;
    const MAX_AMOUNT =
        type === 'coverageAmount'
            ? planType === PLAN_TYPE_FILTER_IUL
                ? MAX_AMOUNT_IUL_COVERAGE
                : MAX_AMOUNT_COVERAGE
            : planType === PLAN_TYPE_FILTER_IUL
              ? MAX_AMOUNT_IUL_PREMIUM
              : MAX_AMOUNT_PREMIUM;

    const helperText =
        type === 'coverageAmount'
            ? planType === PLAN_TYPE_FILTER_IUL
                ? 'Enter value between $25,000 - $300,000'
                : 'Enter value between $5,000 - $250,000'
            : planType === PLAN_TYPE_FILTER_IUL
              ? 'Enter value between $10 - $300'
              : 'Enter value between $10 - $250';

    return (
        <>
            <div
                className={`${styles.coverageCostFilterInput} coverage-cost-filter-input`}
            >
                <Button
                    label={<SubtractIcon />}
                    disabled={value < AMOUNT_INCREMENT + MIN_AMOUNT}
                    className={styles.changeAmountButton}
                    onClick={() => {
                        setNewValue(value - AMOUNT_INCREMENT);
                    }}
                />
                <div className={styles.textFieldInput}>
                    <TextField
                        register={register}
                        id={type}
                        type="string"
                        className={`${styles.textField} ${
                            error && styles.errorInputField
                        }`}
                        InputPropsForIcon={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                            endAdornment:
                                type === 'coverageAmount' ? undefined : (
                                    <InputAdornment position="end">
                                        <span>/mo</span>
                                    </InputAdornment>
                                )
                        }}
                    />
                </div>
                <Button
                    label={<AddIcon />}
                    className={styles.changeAmountButton}
                    disabled={value > MAX_AMOUNT - AMOUNT_INCREMENT}
                    onClick={() => {
                        setNewValue(value + AMOUNT_INCREMENT);
                    }}
                />
            </div>
            <Text className={styles.enterValueHelper} text={helperText} />
            {error && (
                <Text className={styles.errorText} text={error.message} />
            )}
        </>
    );
};

export default CoverageCostFilterInput;
