import Icon from 'components/Icon';
import Text from 'components/Text';

import { formatMCID } from 'utilities/maskMedicareId';

import SafeGuardIcon from './images/safeguard.svg';

import styles from './styles.module.scss';

const LegacySafeGuardCard = ({ name, id, cardClassName }) => (
    <div className={cardClassName} id="walletLegacyCardContent">
        <Icon image={SafeGuardIcon} id="walletLegacyCardContentIcon" />
        <Text
            text={name}
            className={styles.name}
            id="walletLegacyCardContentText"
        />
        <Text
            text={formatMCID(id) || '000-000-000-XXX'}
            className={styles.medicareID}
            id="walletLegacyCardContentMedicareId"
        />
    </div>
);

export default LegacySafeGuardCard;
