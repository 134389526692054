import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material';
import Tab from '@mui/material/Tab';
// import { Tab, Tabs } from '@mui/material';
import Tabs from '@mui/material/Tabs';

import Icon from 'components/Icon';
import InfoCard from 'components/InfoCard';
import InsuranceAgentDetailsModal from 'components/InsuranceDetailsInfoCards/InsuranceAgentDetailsModal';
import { medicarePlanType } from 'components/PlanTypeModal/constants';

import AssignedAgent from 'pages/AssignedAgent';

import { getPlans } from 'utilities/apiSession/plans';

import healthIcon from './images/Health.svg';
import lifeIcon from './images/Life.svg';
import agentIcon from './images/assignedAgent.svg';

import styles from './styles.module.scss';

const HealthLifeProfile = () => {
    const { purlAgents = [] } = useSelector(state => state.mainDetails);
    const [, setHealthPlans] = useState([]);
    const [, setLifePlans] = useState([]);
    const [costTab, setCostTab] = useState(0);

    const [isLifeAgentModalOpen] = useState(false);
    const [selectedAgentDetails] = useState();
    const [isAgentDetailsModalOpen, setIsAgentDetailsModalOpen] =
        useState(false);

    useEffect(() => {
        if (purlAgents.length) {
            const isMedicare = purlAgents.filter(
                purlAgent => purlAgent.productType === medicarePlanType
            ).length;
            setCostTab(isMedicare ? 0 : 1);
        }
    }, [purlAgents]);

    const getPlansList = useCallback(async () => {
        try {
            const plans = await getPlans();
            const filterPlans = plans?.filter(
                data => data?.policyStatus === 'Active'
            );
            const lifeFilterPlans = filterPlans?.filter(
                data => data?.productCategory === 'Final Expense'
            );
            const healthFilterPlans = filterPlans?.filter(
                data => data?.productCategory !== 'Final Expense'
            );
            setLifePlans(lifeFilterPlans);
            setHealthPlans(healthFilterPlans);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        getPlansList();
    }, [getPlansList]);

    const AntTab = styled(props => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            '&.Mui-selected': {
                color: '#052a63',
                fontWeight: 700
            }
        })
    );

    return (
        <>
            <InfoCard
                bodyElement={
                    <div className={styles.container}>
                        <div>
                            <Tabs
                                value={costTab}
                                onChange={(e, value) => setCostTab(value)}
                                aria-label="Cost Tabs"
                                className={styles.costTabs}
                                variant="fullWidth"
                            >
                                <AntTab
                                    id="healthAgent"
                                    icon={
                                        <Icon
                                            className={styles.iconSize}
                                            image={healthIcon}
                                        />
                                    }
                                    iconPosition="start"
                                    label="Health Agent"
                                />
                                <AntTab
                                    id="lifeAgent"
                                    icon={
                                        <Icon
                                            className={styles.iconSize}
                                            image={lifeIcon}
                                        />
                                    }
                                    iconPosition="start"
                                    label="Life Agent"
                                />
                            </Tabs>
                        </div>
                        <div className={styles.agentDetails}>
                            <AssignedAgent tab={costTab} />
                        </div>
                    </div>
                }
                heading={'Assigned Agent'}
                containerClassName={styles.personalDetails}
                icon={agentIcon}
                iconClassName={styles.headerIcon}
                infoCardDetailsClassName={styles.cardBody}
                titleContainerClassName={styles.titleContainerClassName}
            />
            <InsuranceAgentDetailsModal
                isLifeAgent={isLifeAgentModalOpen}
                isOpen={isAgentDetailsModalOpen}
                agentDetails={selectedAgentDetails}
                onClose={() => setIsAgentDetailsModalOpen(false)}
            />
        </>
    );
};
export default HealthLifeProfile;
