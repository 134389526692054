import React, { useContext, useMemo } from 'react';
import styles from './styles.module.scss';
import ZipCodeContext from 'contexts/zipCode';
import PlansByZipForm from 'components/PlansByZipForm';
import Button from 'components/Button';
import { COUNTY_FIPS_KEY } from '../../utilities/storageKeys';
import { get } from 'temp/utilities/storage';
import Heading3 from '../Heading3';
import { Box, IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from 'react-redux';
import { medicarePlanType } from './constants';

const AgentZipCode = () => {
    const { onSetCounty, onSetZipCode, buttonDisabled, county } =
        useContext(ZipCodeContext);
    const selectedAgentType = useSelector(
        state => state.agentTypeDetail.selectedAgentType
    );

    const isMedicare = useMemo(
        () => selectedAgentType === medicarePlanType,
        [selectedAgentType]
    );

    const onClick = () => {
        onSetZipCode(localStorage.getItem('updatedZipCode'));
        onSetCounty(
            localStorage.getItem('updatedCountyVal') || get(COUNTY_FIPS_KEY)
        );
        localStorage.removeItem('updatedCountyVal');
        document.getElementById('languageSelect')?.scrollIntoView({
            behavior: 'smooth'
        });
    };

    const navigateToFeFindAgent = () =>
        document
            .getElementById('findAnAgentContent')
            ?.scrollIntoView({ behavior: 'smooth' });

    const navigatetoFePlanType = () =>
        document
            .getElementById('fePlanType')
            ?.scrollIntoView({ behavior: 'smooth' });

    return (
        <div className={styles.agentZipBlock} id="agentZipBlock">
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
                width="100%"
                onClick={
                    isMedicare ? navigateToFeFindAgent : navigatetoFePlanType
                }
            >
                <IconButton>
                    <ExpandLessIcon
                        className={styles.collapseIcon}
                        fontSize="large"
                    />
                </IconButton>
            </Box>
            <Box width={300}>
                <Heading3
                    className={styles.subProductTypeHeading}
                    text="Let's find your location"
                />
                <PlansByZipForm
                    isOnChangeEnable={true}
                    shouldAlwaysShowCounty={true}
                    type="modalWindow"
                />
            </Box>
            <Button
                className={styles.button}
                disabled={buttonDisabled || !county}
                label="Continue"
                onClick={onClick}
                id={'Continue'}
            />
        </div>
    );
};

export default AgentZipCode;
