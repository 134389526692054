import { useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import HamburgerMenu from 'components/HamburgerMenu';
import HamburgerIcon from 'components/icons/HamburgerIcon';

import hamburgerContext from 'contexts/hamburger';
import purlAgentContext from 'contexts/purlAgentContext';

import data from './data';

import styles from './styles.module.scss';

const Hamburger = () => {
    const { isOpen, setIsOpen } = useContext(hamburgerContext);
    const { isAuthenticated } = useAuth0();
    const { purlAgentData } = useContext(purlAgentContext);

    const items =
        purlAgentData?.isMedicareAgent || purlAgentData?.isFinalExpenseAgent
            ? data.filter(d => d.title !== 'Find an Agent')
            : data;

    const filteredData = items.filter(
        section => section.title !== 'Profile' || isAuthenticated
    );

    const quickProfileData = data.filter(
        section => !['Profile', 'Find an Agent'].includes(section.title)
    );

    const handleClick = () => setIsOpen(!isOpen);

    return (
        <div className={styles.hamburger}>
            <HamburgerIcon
                className={styles.hamburgerIcon}
                isOpen={isOpen}
                onClick={handleClick}
            />

            <HamburgerMenu
                className={styles.hamburgerMenu}
                isOpen={isOpen}
                items={
                    window.location.href.includes('quick-profile')
                        ? quickProfileData
                        : filteredData
                }
                onItemClick={handleClick}
            />
        </div>
    );
};

export default Hamburger;
