import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import CoverageCostFilterInput from 'components/CoverageCostFilterInput';
import InverseButton from 'components/InverseButton';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import PlanCostLabel from 'components/PlanCostLabel';
import RadioMultipleOptions from 'components/RadioMultipleOptions';

import formatCurrency from 'utilities/formatCurrency';

import './styles.scss';

import CheckboxGroup from 'temp/components/CheckboxGroup';
import { SubSection } from 'temp/components/FilterSection';
import { set } from 'temp/utilities/storage';
import {
    COVERAGE_AMOUNT,
    IUL_COVERAGE_AMOUNT,
    IUL_MONTHLY_PREMIUM,
    MONTHLY_PREMIUM
} from 'utilities/storageKeys';

export const INFO_COVERAGE_TYPES_MODAL_DESCRIPTION = `
Final Expense life insurance has four main coverage types. Learn more about each one to see if it's the right fit for your needs.
<br/><br/>
<b>Level:</b> The closest plan to a traditional Whole Life policy, with higher available policy values and lower premiums if qualified. Requires health qualification for underwriting.
<br/><br/>
<b>Graded:</b> These policies allow minor health conditions in their underwriting, and have a waiting period before the entire death benefit will be paid, often between 2 and 4 years. A smaller payout may be available before the waiting period is over.
<br/><br/>
<b>Guaranteed Issue:</b> Ideal for clients with multiple health issues: no health-related questions are required. May only pay out premiums + interest for first few years of policy. Has higher premiums due to guaranteed benefits.
<br/><br/>
<b>Limited Pay (Modified):</b> Similar to Graded, with more allowance for severe health conditions in the policyholder. Premiums are higher than Graded plans.
`;
const PLAN_TYPE_FILTER_IUL = 6;
const FinalExpenseFilterContainer = ({
    coverageSelectedValue,
    setCoverageSelectedValue,
    COVERAGE_TYPE_OPTIONS,
    coverageAmount,
    costTab,
    setCostTab,
    setCoverageAmount,
    monthlyPremiumAmount,
    setMonthlyPremiumAmount,
    carrierList,
    sortSelected,
    setSortSelected,
    FINAL_EXPENSES_SORT_OPTIONS,
    planType = PLAN_TYPE_FILTER_IUL
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSort, setIsSort] = useState(false);
    const [isCoverageSelectOpen, setIsCoverageSelectOpen] = useState(false);

    const {
        register,
        setValue,
        getValues,
        watch,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({
        defaultValues: {
            coverageAmount: coverageAmount,
            monthlyPremium: monthlyPremiumAmount
        }
    });

    const coverageAmountWatch = watch('coverageAmount');
    const monthlyPremiumWatch = watch('monthlyPremium');

    useEffect(() => {
        setValue('coverageAmount', coverageAmount);
    }, [coverageAmount]);

    useEffect(() => {
        setValue('monthlyPremium', monthlyPremiumAmount);
    }, [monthlyPremiumAmount]);

    useEffect(() => {
        if (!coverageAmountWatch) {
            setValue('coverageAmount', '0');
            return;
        }
        const amountInteger = parseInt(coverageAmountWatch.replaceAll(',', ''));
        const formattedValue = formatCurrency(amountInteger)
            .replace('$', '')
            .replace('.00', '');
        if (formattedValue !== coverageAmountWatch) {
            setValue('coverageAmount', formattedValue);
            set(
                planType === PLAN_TYPE_FILTER_IUL
                    ? IUL_COVERAGE_AMOUNT
                    : COVERAGE_AMOUNT,
                coverageAmountWatch
            );
        }
        setCoverageAmount(coverageAmountWatch.replaceAll(',', ''));
    }, [
        setCoverageAmount,
        coverageAmountWatch,
        setValue,
        setError,
        clearErrors
    ]);

    useEffect(() => {
        const amountInteger = parseInt(coverageAmountWatch.replaceAll(',', ''));
        if (
            planType === PLAN_TYPE_FILTER_IUL
                ? amountInteger < 25000 || amountInteger > 300000
                : amountInteger < 5000 || amountInteger > 250000
        ) {
            setError('coverageAmount', {
                type: 'manual',
                message: `Coverage amount must be between ${planType === PLAN_TYPE_FILTER_IUL ? '$25,000 and $300,000.' : '$5,000 and $250,000.'}`
            });
        } else {
            clearErrors('coverageAmount');
        }
    }, [
        setCoverageAmount,
        coverageAmountWatch,
        setValue,
        setError,
        clearErrors,
        planType
    ]);

    useEffect(() => {
        if (!monthlyPremiumWatch) {
            setValue('monthlyPremium', '0');
            return;
        }
        const amountInteger = parseInt(monthlyPremiumWatch.replaceAll(',', ''));
        const formattedValue = formatCurrency(amountInteger)
            .replace('$', '')
            .replace('.00', '');
        if (formattedValue !== monthlyPremiumWatch) {
            setValue('monthlyPremium', formattedValue);
        }
        set(
            planType === PLAN_TYPE_FILTER_IUL
                ? IUL_MONTHLY_PREMIUM
                : MONTHLY_PREMIUM,
            monthlyPremiumWatch
        );
        setMonthlyPremiumAmount(monthlyPremiumWatch.replaceAll(',', ''));
        if (
            planType === PLAN_TYPE_FILTER_IUL
                ? amountInteger < 10 || amountInteger > 300
                : amountInteger < 10 || amountInteger > 250
        ) {
            setError('monthlyPremium', {
                type: 'manual',
                message: `Monthly premium must be between ${
                    planType === PLAN_TYPE_FILTER_IUL
                        ? '$10 and $300.'
                        : '$10 and $250.'
                }`
            });
        } else {
            clearErrors('monthlyPremium');
        }
    }, [
        clearErrors,
        monthlyPremiumWatch,
        setError,
        setMonthlyPremiumAmount,
        setValue
    ]);

    const filterSelect = useMemo(() => {
        return (
            <Select
                onClose={() => setIsCoverageSelectOpen(false)}
                open={isCoverageSelectOpen}
                onOpen={() => setIsCoverageSelectOpen(true)}
                IconComponent={() => (
                    <KeyboardArrowDownIcon
                        className={`selectIconStyle ${
                            isCoverageSelectOpen ? 'selectIconStyleActive' : ''
                        }`}
                    />
                )}
                className="select"
                onChange={e => {
                    setCoverageSelectedValue(e.target.value);
                }}
                id="filterCoverageType"
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                            color: '#5b6886'
                        }
                    }
                }}
                value={coverageSelectedValue}
            >
                {COVERAGE_TYPE_OPTIONS.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        );
    }, [
        COVERAGE_TYPE_OPTIONS,
        coverageSelectedValue,
        isCoverageSelectOpen,
        setCoverageSelectedValue
    ]);

    const sortByMobileContent = useMemo(
        () => (
            <div className="sortByMobileContent">
                <RadioMultipleOptions
                    items={FINAL_EXPENSES_SORT_OPTIONS.map(item => item.label)}
                    selectedOption={
                        FINAL_EXPENSES_SORT_OPTIONS.find(
                            item => item.value === sortSelected
                        )?.label
                    }
                    onChange={e => {
                        setSortSelected(
                            FINAL_EXPENSES_SORT_OPTIONS.find(
                                item => item.label === e.target.value
                            )?.value
                        );
                        setIsModalOpen(false);
                    }}
                />
            </div>
        ),
        [FINAL_EXPENSES_SORT_OPTIONS, setSortSelected, sortSelected]
    );

    const filtersMobileContent = useMemo(
        () => (
            <div className="filtersMobileContent">
                {planType !== PLAN_TYPE_FILTER_IUL && (
                    <SubSection
                        className="coverageTypeSubSection"
                        title="Coverage Type"
                        infoModalTitle={'Coverage Type'}
                        infoModalDescription={
                            INFO_COVERAGE_TYPES_MODAL_DESCRIPTION
                        }
                        modalBoxClassName="finalPlanFilterModal"
                        headerClassName="finalPlanFilterModalHeader"
                    >
                        <RadioMultipleOptions
                            items={COVERAGE_TYPE_OPTIONS.map(
                                item => item.label
                            )}
                            onChange={e => {
                                setCoverageSelectedValue(
                                    COVERAGE_TYPE_OPTIONS.find(
                                        item =>
                                            item.label ===
                                            e.target._wrapperState.initialValue
                                    )?.value
                                );
                            }}
                            selectedOption={
                                COVERAGE_TYPE_OPTIONS.find(
                                    item => item.value === coverageSelectedValue
                                )?.label
                            }
                        />
                    </SubSection>
                )}

                <SubSection title="Carriers">
                    <CheckboxGroup
                        className="carriersCheckboxGroup"
                        checkboxes={carrierList}
                    />
                </SubSection>
            </div>
        ),
        [COVERAGE_TYPE_OPTIONS, carrierList]
    );

    return (
        <div className="filter-container">
            <div className="costFilter">
                <div className="header">
                    <PlanCostLabel label="Cost" />
                </div>
                <Tabs
                    value={costTab}
                    onChange={(e, value) => setCostTab(value)}
                    aria-label="Cost Tabs"
                    className="costTabs"
                >
                    <Tab id="coverageAmount" label="Coverage Amount" />
                    <Tab id="monthlyPremium" label="Monthly Premium" />
                </Tabs>
                <div className="costFilterContent">
                    {costTab === 0 && (
                        <CoverageCostFilterInput
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            error={errors.coverageAmount}
                            value={
                                parseInt(
                                    coverageAmountWatch
                                        ?.toString()
                                        .replaceAll(',', '')
                                ) || 0
                            }
                            type="coverageAmount"
                            planType={planType}
                        />
                    )}
                    {costTab === 1 && (
                        <CoverageCostFilterInput
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            error={errors.monthlyPremium}
                            value={
                                parseInt(
                                    monthlyPremiumWatch
                                        ?.toString()
                                        .replaceAll(',', '')
                                ) || 0
                            }
                            type="monthlyPremium"
                            planType={planType}
                        />
                    )}
                </div>
            </div>
            <div className="planOptionsFilter">
                <div className="header">
                    <PlanCostLabel label="Options" />
                </div>
                {planType !== PLAN_TYPE_FILTER_IUL && (
                    <SubSection
                        title="Coverage Type"
                        infoModalTitle={'Coverage Types'}
                        infoModalDescription={
                            INFO_COVERAGE_TYPES_MODAL_DESCRIPTION
                        }
                        modalBoxClassName="finalPlanFilterModal"
                        headerClassName="finalPlanFilterModalHeader"
                    >
                        {filterSelect}
                    </SubSection>
                )}

                <SubSection title="Carriers">
                    <CheckboxGroup
                        className="carriersCheckboxGroup"
                        checkboxes={carrierList}
                    />
                </SubSection>
            </div>

            <div className="mobile-filters-container">
                <InverseButton
                    label="Sort By"
                    onClick={() => {
                        setIsSort(true);
                        setIsModalOpen(true);
                    }}
                />
                <InverseButton
                    label="Filters"
                    onClick={() => {
                        setIsSort(false);
                        setIsModalOpen(true);
                    }}
                />
                <Modal
                    className="final-expense-filter-modal"
                    isOpen={isModalOpen}
                >
                    <ModalWindow
                        contentClassName="contentClassName"
                        headerClassName="expenseModalHeader"
                        footerClassName="expenseModalFooter"
                        footerButtonClassName="footerButtonClassName"
                        headerTitle={isSort ? 'Sort By' : 'Filters'}
                        onClose={() => setIsModalOpen(false)}
                    >
                        {isSort ? sortByMobileContent : filtersMobileContent}
                    </ModalWindow>
                </Modal>
            </div>
        </div>
    );
};

export default FinalExpenseFilterContainer;
