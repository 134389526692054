import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import ActivityStreamInfoCard from 'components/ActivityStreamInfoCard';
import FindCoverage from 'components/FindCoverage';
import SafeGuardBanner from 'components/SafeGuardBanner';
import UserProfileTemplate from 'components/UserProfileTemplate';

import zipcodeContext from 'contexts/zipCode';

import HealthLifeProfile from 'pages/HealthLifeProfile';
import PersonalDetails from 'pages/PersonalDetails';
import ProfileWalletWithTodos from 'pages/ProfileWalletWithTodos';

import styles from './styles.module.scss';

import EditZipModal from 'temp/components/EditZipModal';

const Profile = () => {
    const [editZipModalOpen, setEditZipModalOpen] = useState(false);
    const [insuranceIcon, setInsuranceIcon] = useState();

    const { setZipCode, onSetCounty, onSetCounties } =
        useContext(zipcodeContext);

    const onclick = () => {};
    const handleEditZipModalOpen = () => {
        setEditZipModalOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>Profile | PlanEnroll</title>
            </Helmet>

            <UserProfileTemplate title="Profile" showLastUpdated={false}>
                <ProfileWalletWithTodos
                    insuranceIcon={insuranceIcon}
                    setInsuranceIcon={setInsuranceIcon}
                />

                <FindCoverage />
                <PersonalDetails />
                <HealthLifeProfile />

                <SafeGuardBanner />

                <ActivityStreamInfoCard
                    activityStreamCardContainer={`${styles.informationCards} ${styles.informationCardsRow2}`}
                    onclick={onclick}
                    openZipModal={handleEditZipModalOpen}
                />

                <EditZipModal
                    footerButtonLabel="Save"
                    footerButtonClicked={() => {
                        setEditZipModalOpen(false);
                        setZipCode(localStorage.getItem('updatedZipCode'));
                        onSetCounty(
                            localStorage.getItem('updatedCountyVal') ||
                                localStorage.getItem('countyVal')
                        );
                    }}
                    onClose={() => {
                        setEditZipModalOpen(false);
                        onSetCounty(localStorage.getItem('countyVal'));
                        localStorage.removeItem('countyVal');
                        onSetCounties([]);
                    }}
                    isOpen={editZipModalOpen}
                    isOnChangeEnable={false}
                />
            </UserProfileTemplate>
        </>
    );
};
export default Profile;
