import ButtonLink from 'components/ButtonLink';

const Phone = ({ className = '', number, text = number, datagtm = '', id }) => {
    const href = `tel:+${number}`;

    return (
        <ButtonLink
            className={className}
            href={href}
            text={text}
            datagtm={datagtm}
            id={id}
        />
    );
};

export default Phone;
