import { useState } from 'react';
import { useSelector } from 'react-redux';

import Body2 from 'components/Body2';
import Icon from 'components/Icon';
import IconWithLabel from 'components/IconWithLabel';
import Spinner from 'components/Spinner';
import TextAsLink from 'components/TextAsLink';

import fullAddressTwoLine from 'utilities/fullAddressTwoLine';

import OutOfNetworkIcon from './images/OutofNetwork_Icon.svg';
import deleteIcon from './images/delete.svg';
import greenCheckImage from './images/greenCheckImage.svg';
import online from './images/online.svg';
import physical from './images/physical.svg';

import styles from './styles.module.scss';

// eslint-disable-next-line no-unused-vars
const onClick =
    ({ deletePharmacy, pharmacyDetails, setLoading }) =>
    async () => {
        setLoading(true);
        await deletePharmacy(pharmacyDetails?.pharmacyRecordID);
        setLoading(false);
    };
const CoveredCheck = (
    <Icon className={styles.checkIcon} image={greenCheckImage} />
);

const NotCoveredCheck = (
    <Icon className={styles.checkIcon} image={OutOfNetworkIcon} />
);

const PharmacyAddedListItem = ({
    deletePharmacy,
    pharmacyDetails,
    setAsPrimary
}) => {
    const [loading, setLoading] = useState(false);
    const [primaryLoading, setPrimaryLoading] = useState(false);
    const selectedPlan = useSelector(
        state => state.pharmacyDetails.selectedPlan
    );

    const {
        address1,
        address2,
        city,
        name,
        state,
        zip: zipCode,
        isPrimary,
        isDigital,
        pharmacyId
    } = pharmacyDetails;

    const { pharmacyCosts } = selectedPlan || {};

    let inNetwork = false;
    if (pharmacyCosts?.length)
        inNetwork = pharmacyCosts?.filter(
            cost => cost.pharmacyID === pharmacyId
        )[0]?.isNetwork;

    const handleClick = async pharmacy => {
        setLoading(true);
        await deletePharmacy(pharmacy);
        setLoading(false);
    };

    const updatePrimary = async pharmacy => {
        setPrimaryLoading(true);
        await setAsPrimary(pharmacy);
        setPrimaryLoading(false);
    };

    return loading ? (
        <Spinner />
    ) : (
        <>
            <div className={`${styles.addressBlock} `}>
                <div className={styles.titleContainer}>
                    <IconWithLabel
                        caption={name}
                        iconClassName={styles.iconClassName}
                        image={isDigital ? online : physical}
                        labelClassName={styles.heading5}
                    />
                    <Icon
                        className={styles.deleteIcon}
                        onClick={() => handleClick(pharmacyDetails)}
                        image={deleteIcon}
                    />
                </div>

                <div className={styles.addressContainer}>
                    <div className={styles.tabletLabel}>
                        {selectedPlan &&
                            (inNetwork ? CoveredCheck : NotCoveredCheck)}
                        <Body2
                            text={fullAddressTwoLine({
                                streetLine1: address1,
                                streetLine2: address2,
                                city,
                                state,
                                zipCode
                            })}
                            className={styles.address}
                        />
                    </div>

                    {primaryLoading ? (
                        <div className={styles.spinnerContainer}>
                            <Spinner />
                        </div>
                    ) : isPrimary ? (
                        <div className={styles.primaryContainer}>
                            <span className={styles.primaryText}>Primary</span>
                        </div>
                    ) : (
                        <TextAsLink
                            className={` ${styles.linkTextClass}`}
                            text={'Set as Primary'}
                            onClick={() => {
                                updatePrimary(pharmacyDetails);
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default PharmacyAddedListItem;
