import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import Body2Italic from 'components/Body2Italic';
import Button from 'components/Button';
import EmailTextFieldWithLabel from 'components/EmailTextFieldWithLabel';
import FeedbackCard from 'components/FeedbackCard';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import PageSpinner from 'components/PageSpinner';
import PasswordTextFieldWithLabel from 'components/PasswordTextFieldWithLabel';
import { setSelectedItem } from 'components/PlanTypeModal/slice';
import TrustedFormScript from 'components/TrustedFormScript';
import useFetch from 'components/hooks/useFetch';

import purlAgentContext from 'contexts/purlAgentContext';

import {
    PrivacyLink,
    TermsAndConditionsLink
} from 'utilities/privacyAndTermsLinks';
import {
    CONTACT_CALLBACK_KEY,
    QUICK_PROFILE_PERSONAL_INFORMATION
} from 'utilities/storageKeys';

import check from './image/Check.svg';

import { headerTitle, headerText } from './constants';
import { setSelectedQuickProfileItem } from './slice';

import styles from './styles.module.scss';

import { getLeadId } from 'temp/utilities/apiSession/leadId';
import { get } from 'temp/utilities/storage';
import { set } from 'temp/utilities/storage';

const QuickProfileHealthModal = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const dispatch = useDispatch();
    const { loginWithRedirect } = useAuth0();
    const selectedQuickProfileItem = useSelector(
        state => state.quickProfileModalDetail.selectedQuickProfileItem
    );
    const profileInfo = get(QUICK_PROFILE_PERSONAL_INFORMATION);
    const targetPage = useSelector(
        state => state.quickProfileModalDetail.targetPage
    );

    const handleLoginClick = async () => {
        try {
            await loginWithRedirect();
        } catch (e) {
            console.error('sign in error: ', e);
        }
    };
    const onClose = () => dispatch(setSelectedQuickProfileItem(null));
    const onClick = url => {
        document.getElementById('submitButton').click();
    };
    const onLeave = () => {
        onClose();
        if (targetPage === 'products')
            dispatch(setSelectedItem(selectedQuickProfileItem));
        else if (targetPage === 'Learning_Center') {
            dispatch(setSelectedItem(null));
            history.push(selectedQuickProfileItem);
        } else if (targetPage === 'Sign_Up') {
            history.push(selectedQuickProfileItem);
        } else if (targetPage === 'Login') {
            handleLoginClick();
        } else if (targetPage === 'Logo') {
            history.push(selectedQuickProfileItem);
            window.scrollTo(0, 0);
        } else if (targetPage === 'Help') {
            set(CONTACT_CALLBACK_KEY, 'true');
            dispatch(setSelectedItem(selectedQuickProfileItem));
        } else if (targetPage === 'ViewMore') {
            history.push(selectedQuickProfileItem);
        } else if (targetPage === 'Sign-Up' || targetPage === 'Home') {
            history.push(selectedQuickProfileItem);
        }
        setLoading(false);
    };
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        setError,
        setValue,
        watch
    } = useForm({
        defaultValues: {}
    });

    const watchPassword = watch('password');
    const { purlAgentData } = useContext(purlAgentContext);
    const { Post: registerUser } = useFetch(
        `${import.meta.env.VITE_APP_ACCOUNT_SERVICE_URL}/Register`,
        true
    );
    const updateProfile = async data => {
        setLoading(true);

        if (!watchPassword) {
            setLoading(false);
            setError('password', { type: 'pattern' });
        }

        if (watchPassword) {
            setIsSubmitDisabled(true);
            reset({});

            profileInfo.zip = profileInfo.zipVal;
            profileInfo.password = watchPassword;

            if (purlAgentData) {
                const virtualNumber = purlAgentData?.virtualNumber;
                const phoneNumber = virtualNumber?.substring(2);
                data.Purl = purlAgentData.agentPurlCode;
                data.agentNPN = purlAgentData.agentNpn;
                data.AgentPhoneNumber = phoneNumber;
                data.isMedicareAgent = purlAgentData.isMedicareAgent;
                data.isFinalExpenseAgent = purlAgentData.isFinalExpenseAgent;
            }
            const agentPurlDetails = purlAgentData
                ? {
                      agentPurlCode: purlAgentData.agentPurlCode,
                      agentNPN: purlAgentData.agentNpn,
                      agentLastName: purlAgentData.agentLastName,
                      agentFirstName: purlAgentData.agentFirstName,
                      agentMiddleName: purlAgentData.agentMiddleName,
                      isPurlToggledOn: purlAgentData.isPurlToggledOn,
                      email: purlAgentData.email,
                      virtualNumber: purlAgentData.virtualNumber,
                      caLicense: purlAgentData.caLicense
                  }
                : {};
            data.agentPurlDetails = agentPurlDetails;

            data.streetAddress = profileInfo.address;
            data.aptSuiteNumber = profileInfo.address1 || '';
            data.stateCode = profileInfo.stateVal;
            profileInfo.leadId = getLeadId();
            delete profileInfo.address;
            delete profileInfo.address1;

            const response = await registerUser(
                { ...profileInfo, ...data },
                true
            );

            if (response.status >= 200 && response.status < 300) {
                onClose();
                if (targetPage === 'products')
                    dispatch(setSelectedItem(selectedQuickProfileItem));
                else if (targetPage === 'Learning_Center') {
                    dispatch(setSelectedItem(null));
                    history.push(selectedQuickProfileItem);
                } else if (targetPage === 'Sign_Up') {
                    history.push(selectedQuickProfileItem);
                } else if (targetPage === 'Login') {
                    handleLoginClick();
                } else if (targetPage === 'Logo') {
                    history.push(selectedQuickProfileItem);
                    window.scrollTo(0, 0);
                } else if (targetPage === 'Help') {
                    set(CONTACT_CALLBACK_KEY, 'true');
                    dispatch(setSelectedItem(selectedQuickProfileItem));
                } else if (targetPage === 'ViewMore') {
                    history.push(selectedQuickProfileItem);
                } else if (targetPage === 'Sign-Up' || targetPage === 'Home') {
                    history.push(selectedQuickProfileItem);
                }

                setLoading(false);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (profileInfo?.email && watchPassword?.length > 5) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [watchPassword]);
    useEffect(() => {
        const defaultValue = {};
        reset(defaultValue);
    }, [reset]);

    useEffect(() => {
        setValue('email', profileInfo?.email);
    }, [profileInfo?.email, setValue]);

    return selectedQuickProfileItem ? (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={true}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    headerClassName={styles.headerClassName}
                    headerTitle={headerTitle}
                    headerText={headerText}
                    headerIcon={check}
                    onClose={onClose}
                    footerClassName={styles.footerClassName}
                    footerLabel="Save"
                    footerButtonClicked={onClick}
                    footerButtonDisabled={isSubmitDisabled}
                    isBack={true}
                    backButtonClicked={onClose}
                    isSkip={true}
                    skipButtonClicked={onLeave}
                    skipButtonLabel={'Leave'}
                    skipButtonClassName={styles.skipButtonClassName}
                    modalWindow={styles.modalWindow}
                    footerContainerClassName={styles.footerContainer}
                >
                    <div
                        className={styles.content}
                        data-testid="quick-profile-modal"
                    >
                        <TrustedFormScript />

                        <form
                            onSubmit={handleSubmit(data => updateProfile(data))}
                            className={styles.manageAccountForm}
                        >
                            {loading && <PageSpinner />}

                            {message.length > 0 && isDirty ? (
                                <FeedbackCard
                                    onClose={() => setMessage('')}
                                    severity="success"
                                    text={message}
                                />
                            ) : null}

                            <EmailTextFieldWithLabel
                                className={styles.inputField}
                                disabled={true}
                                defaultValue={profileInfo?.email}
                                labelClassName={styles.emailLabelClass}
                                id="email"
                                labelText="Email Address"
                                register={register}
                            />

                            <PasswordTextFieldWithLabel
                                id="password"
                                labelText="Password "
                                register={register}
                                error={errors.password}
                                required
                                passwordVisibilityClass={
                                    styles.passwordVisibility
                                }
                            />

                            <div>
                                <Body2Italic
                                    className={styles.requiredFields}
                                    text="* Required Fields"
                                />
                            </div>

                            <div className={styles.disclaimer}>
                                <>
                                    <p className={styles.HealthDisclaimer}>
                                        By providing my information and clicking
                                        the Save button, I agree that I may be
                                        contacted by a licensed insurance agent
                                        who can answer my questions and provide
                                        information about Medicare options, such
                                        as Medicare Advantage, Prescription Drug
                                        (Part D) and Medicare Supplement
                                        insurance plans. This is a solicitation
                                        for insurance. The communications
                                        consented to above include marketing of
                                        insurance products. Licensed insurance
                                        agents are not connected with or
                                        endorsed by the U.S. government or the
                                        federal Medicare program. I understand
                                        that submitting this form does NOT
                                        affect my current enrollment, nor will
                                        it enroll me in a Medicare Advantage
                                        Plan, Prescription Drug Plan, or other
                                        Medicare plan. A Medicare Advantage Plan
                                        is a health insurance plan provided
                                        through a private insurer and delivers
                                        Medicare Part A and Part B benefits. A
                                        Part D Drug Plan is a prescription drug
                                        insurance plan provided through a
                                        private insurer and delivers Medicare
                                        Part D benefits.
                                    </p>

                                    <p>
                                        By clicking the Save button, I expressly
                                        consent by electronic signature to
                                        receive informational and marketing
                                        communications via automatic telephone
                                        dialing system or by
                                        artificial/pre-recorded message, email,
                                        or by text message from this website
                                        (operated by Integrity Marketing Group),
                                        PlanEnroll affiliated agents, and
                                        multiple partner companies or their
                                        agents at the telephone number above
                                        (even if my number is currently listed
                                        on any state, federal, local or
                                        corporate Do Not Call list) including my
                                        wireless number if provided. Carrier
                                        message and data rates may apply. I
                                        understand that my consent is not
                                        required as a condition of purchasing
                                        any goods or services and that I may
                                        revoke my consent at any time. I also
                                        acknowledge that I have read and agree
                                        to the{' '}
                                        <PrivacyLink
                                            className={styles.disclaimerLink}
                                        />{' '}
                                        and{' '}
                                        <TermsAndConditionsLink
                                            className={styles.disclaimerLink}
                                        />
                                        . If you do not want to share your
                                        information, please click on Do Not Sell
                                        My Information located in the footer for
                                        more details.
                                    </p>
                                </>
                            </div>

                            <Button
                                className={styles.submitButton}
                                label="Continue"
                                type="submit"
                                id="submitButton"
                            />
                        </form>
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    ) : (
        <></>
    );
};

export default QuickProfileHealthModal;
