import { useMemo } from 'react';

import RadioWithLabel from 'components/RadioWithLabel';
import Text from 'components/Text';

import styles from './styles.module.scss';

const DrugListItem = ({
    checked,
    id,
    isRadio,
    key,
    name,
    onClick,
    type,
    value,
    drugDetailsClass
}) => {
    const htmlContent = useMemo(
        () => (
            <div className={styles.drugBlock}>
                <div className={styles.addressContainer}>
                    <Text className={styles.drugName} text={name} id={name} />
                    <Text className={styles.text} text={type} id={type} />
                </div>
            </div>
        ),
         
        []
    );

    return (
        <li
            className={`${styles.drugListItem}  ${
                checked ? styles.active : ''
            } ${id === 0 && styles.firstDrugItem} ${drugDetailsClass}`}
            id={id}
            key={key}
            onClick={e => onClick(e, value)}
        >
            {isRadio ? (
                <RadioWithLabel
                    checked={checked}
                    className={styles.radioLabel}
                    id={`${id}_${value}`}
                    label={htmlContent}
                    name={name}
                    value={value}
                />
            ) : (
                <>{htmlContent}</>
            )}
        </li>
    );
};

export default DrugListItem;
