import { Link } from 'react-router-dom';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const LinkWithArrow = ({
    text,
    url = '',
    className = '',
    iconClassName = '',
    onClick
}) => {
    const linkText = text || url;

    if (isFunction(onClick)) {
        return (
            <span
                onClick={onClick}
                className={`${className} ${styles.linkText}`}
                id={linkText ? linkText?.replaceAll(' ', '_') : 'link'}
            >
                {linkText}
                <ArrowForwardIcon
                    className={`${styles.arrowIcon} ${iconClassName}`}
                    fontSize="inherit"
                />
            </span>
        );
    }

    if (url.startsWith('/')) {
        return (
            <Link to={url} className={`${className} ${styles.linkText}`}>
                {linkText}
                <ArrowForwardIcon
                    className={styles.arrowIcon}
                    fontSize="inherit"
                />
            </Link>
        );
    }

    return (
        <a
            className={`${className} ${styles.linkText}`}
            href={url}
            id={linkText ? linkText?.replaceAll(' ', '_') : 'link'}
        >
            {linkText}
            <ArrowForwardIcon className={styles.arrowIcon} fontSize="inherit" />
        </a>
    );
};

export default LinkWithArrow;
