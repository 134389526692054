import { useContext } from 'react';

import Body2 from 'components/Body2';
import ListItem from 'components/ListItem';
import Phone from 'components/Phone';

import phoneContext from 'contexts/phone';

import styles from './styles.module.scss';

const PhoneListItem = () => {
    const { phone } = useContext(phoneContext);

    const { hours = '', number = '', text = '' } = phone;

    if (!number) {
        return '';
    }

    return (
        <ListItem className={styles.listItem}>
            <div className={styles.mobileStyle}>
                <Phone
                    className={styles.phoneTwo}
                    number={number}
                    text={text}
                    id={'phoneNumber'}
                />

                <div className={styles.container}>
                    <Body2
                        className={styles.hourText}
                        text={hours}
                        id="hourLabel"
                    />
                </div>
            </div>
        </ListItem>
    );
};

export default PhoneListItem;
