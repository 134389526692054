import TextFieldWithLabel from 'components/TextFieldWithLabel';

import {
    invalidPatternError,
    placeholder,
    zipCodeError,
    zipCodeShortRegex
} from './data';

import styles from './styles.module.scss';

const ZipCodeTextField = ({
    autoFocus,
    defaultValue,
    disablePattern = false,
    error,
    id,
    labelText,
    register,
    required = true,
    className = ''
}) => (
    <div className={`${styles.container} ${className}`}>
        <TextFieldWithLabel
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            error={error}
            errorMessage={zipCodeError}
            errorMessagePattern={invalidPatternError}
            errorTextClassName={styles.errorText}
            InputProps={{ maxLength: 5 }}
            fieldClassName={styles.textField}
            id={id}
            labelClassName={styles.label}
            labelText={labelText}
            pattern={disablePattern && zipCodeShortRegex}
            placeholder={placeholder}
            register={register}
            required={required}
            type="text"
        />
    </div>
);

export default ZipCodeTextField;
