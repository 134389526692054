import { useDispatch } from 'react-redux';

import { setGuidedModal } from 'components/ConsumerGuidedExperienceModal/slice';
import Icon from 'components/Icon';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import Text from 'components/Text';
import medicareCardIcon from 'components/WalletInsuranceCard/image.svg';
import { setHealthInsuranceContentModal } from 'components/YourMedicareHealthInsuranceModal/slice';

import autoIcon from './autoIcon.svg';
import completedIcon from './completedIcon.svg';
import rightArrow from './rightArrow.svg';

import { setCheckCondition } from './slice';

import styles from './styles.module.scss';

const TodoItem = ({ toDo, toDoUpdate, setInsuranceIcon }) => {
    const dispatch = useDispatch();

    const onSelect = async () => {
        if (toDo.toDoType.toDoTypeId === 1) {
            dispatch(setSelectedPlanType(medicarePlanType));
            dispatch(setGuidedModal(true));
            dispatch(setCheckCondition(true));
        }
        if (toDo.toDoType.toDoTypeId === 2) {
            dispatch(setSelectedPlanType(finalExpensePlanType));
            dispatch(setGuidedModal(true));
            dispatch(setCheckCondition(true));
        }
        if (toDo.toDoType.toDoTypeId === 3) {
            setInsuranceIcon(medicareCardIcon);
            dispatch(setHealthInsuranceContentModal(true));
        }
        if (toDo.toDoType.toDoTypeId === 4 || toDo.toDoType.toDoTypeId === 5) {
            await toDoUpdate(toDo);
            window.open(toDo.toDoInteractionUrl, '_blank', 'noreferrer');
        }
    };

    const onComplete = async () => {
        await toDoUpdate(toDo);
    };
    return (
        <div className={styles.todoContainer}>
            <div className={styles.toDoIconWithTitle}>
                <Icon
                    className={styles.icon}
                    image={toDo.isComplete ? completedIcon : autoIcon}
                    onClick={onComplete}
                />
                <div>
                    <Text
                        text={toDo?.toDoType?.toDoDescription}
                        className={styles.todoTitle}
                        id="profileTodoItemDescription"
                    />

                    <div className={styles.sourceWithVerb}>
                        <span className={styles.todoVerb}>From</span>
                        <Text
                            text={toDo?.source}
                            className={styles.todoSource}
                            id="profileTodoItemSource"
                        />
                    </div>
                </div>
            </div>
            <div
                className={styles.actionBtnContainer}
                onClick={() => onSelect()}
                id="profileTodoItemActionButton"
            >
                <span
                    className={styles.actionText}
                    id="profileTodoItemActionText"
                >
                    {toDo.isComplete ? 'View' : toDo?.toDoType?.actionLabel}
                </span>
                <div className={styles.buttonIcon}>
                    <img alt="back arrow" src={rightArrow} />
                </div>
            </div>
        </div>
    );
};
export default TodoItem;
