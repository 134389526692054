import { useState } from 'react';

import { Tooltip } from '@mui/material';

import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelperInfo } from '@awesome.me/kit-cc709582e6/icons/kit/custom';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const InfoModal = ({
    className = '',
    description,
    iconClassName = '',
    modalBoxClassName = '',
    headerClassName = '',
    hideFooter = false,
    title,
    from
}) => {
    const [open, setOpen] = useState(false);
    const handleClick = onClick => handleEvent(onClick);

    return description ? (
        <div className={className}>
            {from &&
            (from === 'healthInsuranceModal' || from === 'legacyModal') ? (
                <Tooltip
                    title={description}
                    arrow
                    classes={{
                        tooltip: styles.tooltipClass,
                        arrow: styles.tooltipArrowClass
                    }}
                >
                    <div>
                        <FontAwesomeIcon
                            className={`${iconClassName} ${styles.infoIcon}`}
                            color={'#0b41aa'}
                            icon={faHelperInfo}
                            id="informationIcon"
                        />
                    </div>
                </Tooltip>
            ) : (
                <>
                    <FontAwesomeIcon
                        className={`${iconClassName} ${styles.infoIcon}`}
                        color={
                            from === 'PlanCardRecommendedBanner'
                                ? '#fff'
                                : '#0b41aa'
                        }
                        icon={
                            from === 'PlanCardRecommendedBanner'
                                ? faCircleInfo
                                : faHelperInfo
                        }
                        id="informationIcon"
                        onClick={handleClick(() => setOpen(true))}
                    />
                    <Modal isOpen={open}>
                        <ModalWindow
                            contentClassName={styles.contentClassName}
                            footerClassName={styles.footerClassName}
                            hideFooter={
                                title !== 'Coverage Type' ? true : hideFooter
                            }
                            headerClassName={`${styles.headerClassName} ${headerClassName}`}
                            headerTitle={title}
                            footerButtonClassName={styles.footerButtonClassName}
                            modalBoxClassName={modalBoxClassName}
                            footerLabel={'< Back to Filters'}
                            footerButtonClicked={() => {
                                setOpen(false);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                        >
                            {title === 'Medicare ID' ? (
                                description
                            ) : (
                                <span
                                    id="description"
                                    dangerouslySetInnerHTML={{
                                        __html: description
                                    }}
                                ></span>
                            )}
                        </ModalWindow>
                    </Modal>
                </>
            )}
        </div>
    ) : null;
};

export default InfoModal;
