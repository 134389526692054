import { setIsApiFailed } from '../YourPharmacyModal/slice';
import { useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import PharmacyListItem from 'components/PharmacyListItem';
import TypeAheadPharmacy from 'components/TypeAheadPharmacy';

import ZipCodeContext from 'contexts/zipCode';

import {
    multipleFoundLabel,
    noResultsMessage,
    placeholder,
    singleFoundLabel,
    startPrompt,
    typeAheadTitle
} from './constants';
import { pharmacySearch } from './data';

const PharmacySearch = ({
    defaultValue,
    handleSelectedPharmacy,
    selectedPharmacies,
    onChange,
    onClear,
    PER_PAGE,
    tab,
    setTab
}) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const radiusMiles = useSelector(
        state => state.distanceDropdownDetails.radiusMiles
    );
    const selectedPharmacyId = useSelector(
        state => state.pharmacyDetails.selectedPharmacyId
    );
    const dispatch = useDispatch();

    const { zipCode, county } = useContext(ZipCodeContext);

    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const resultMap = useCallback(
        (data, index) => {
            const {
                name,
                address1,
                address2,
                city,
                state,
                pharmacyID,
                zip: zipCode
            } = data;

            return (
                <PharmacyListItem
                    address1={address1}
                    address2={address2}
                    checked={selectedPharmacyId?.includes(pharmacyID)}
                    city={city}
                    id={
                        tab === 0
                            ? `pharmacy-searchItem-${index}`
                            : `digital-pharmacy-searchItem-${index}`
                    }
                    isRadio={true}
                    key={pharmacyID}
                    name={name}
                    selectedPharmacies={selectedPharmacies}
                    onClick={(value, isChecked) => {
                        handleSelectedPharmacy(data, isChecked);
                    }}
                    selectedOption={selectedPharmacyId}
                    state={state}
                    value={pharmacyID}
                    zipCode={zipCode}
                    tab={tab}
                />
            );
        },

        [selectedPharmacyId, handleSelectedPharmacy, tab]
    );

    const fetchResults = async ({
        searchTerm,
        signal,
        page,
        zipCode,
        radiusMiles,
        county,
        perPage
    }) => {
        const results = await pharmacySearch({
            page,
            perPage,
            radius: radiusMiles,
            searchTerm,
            signal,
            zipCode,
            fips: county,
            authToken: await getAuthToken()
        });
        if (!results || Object.keys(results).length === 0) {
            dispatch(setIsApiFailed(true));
        } else {
            dispatch(setIsApiFailed(false));
        }
        return results;
    };

    return (
        <>
            <TypeAheadPharmacy
                defaultValue={defaultValue}
                defaultValue1={defaultValue}
                fetchResults={fetchResults}
                PER_PAGE={PER_PAGE}
                handleSelectedPharmacy={handleSelectedPharmacy}
                multipleFoundLabel={multipleFoundLabel}
                noResultsMessage={noResultsMessage}
                onChange={onChange}
                onClear={onClear}
                placeholder={placeholder}
                radiusMiles={radiusMiles}
                zipCode={zipCode}
                county={county}
                resultMap={resultMap}
                singleFoundLabel={singleFoundLabel}
                startPrompt={startPrompt}
                title={typeAheadTitle}
                tab={tab}
                setTab={setTab}
            />
        </>
    );
};

export default PharmacySearch;
