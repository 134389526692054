import * as Sentry from '@sentry/react';

import {
    finalExpenseAgentPlanType,
    medicareAgentPlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';

import {
    AVAILABLE_AGENT_URL,
    AVAILABLE_AGENT_URL_V1,
    AVAILABLE_AGENT_TOKEN
} from 'utilities/env';
import getJSON from 'utilities/getJSON';
import postJSON from 'utilities/postJSON';

const getUrl = version1 =>
    version1
        ? `${AVAILABLE_AGENT_URL_V1}/getAgentByZip`
        : `${AVAILABLE_AGENT_URL}/getAgentByZip`;

const getAvailableAgentsData = async (
    zipCode,
    planType = medicarePlanType,
    version1 = false // this change needs to be reverted once the new API v2.0 is ready to use everywhere
) => {
    const agentPlanType =
        planType === medicarePlanType
            ? medicareAgentPlanType
            : finalExpenseAgentPlanType;
    const url = `${getUrl(version1)}?zipCode=${zipCode}&planType=${agentPlanType}`;
    const additionalHeaders = {
        Authorization: `Bearer ${AVAILABLE_AGENT_TOKEN}`
    };
    try {
        return await getJSON({ url, additionalHeaders });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getFindAgentData = async body => {
    const url = getUrl();

    try {
        return await postJSON({ url, authToken: AVAILABLE_AGENT_TOKEN, body });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export { getAvailableAgentsData, getFindAgentData };
