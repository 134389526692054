import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import ListItem from 'components/ListItem';
import PhoneListItem from 'components/PhoneListItem';
import { setSelectedItem } from 'components/PlanTypeModal/slice';
import { usePurlBanner } from 'components/PurlBanner/usePurlBanner';
import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';
import SignInButton from 'components/SignInButton';
import UserProfile from 'components/UserProfile';

import purlAgentContext from 'contexts/purlAgentContext';

import { CONTACT_AN_AGENT_ROUTE, REQUEST_CALL_BACK } from 'utilities/routes';
import {
    PURL_AGENT_CODE,
    PURL_AGENT_CODE_NEW,
    PURL_AGENT_DATA,
    PURL_AGENT_DATA_OLD
} from 'utilities/sessionStorageKeys';
import {
    CONTACT_CALLBACK_KEY,
    FINALEXPENSE_AGENT_DATA,
    FINALEXPENSE_AGENT_NPN,
    FINAL_EXPENSE_KEY,
    HEALTH_CONDITION_DETAILS_KEY,
    HEALTH_CONDITION_SEARCH_TERM_KEY,
    HEALTH_CONDITION_TERM_KEY,
    LEAD_ID_KEY,
    MEDICARE_AGENT_DATA,
    MEDICARE_AGENT_NPN
} from 'utilities/storageKeys';
import toBoolean from 'utilities/toBoolean';

import styles from './styles.module.scss';

import { get, set } from 'temp/utilities/storage';
import { remove } from 'temp/utilities/storage';
import { useFindAgentModal } from '../hooks/useFindAgentModal';

const HamburgerMenu = ({ className = '', isOpen, items, onItemClick }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { openAgentModal } = useFindAgentModal();
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const [activedLink, setActivedLink] = useState('');

    const { purlAgentData } = useContext(purlAgentContext);
    const { isMedicareAgent, isFinalExpenseAgent } = purlAgentData || {};

    const {
        HasBothAgentData,
        HasFinalExpenseAgentData,
        HasMedicareAgentData,
        HasNoAgentData
    } = usePurlBanner();

    useEffect(() => {
        history?.location?.pathname !== activedLink &&
            setActivedLink(history.location.pathname);
    }, [history.location.pathname]);

    const isHomePage = window.location.pathname === '/';
    const currentPage = window.location.href.substring(
        window.location.href.indexOf('#') + 1
    );

    const handlePlanTypeModalOpen = (
        item,
        disableMedicarePlanType,
        disableFinalExpensePlanType
    ) => {
        // Check if the agent is both Medicare and Final Expense
        const isBothAgentTypes =
            HasBothAgentData() || (isMedicareAgent && isFinalExpenseAgent);

        const isNoAgentData = HasNoAgentData();

        if (isBothAgentTypes || isNoAgentData) {
            // If the agent is both types, dispatch the selected item with disabled states
            dispatch(
                setSelectedItem({
                    ...item,
                    disableMedicarePlanType,
                    disableFinalExpensePlanType
                })
            );
        } else {
            // Otherwise, determine the correct navigation URL based on the agent type
            const navigationUrl = isFinalExpenseAgent
                ? item.finalExpenseLink
                : item.medicareLink;
            handleNavigationUrl(navigationUrl);
        }
    };

    const handleAgentTypeModalOpen = (
        item,
        disableMedicarePlanType,
        disableFinalExpensePlanType
    ) => {
        // Check if the agent is both Medicare and Final Expense
        const isBothAgentTypes =
            HasBothAgentData() || (isMedicareAgent && isFinalExpenseAgent);

        const isNoAgentData = HasNoAgentData();

        if (isBothAgentTypes || isNoAgentData) {
            // If the agent is both types, dispatch the selected item with disabled states
            openAgentModal({
                ...item
            });
        } else {
            // Otherwise, determine the correct navigation URL based on the agent type
            const navigationUrl = isFinalExpenseAgent
                ? item.finalExpenseLink
                : item.medicareLink;
            handleNavigationUrl(navigationUrl);
        }
    };

    const handleNavigation = item => {
        let disableMedicarePlanType = false;
        let disableFinalExpensePlanType = false;
        if (item.medicareLink) {
            if (item.medicareLink === CONTACT_AN_AGENT_ROUTE) {
                set(CONTACT_CALLBACK_KEY, 'true');
                disableMedicarePlanType = HasMedicareAgentData();
                disableFinalExpensePlanType = HasFinalExpenseAgentData();
            }
            onItemClick();

            if (
                window.location.href.includes('quick-profile') &&
                currentPage !== 'personalInformation' &&
                currentPage !== 'complete' &&
                window.location.hash &&
                !isAuthenticated
            ) {
                dispatch(setTargetPage('products'));
                dispatch(
                    setSelectedQuickProfileItem({
                        ...item,
                        disableMedicarePlanType,
                        disableFinalExpensePlanType
                    })
                );
            } else if (item.medicareLink === REQUEST_CALL_BACK) {
                handleAgentTypeModalOpen(
                    item,
                    disableMedicarePlanType,
                    disableFinalExpensePlanType
                );
            } else {
                handlePlanTypeModalOpen(
                    item,
                    disableMedicarePlanType,
                    disableFinalExpensePlanType
                );
            }
        } else {
            if (
                window.location.href.includes('quick-profile') &&
                currentPage !== 'personalInformation' &&
                currentPage !== 'complete' &&
                window.location.hash &&
                !isAuthenticated
            ) {
                dispatch(setTargetPage('Learning_Center'));
                dispatch(setSelectedQuickProfileItem(item.navigationLink));
            } else {
                handleNavigationUrl(item.navigationLink);
            }
        }
    };

    const handleNavigationUrl = url => {
        dispatch(setSelectedItem(null));
        if (
            window.location.href.includes('quick-profile') &&
            currentPage !== 'personalInformation' &&
            currentPage !== 'complete' &&
            window.location.hash &&
            !isAuthenticated
        ) {
            dispatch(setTargetPage('Home'));
            dispatch(setSelectedQuickProfileItem(url));
        } else {
            onItemClick();
            history.push(url);
        }
    };

    const handleLogout = async () => {
        remove(LEAD_ID_KEY);
        remove(PURL_AGENT_CODE_NEW);
        remove(PURL_AGENT_CODE);
        remove(PURL_AGENT_DATA);
        remove(FINAL_EXPENSE_KEY);
        remove(HEALTH_CONDITION_DETAILS_KEY);
        remove(HEALTH_CONDITION_SEARCH_TERM_KEY);
        remove(HEALTH_CONDITION_TERM_KEY);
        remove(MEDICARE_AGENT_DATA);
        remove(FINALEXPENSE_AGENT_DATA);
        remove(FINALEXPENSE_AGENT_NPN);
        remove(MEDICARE_AGENT_NPN);
        remove('ProfilePurlAgentFinalExpenseData');
        remove('ProfilePurlAgentMedicareData');
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    const handleLoginClick = async () => {
        try {
            var PurlAgentDataOld = get(PURL_AGENT_DATA);
            PurlAgentDataOld && set(PURL_AGENT_DATA_OLD, PurlAgentDataOld);
            await loginWithRedirect();
        } catch (e) {
            console.error('sign in error: ', e);
        }
    };

    return (
        <>
            <ul
                className={`${styles.hamburgerMenu} ${className} ${
                    isOpen ? styles.open : ''
                } ${isOpen && !isHomePage ? styles.notHomePage : ''} ${
                    isHomePage ? styles.fixedHamburgerMenu : ''
                } ${
                    !window.location.href.includes('profile') && purlAgentData
                        ? isAuthenticated
                            ? isHomePage
                                ? styles.purlBannerVisible
                                : HasBothAgentData()
                                  ? styles.noHomeBannerVisibleShowData
                                  : styles.noHomeBannerVisible
                            : isHomePage
                              ? styles.purlBannerVisibleWithCloseIcon
                              : HasBothAgentData()
                                ? styles.noHomeBannerVisibleWithCloseIconShowData
                                : styles.noHomeBannerVisibleWithCloseIcon
                        : ''
                }`}
            >
                <ListItem
                    className={styles.listitem}
                    onClick={() => handleNavigationUrl('/')}
                    text="home"
                />
                {items.map((item, index) => {
                    const { title, medicareLink, finalExpenseLink } = item;
                    return (
                        <ListItem
                            text={title}
                            key={index}
                            isActived={
                                activedLink.includes(
                                    medicareLink?.split('/').slice(-1)
                                ) ||
                                activedLink.includes(
                                    finalExpenseLink?.split('/').slice(-1)
                                )
                            }
                            onClick={() => handleNavigation(item)}
                        />
                    );
                })}
                {!purlAgentData && <PhoneListItem />}
                {!isAuthenticated && (
                    <ListItem>
                        <SignInButton className={styles.signInButton} />
                    </ListItem>
                )}

                {toBoolean(import.meta.env.VITE_APP_SHOW_LOGIN_BTN) &&
                    isAuthenticated && (
                        <UserProfile
                            handleLoginClick={handleLoginClick}
                            isAuthenticated={isAuthenticated}
                            onItemClick={onItemClick}
                            onLogout={handleLogout}
                        />
                    )}
            </ul>
        </>
    );
};

export default HamburgerMenu;
