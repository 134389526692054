import { Box, IconButton, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Heading3 from 'components/Heading3';
import { finalExpensePlanType } from './constants';
import { set } from 'temp/utilities/storage';
import {
    COVERAGE_AMOUNT,
    FROM_SUB_PRODUCT,
    IUL_COVERAGE_AMOUNT,
    PLAN_TYPE_KEY
} from 'utilities/storageKeys';
import {
    PLAN_TYPE_FILTER_FE,
    PLAN_TYPE_FILTER_IUL
} from 'components/FinalExpensePlanTypeTabs/utility/constants';

import { setSelectedSubAgentType } from './slice';

export const subAgents = ['Indexed Universal Life', 'Final Expense'];
export const IndexUniversalLifeSubAgent = 'Indexed Universal Life';
export const FinalExpenseSubAgent = 'Final Expense';

export const SubAgentType = ({ onClose, subAgentSelection }) => {
    const dispatch = useDispatch();

    const selectedSubAgentType = useSelector(
        state => state.agentTypeDetail.selectedSubAgentType
    );

    const onSelect = value => {
        dispatch(setSelectedSubAgentType(value));
        set(FROM_SUB_PRODUCT, true);
        set(IUL_COVERAGE_AMOUNT, '50000');
        set(COVERAGE_AMOUNT, '15000');
        if (value === IndexUniversalLifeSubAgent)
            set(PLAN_TYPE_KEY, PLAN_TYPE_FILTER_IUL);
        if (value === FinalExpenseSubAgent)
            set(PLAN_TYPE_KEY, PLAN_TYPE_FILTER_FE);
        onClose();
    };

    const navigateToFeFindAgent = () =>
        document
            .getElementById('findAnAgentContent')
            ?.scrollIntoView({ behavior: 'smooth' });

    const SubAgent = ({ name }) => {
        return (
            <Paper
                elevation={1}
                className={`${styles.subProduct} ${selectedSubAgentType === name && styles.selectedSubAgentTypeClass}`}
                onClick={() => onSelect(name)}
            >
                <Typography className={styles.subProductText}>
                    {name}
                </Typography>
            </Paper>
        );
    };

    return (
        <Box className={styles.subProductContainer} id="fePlanType">
            {!subAgentSelection && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    width="100%"
                    onClick={navigateToFeFindAgent}
                >
                    <IconButton onClick={onClose}>
                        <ExpandLessIcon
                            className={styles.collapseIcon}
                            fontSize="large"
                        />
                    </IconButton>
                </Box>
            )}
            <Heading3
                className={styles.subProductTypeHeading}
                text="For life insurance, what type of products are you interested in?"
            />
            <div className={styles.subProductTypeContainer}>
                {subAgents.map(name => (
                    <SubAgent name={name} />
                ))}
            </div>
            <Box
                className={styles.w100}
                onClick={() => onSelect("I'm not sure")}
            >
                <SubAgent name="I'm not sure" />
            </Box>
        </Box>
    );
};
