import { createSlice } from '@reduxjs/toolkit';

import toBoolean from 'utilities/toBoolean';

import { mailOrderData } from './constants';

export const pharmacyFilter = createSlice({
    name: 'pharmacyFilter',

    initialState: {
        isApiFailed: false,
        selectedPharmacy: '',
        pharmacies: [],
        isPlanLoading: false
    },

    reducers: {
        setPharmacies: (state, action) => {
            try {
                if (toBoolean(import.meta.env.VITE_APP_SHOW_MAILORDER)) {
                    const [checkMailOrder] = action.payload.filter(
                        ({ pharmacyId }) => pharmacyId === 'null'
                    );
                    if (!checkMailOrder)
                        action.payload = [...action.payload, ...mailOrderData];
                } else {
                    if (action.payload.length > 1) {
                        action.payload = action.payload.filter(
                            ({ pharmacyId }) => pharmacyId !== 'null'
                        );
                    }
                }
                state.pharmacies = action.payload;
            } catch (error) {
                console.error(error);
            }
        },
        setSelectedPharmacy: (state, action) => {
            state.selectedPharmacy = action.payload;
        },
        setIsApiFailed: (state, action) => {
            state.isApiFailed = action.payload;
        },
        setIsPlanLoading: (state, action) => {
            state.isPlanLoading = action.payload;
        }
    }
});

export const {
    setIsApiFailed,
    setSelectedPharmacy,
    setPharmacies,
    setIsPlanLoading
} = pharmacyFilter.actions;

export default pharmacyFilter.reducer;
