import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';

import zipcodeContext from 'contexts/zipCode';

import {
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK,
    PROFILE_ROUTE,
    FIND_AGENT_BIO_ROUTE,
    REQUEST_CALL_BACK
} from 'utilities/routes';

import finalExpenseIcon from './finalExpense.svg';
import medicareIcon from './medicare.svg';

import { finalExpensePlanType, medicarePlanType } from './constants';
import {
    hideSubAgentSelection,
    setCheckPreferredAgentModal,
    setSelectedAgentType,
    setSelectedItem,
    setSelectedSubAgentType
} from './slice';

import styles from './styles.module.scss';
import {
    FinalExpenseSubAgent,
    IndexUniversalLifeSubAgent,
    SubAgentType
} from './subAgentType';
import { IUL_TOGGLE } from 'utilities/env';
import Heading4 from 'components/Heading4';
import Button from 'components/Button';
import { LanguageSelection } from 'components/LanguageSelection';
import { AgentPreference } from 'components/AgentPreference';
import { AgentModalAddress } from 'components/AgentModalAddress';
import AgentZipCode from './agentZipCode';
import { get, set, remove } from 'temp/utilities/storage';
import { PLAN_TYPE_KEY } from 'utilities/storageKeys';
import { PLAN_TYPE_FILTER_IUL } from 'components/FinalExpensePlanTypeTabs/utility/constants';
import { getFindAgentData } from 'temp/utilities/apiSession/availableAgents';
import { getPurlAgentData } from 'temp/utilities/apiSession';
import {
    AGENT_PROFESSIONAL_INFO,
    FIND_AGENT_DATA
} from '../../utilities/storageKeys';

const AgentTypeModal = () => {
    const { zipCode } = useContext(zipcodeContext);
    const dispatch = useDispatch();

    const history = useHistory();
    const selectedItem = useSelector(
        state => state.agentTypeDetail.selectedItem
    );
    const selectedAgentType = useSelector(
        state => state.agentTypeDetail.selectedAgentType
    );
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );
    const subAgentSelection = useSelector(
        state => state.agentTypeDetail.subAgentSelection
    );
    const addressDetails = useSelector(
        state => state.agentTypeDetail.addressDetails
    );
    const productType = useSelector(
        state => state.agentTypeDetail.selectedSubAgentType
    );
    const selectedLanguages = useSelector(
        state => state.agentTypeDetail.selectedLanguages
    );

    const selectedAgentPreference = useSelector(
        state => state.agentTypeDetail.selectedAgentPreference
    );
    const [, setLifeClicked] = useState(false);
    const findAgentRef = useRef(null);

    const onSubAgentTypeClose = () => {
        setLifeClicked(false);
        dispatch(hideSubAgentSelection());
        navigateToZipCodeBlock();
    };

    const navigateToZipCodeBlock = () =>
        document.getElementById('agentZipBlock')?.scrollIntoView({
            behavior: 'smooth'
        });

    const onClose = () => {
        dispatch(setSelectedItem(null));
        remove(PLAN_TYPE_KEY);
        onSubAgentTypeClose();
    };

    const onClick = url => {
        if (
            PROFILE_ROUTE !== selectedItem.from ||
            (PROFILE_ROUTE === selectedItem.from && zipCode)
        )
            navigateToZipCodeBlock();
    };

    const onSelectAgentType = (planType, subAgentType) => {
        dispatch(setSelectedAgentType(planType));
        dispatch(setCheckPreferredAgentModal(true));
        if (!subAgentType) {
            remove(PLAN_TYPE_KEY);
        }
        if (subAgentType) {
            if (
                subAgentType === IndexUniversalLifeSubAgent &&
                selectedItem.title === 'Find an Agent'
            )
                onClick(IUL_REQUEST_CALL_BACK);
            if (
                subAgentType === FinalExpenseSubAgent &&
                selectedItem.title === 'Find an Agent'
            )
                onClick(FINAL_EXPENSE_REQUEST_CALL_BACK);
        } else if (planType === medicarePlanType) {
            navigateToZipCodeBlock();
        }
    };

    const onClickFeTab = () => {
        if (!selectedItem.disableFinalExpensePlanType) {
            if (IUL_TOGGLE === 'true') {
                document.getElementById('fePlanType')?.scrollIntoView({
                    behavior: 'smooth'
                });
            } else {
                navigateToFeFindAgent();
            }
        }
    };

    const navigateToFeFindAgent = () => {
        dispatch(setSelectedAgentType(finalExpensePlanType));
        dispatch(setCheckPreferredAgentModal(true));
        onClose();
        history.push(FINAL_EXPENSE_REQUEST_CALL_BACK);
    };
    const getFindAgentDetails = async () => {
        if (zipCode) {
            const body = {
                zipCode,
                city: addressDetails?.city ?? '',
                state: addressDetails?.stateCode ?? '',

                planType:
                    selectedAgentType === 'Medicare'
                        ? 'health'
                        : selectedAgentType === 'Final Expense'
                          ? 'life'
                          : selectedAgentType,
                productType: [
                    (selectedAgentType === 'Final Expense' ||
                        selectedAgentType === 'both') &&
                    productType &&
                    productType !== "I'm not sure"
                        ? productType === 'Indexed Universal Life'
                            ? 'Indexed Universal'
                            : productType
                        : ''
                ],
                languages: selectedLanguages ?? [''],
                meetType: selectedAgentPreference ?? '',

                address: addressDetails?.streetAddress ?? '',
                aptNumber: addressDetails?.aptSuiteNumber ?? ''
            };

            const agentDetails = await getFindAgentData(body);
            onClose();

            if (agentDetails?.length > 0) {
                const response = await getPurlAgentData(
                    agentDetails[0]?.agentPURL
                );

                set(FIND_AGENT_DATA, response);
                set(
                    AGENT_PROFESSIONAL_INFO,
                    response?.agentProfessionalInfoResponse
                );
                history.push(FIND_AGENT_BIO_ROUTE);
            } else
                history.push(
                    selectedAgentType === 'Medicare'
                        ? REQUEST_CALL_BACK
                        : productType === 'Indexed Universal Life'
                          ? IUL_REQUEST_CALL_BACK
                          : FINAL_EXPENSE_REQUEST_CALL_BACK
                );
        }
    };

    const goToFindAgentForm = async () => {
        await getFindAgentDetails();
    };

    useEffect(() => {
        if (selectedItem && selectedPlanType) {
            dispatch(setSelectedAgentType(selectedPlanType));
            if (
                selectedPlanType === finalExpensePlanType &&
                get(PLAN_TYPE_KEY)
            ) {
                let selectedSubAgent =
                    get(PLAN_TYPE_KEY) === PLAN_TYPE_FILTER_IUL
                        ? IndexUniversalLifeSubAgent
                        : FinalExpenseSubAgent;
                dispatch(setSelectedSubAgentType(selectedSubAgent));
            }
        }
        if (selectedItem) {
            findAgentRef.current.addEventListener(
                'wheel',
                function (e) {
                    e.preventDefault();
                },
                { passive: false }
            );

            findAgentRef.current.addEventListener(
                'touchmove',
                function (e) {
                    e.preventDefault();
                },
                { passive: false }
            );
        }
    }, [selectedItem, selectedPlanType]);

    return selectedItem ? (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={true}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    headerClassName={styles.headerClassName}
                    headerTitle={'Find An Agent'}
                    onClose={onClose}
                    hideFooter={true}
                    modalWindow={styles.modalWindow}
                >
                    <div
                        className={styles.content}
                        data-testid="find-an-modal"
                        id="findAnAgentContent"
                        ref={ref => (findAgentRef.current = ref)}
                    >
                        {subAgentSelection ? (
                            <SubAgentType
                                onClose={onSubAgentTypeClose}
                                onSelectPlanType={onSelectAgentType}
                                subAgentSelection={subAgentSelection}
                            />
                        ) : (
                            <>
                                <div className={styles.agentTypeContentBlock}>
                                    <Heading4
                                        className={styles.findAgentHelperText}
                                        text={
                                            'What type of agent are you looking for ?'
                                        }
                                    />
                                    <div className={styles.planTypeContent}>
                                        <div
                                            className={`${styles.title} ${selectedAgentType === medicarePlanType && styles.selectedAgentClass}`}
                                            onClick={
                                                !selectedItem?.disableMedicarePlanType
                                                    ? () =>
                                                          onSelectAgentType(
                                                              medicarePlanType
                                                          )
                                                    : () => {}
                                            }
                                        >
                                            <Icon
                                                alt="Medicare"
                                                className={`${styles.medicareIcon} ${
                                                    selectedItem?.disableMedicarePlanType
                                                        ? styles.disableIcon
                                                        : ''
                                                }`}
                                                image={medicareIcon}
                                                id="medicareIcon"
                                            />
                                            <Text
                                                className={`${styles.typeText} ${
                                                    selectedItem?.disableMedicarePlanType
                                                        ? styles.opacity3
                                                        : ''
                                                }`}
                                                text={
                                                    <>
                                                        Health Agent <br />
                                                        (Medicare)
                                                    </>
                                                }
                                                id="Medicare"
                                            />
                                        </div>
                                        <div
                                            className={`${styles.title} ${selectedAgentType === finalExpensePlanType && styles.selectedAgentClass}`}
                                            onClick={() => {
                                                onClickFeTab();
                                                onSelectAgentType(
                                                    finalExpensePlanType
                                                );
                                            }}
                                        >
                                            <Icon
                                                alt="Final Expense"
                                                className={`${styles.typeText} ${styles.finalExpenseIcon} ${
                                                    selectedItem?.disableFinalExpensePlanType
                                                        ? styles.disableIcon
                                                        : ''
                                                }`}
                                                image={finalExpenseIcon}
                                                id="finalExpenseIcon"
                                            />
                                            <Text
                                                className={`${styles.typeText} ${
                                                    selectedItem?.disableFinalExpensePlanType
                                                        ? styles.opacity3
                                                        : ''
                                                }`}
                                                text="Life Agent"
                                                id="life"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.buttonContainer}>
                                        <Button
                                            className={`${styles.selectBothAgentButton} ${selectedAgentType === 'both' && styles.selectedAgentClass}`}
                                            onClick={() => {
                                                onClickFeTab();
                                                onSelectAgentType('both');
                                            }}
                                            disabled={
                                                selectedItem?.disableMedicarePlanType ||
                                                selectedItem?.disableFinalExpensePlanType
                                            }
                                            label="Or an agent that sells both"
                                        />
                                    </div>
                                </div>
                                <div className={styles.agentTypeContentBlock}>
                                    <SubAgentType
                                        onClose={onSubAgentTypeClose}
                                        subAgentSelection={subAgentSelection}
                                    />
                                </div>
                                <div className={styles.agentTypeContentBlock}>
                                    <AgentZipCode />
                                </div>
                                <LanguageSelection />
                                <AgentPreference
                                    navigateToFeFindAgent={goToFindAgentForm}
                                />
                                <AgentModalAddress
                                    navigateToFeFindAgent={goToFindAgentForm}
                                />
                            </>
                        )}
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    ) : (
        <></>
    );
};

export default AgentTypeModal;
