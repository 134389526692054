import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelectedItem as setSelectedAgentItem } from 'components/AgentTypeModal/slice';
import { AGENT_UBER_TOGGLE } from 'utilities/env';
import { setSelectedItem as setSelectedPlanItem } from 'components/PlanTypeModal/slice';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/AgentTypeModal/constants';
import { usePurlBanner } from 'components/PurlBanner/usePurlBanner';

export const useFindAgentModal = () => {
    const { push } = useHistory();
    const dispatch = useDispatch();

    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );

    const { HasMedicareAgentData, HasFinalExpenseAgentData } = usePurlBanner();
    const disableFinalExpensePlanType = HasFinalExpenseAgentData();
    const disableMedicarePlanType = HasMedicareAgentData();

    const openAgentModal = data => {
        if (
            selectedPlanType === finalExpensePlanType &&
            disableFinalExpensePlanType
        )
            push(data.finalExpenseLink);
        else if (
            selectedPlanType === medicarePlanType &&
            disableMedicarePlanType
        )
            push(data.medicareLink);
        else
            AGENT_UBER_TOGGLE === 'true'
                ? dispatch(setSelectedAgentItem(data))
                : dispatch(setSelectedPlanItem(data));
    };

    const openAgentModalWithProductType = data => {
        if (AGENT_UBER_TOGGLE === 'true') openAgentModal(data);
        else
            push(
                selectedPlanType === medicarePlanType
                    ? data.medicareLink
                    : data.finalExpenseLink
            );
    };

    return { openAgentModal, openAgentModalWithProductType };
};
