import { createSlice } from '@reduxjs/toolkit';

export const contactAgentDetails = createSlice({
    name: 'contactAgentDetails',

    initialState: {
        isModalOpen: false
    },

    reducers: {
        setIsModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        }
    }
});

export const { setIsModalOpen } = contactAgentDetails.actions;

export default contactAgentDetails.reducer;
