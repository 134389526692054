import Icon from 'components/Icon';

import image from './image.svg';

import styles from './styles.module.scss';

const NotFoundIcon = ({ onClick }) => (
    <Icon className={styles.notFoundIcon} image={image} onClick={onClick} />
);

export default NotFoundIcon;
