import styles from './styles.module.scss';

export const AgentModalContentContainer = ({
    id,
    children,
    className = ''
}) => {
    return (
        <div className={styles.agentTypeContentBlock}>
            <div className={`${styles.container} ${className}`} id={id}>
                {children}
            </div>
        </div>
    );
};
