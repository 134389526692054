import { useState } from 'react';

import EditLink from 'components/EditLink';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';

import image from './image.svg';

import {
    Eligibility_TITLE,
    UNABLE_Eligibility_TITLE,
    NOT_Eligibility_TITLE,
    PRESCREEN_AVAILABLE
} from './constants';

import styles from './styles.module.scss';

const PrescreenAvailable = ({ eligibility, className = '', conditionList }) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className={`${styles.learnMoreAboutPlanType} ${className}`}>
            <EditLink
                displayIconOnLeft={true}
                onClick={() => setModalOpen(true)}
                text={eligibility}
                className={styles.link}
                iconClassName={styles.icon}
                icon={image}
            />{' '}
            <Modal isOpen={modalOpen}>
                <ModalWindow
                    contentClassName={`${styles.modalContent} ${styles.modalContentDsnp}`}
                    footerClassName={styles.modalFooter}
                    headerClassName={styles.modalHeader}
                    headerTitle={'Eligibility Notes'}
                    footerButtonClicked={() => setModalOpen(false)}
                    modalBoxClassName={styles.modalBoxClassName}
                    onClose={() => setModalOpen(false)}
                >
                    <div className={styles.contentBox}>
                        {conditionList.length > 0
                            ? UNABLE_Eligibility_TITLE
                            : eligibility === PRESCREEN_AVAILABLE
                            ? Eligibility_TITLE
                            : NOT_Eligibility_TITLE}
                        <ul>
                            {conditionList.map(({ name, lookBackPeriod }) => {
                                return (
                                    <li key={name}>{`${name} ${
                                        lookBackPeriod
                                            ? `within ${lookBackPeriod} months`
                                            : ''
                                    }`}</li>
                                );
                            })}
                        </ul>
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    );
};

export default PrescreenAvailable;
