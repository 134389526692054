import {
    setIsApiFailed,
    setIsEdit,
    setSelectedDrugId
} from '../YourPrescriptionModal/slice';
import { useCallback, useRef, useState } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import TextAsLink from 'components/TextAsLink';

import {
    mobilePlaceholder,
    multipleFoundLabel,
    noResultsMessage,
    placeholder,
    singleFoundLabel,
    startPrompt,
    typeAheadTitle
} from './constants';
import { drugSearch } from './data';

import styles, { mobileWidth } from './styles.module.scss';

import DrugListItem from 'temp/components/DrugListItem';
import PrescriptionsList from 'temp/components/PrescriptionsList';
import TypeAhead from 'temp/components/TypeAhead';
import { drugDetailSearch } from 'temp/utilities/apiSearch';

const PrescriptionSearch = ({
    defaultValue,
    PER_PAGE,
    handleSelectedPrescription,
    inputRef,
    onChange,
    onClear,
    prescriptions,
    setShowLink,
    showSearch,
    setShowSearch,
    isFromProfile,
    isBackButtonVisible,
    selectedPrescriptions,
    onSelectHasNoPrescriptions
}) => {
    const dispatch = useDispatch();
    const editDrug = useRef(null);
    const selectedDrugId = useSelector(
        state => state.prescriptionDetails.selectedDrugId
    );
    const { user, getAccessTokenSilently } = useAuth0();
    const [isMobile, setIsMobile] = useState(false);
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const selectDrug = async (id, edit = false, dosageRecordID = undefined) => {
        const authToken = await getAuthToken();
        let drugDetails = await drugDetailSearch({ id, authToken });
        if (edit) {
            drugDetails = { ...drugDetails, ...editDrug.current };
        }
        handleSelectedPrescription(
            { ...drugDetails, id: dosageRecordID },
            edit
        );
        editDrug.current = null;
    };

    const onDrugSelect = async (e, drugID) => {
        if (e) e?.preventDefault();
        dispatch(setSelectedDrugId(drugID));
        await selectDrug(drugID);
    };

    const resultMap = useCallback(
        ({ drugID, drugName, drugType }, index) => (
            <DrugListItem
                checked={drugID === selectedDrugId}
                isRadio
                name={drugName}
                onClick={e => onDrugSelect(e, drugID)}
                type={drugType}
                key={drugID}
                value={drugID}
                id={index}
            />
        ),
         
        [selectedDrugId]
    );
    const prescriptionsList = (
        <PrescriptionsList
            prescriptions={prescriptions}
            selectDrug={dosage => {
                const { drugID, dosageRecordID } = dosage;
                editDrug.current = dosage;
                selectDrug(drugID, true, dosageRecordID);
                dispatch(setIsEdit(true));
            }}
            setShowSearch={setShowSearch}
            isFromProfile={isFromProfile}
            isBackButtonVisible={isBackButtonVisible}
        />
    );
    const fetchResults = async ({ searchTerm: drugName, signal }) => {
        const authToken = await getAuthToken();
        let results = await drugSearch({ drugName, signal, authToken });

        if (!results) {
            dispatch(setIsApiFailed(true));
        } else {
            dispatch(setIsApiFailed(false));
        }
        return results;
    };
    return (
        <>
            <Media
                queries={{
                    mobile: { maxWidth: mobileWidth }
                }}
                onChange={breakpoint => {
                    setIsMobile(breakpoint.mobile);
                }}
            />
            <TypeAhead
                defaultValue={defaultValue}
                fetchResults={fetchResults}
                PER_PAGE={PER_PAGE}
                handleSelectedPrescription={handleSelectedPrescription}
                multipleFoundLabel={multipleFoundLabel}
                noResultsMessage={noResultsMessage}
                onChange={onChange}
                onClear={onClear}
                placeholder={isMobile ? mobilePlaceholder : placeholder}
                prescriptions={prescriptions}
                prescriptionsList={prescriptionsList}
                ref={inputRef}
                resultMap={resultMap}
                setShowLink={setShowLink}
                showSearch={showSearch}
                singleFoundLabel={singleFoundLabel}
                startPrompt={startPrompt}
                title={typeAheadTitle}
            />
            {selectedPrescriptions.length === 0 && (
                <div className={styles.noPrescription}>
                    <TextAsLink
                        text="I currently don't take any prescriptions."
                        onClick={() => onSelectHasNoPrescriptions()}
                        className={styles.noPrescriptionText}
                    />
                </div>
            )}
        </>
    );
};

export default PrescriptionSearch;
