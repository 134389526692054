import PropTypes from 'prop-types';

import Body2 from 'components/Body2';

import styles from './styles.module.scss';

const Checkbox = ({
    activeClass,
    checkBoxClassName,
    checkboxInput = styles.checkboxInput,
    checked,
    className,
    defaultChecked = false,
    disabled = false,
    id = '',
    label = '',
    labelClass,
    name = '',
    onChange,
    value = ''
}) => (
    <label
        className={`${styles.checkbox} ${disabled ? 'disabled' : ''} ${
            label === 'Compare' ? styles.planCardInput : ''
        } ${labelClass} ${defaultChecked === true ? activeClass : ''} ${
            checkBoxClassName ?? ''
        }`}
        htmlFor={id}
    >
        <input
            className={`${checkboxInput} ${disabled && styles.inputDisabled}`}
            defaultChecked={defaultChecked}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            type="checkbox"
            value={value}
            checked={checked}
        />

        <Body2 className={className} text={label} />
    </label>
);

export const checkboxProps = {
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string
};

Checkbox.propTypes = checkboxProps;

export default Checkbox;
